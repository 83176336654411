/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";
import Countdown, { zeroPad } from "react-countdown";
import timeIcon from "../../../assets/timeIcon.png";
import {
  useStylesDark,
  useStylesLight,
} from "../../../Styles/ActiveAuctionCardStyles";
import { BsFillLightningFill } from "react-icons/bs";
import { escape } from "lodash";

const ActiveAuctionCard = (props) => {
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  var [classes, setClasses] = useState(classesDark);
  const [isAuctionOver, setIsAuctionOver] = useState(false);

  // theme setting

  useEffect(() => {
    if (props.ctheme === "light") {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [props.ctheme]);

  // Calculate the date
  let round = Math.floor(Math.floor(Date.now() / 1000) / (86400 * 14)) - 1348;
  const location = window.location.href;
  const suffix = location.split("/").pop();
  if (
    (round + 1348) * 86400 * 14 + 60 * 60 * 24 * 5 <
    Math.floor(Date.now() / 1000)
  ) {
    round++;
  }
  const calculatedDate = new Date((round + 1348) * 60 * 60 * 24 * 14 * 1000);
  if (suffix === "prisma") {
    round = round - 57;
  }
  if (suffix === "fxn") {
    round = round - 65;
  }

  // Extract the day, month, and year from the date
  const day = calculatedDate.getDate();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[calculatedDate.getMonth()];
  const year = calculatedDate.getFullYear();

  // Function to append the ordinal suffix to the day
  function getOrdinalSuffix(number) {
    if (number > 3 && number < 21) return "th";
    switch (number % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  // Construct the final formatted string
  const formattedDate = `Week of ${day}${getOrdinalSuffix(
    day
  )} ${month} ${year}`;

  //countdown comp
  let date;
  if (suffix === "prisma") {
    date = new Date(
      (round + 1405) * 60 * 60 * 24 * 14 * 1000 +
        5 * 60 * 60 * 24 * 1000 -
        6 * 60 * 60 * 1000
    );
  } else if (suffix === "fxn") {
    date = new Date(
      (round + 1413) * 60 * 60 * 24 * 14 * 1000 +
        5 * 60 * 60 * 24 * 1000 -
        6 * 60 * 60 * 1000
    );
  } else {
    date = new Date(
      (round + 1348) * 60 * 60 * 24 * 14 * 1000 +
        5 * 60 * 60 * 24 * 1000 -
        6 * 60 * 60 * 1000
    );
  }

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setIsAuctionOver(true);
      props.onAuctionCompletion(true);
      return (
        <Typography className={classes.auctionEndedButton}>
          00:00:00:00
        </Typography>
      );
    } else {
      setIsAuctionOver(false);
      props.onAuctionCompletion(false);
      return (
        <span>
          {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };

  return (
    <>
      <Paper className={classes.auctionCard}>
        {window.location.pathname !== "/prisma" ? (
          <Typography className={classes.cardTitle}>
            Round #{round} - Gauge Weight for {formattedDate}
          </Typography>
        ) : (
          <Typography className={classes.cardTitle}>
            Round #{round} - Prisma Emissions Weight for {formattedDate}
          </Typography>
        )}

        <Typography className={classes.proposalText}>
          <p>
            Votium will try to vote for incentives optimally, giving vlCVX
            holders the best returns. Delegating to Votium is not mandatory. You
            can vote for yourself to receive incentives. You can change your
            vote on Snapshot whenever you want.
          </p>
          <Button
            className={classes.voteButton}
            onClick={() => {
              window.open("https://snapshot.org/#/cvx.eth/");
            }}
          >
            Click here to view/vote on Snapshot &nbsp;
            <BsFillLightningFill color="#f3b04e" />
          </Button>
        </Typography>
        <TableContainer>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell
                  align="left"
                  className={classes.tableText}
                  style={{ borderBottom: "none" }}
                >
                  {isAuctionOver ? "Depositing Period Ended" : "Time Remaining"}
                </TableCell>
                <TableCell
                  align="right"
                  className={classes.tableText}
                  style={{ borderBottom: "none" }}
                >
                  {<Countdown date={date} renderer={renderer} />}
                </TableCell>
                <TableCell align="right" style={{ borderBottom: "none" }}>
                  <img
                    src={timeIcon}
                    className={classes.timeIcon}
                    alt="time-icon"
                  ></img>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default ActiveAuctionCard;
