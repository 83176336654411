import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

const useStylesDark = makeStyles((theme) => ({
  button: {
    ...theme.typography.tab,
    color: theme.palette.common.grey,
    fontFamily: "Rubik",
    backgroundColor: "#141E27",
    marginLeft: "2em",
    [theme.breakpoints.up("sm")]: {
      marginRight: "2em",
    },
  },
  buttonConnect: {
    ...theme.typography.tab,
    color: theme.palette.common.grey,
    fontFamily: "Rubik",
    backgroundColor: "#141E27",
    marginLeft: "2em",
    [theme.breakpoints.down("sm")]: {
      marginRight: "2em",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "-0.1em",
    },
  },
}));

const useStylesLight = makeStyles((theme) => ({
  button: {
    ...theme.typography.tab,
    color: theme.palette.common.grey,
    fontFamily: "Rubik",
    backgroundColor: "#141E27",
    marginLeft: "2em",
    [theme.breakpoints.up("sm")]: {
      marginRight: "2em",
    },
  },
  buttonConnect: {
    ...theme.typography.tab,
    color: theme.palette.common.grey,
    fontFamily: "Rubik",
    backgroundColor: "#141E27",
    marginLeft: "2em",
    [theme.breakpoints.down("sm")]: {
      marginRight: "2em",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "-0.1em",
    },
  },
}));

function WalletButton(props) {
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  const [classes, setClasses] = useState(classesDark);
  const truncate = (input) => {
    return (
      input.substring(0, 4) +
      "..." +
      input.substring(input.length - 2, input.length)
    );
  };
  const formattedAddress = truncate(props.address);

  // theme setting
  useEffect(() => {
    if (props.ctheme === "light") {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [props.ctheme]);

  return (
    <>
      <Button
        className={!props.wallet ? classes.buttonConnect : classes.button}
        variant="contained"
        size="small"
        onClick={() => {
          if (props.wallet) {
            props.disconnect(props.wallet);
            window.localStorage.removeItem("WALLETCONNECT_DEEPLINK_CHOICE");
          } else {
            props.connect();
          }
        }}
      >
        {props.connecting
          ? "connecting"
          : props.wallet
          ? formattedAddress
          : "Connect Wallet"}
      </Button>
    </>
  );
}

export default WalletButton;
