import { useState, useEffect } from "react";
import { ref, onValue } from "firebase/database";
import { Grid, Typography, Paper } from "@material-ui/core";
import votBox from "../../../../assets/votBox.png";
import { useStylesDark, useStylesLight } from "../../../../Styles/BribesStyles";
import AuctionCard from "./AuctionCardCurve";
var _ = require("lodash");

const Bribes = (props) => {
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  const [classes, setClasses] = useState(classesDark);
  const [bribes, setBribes] = useState([]);
  const [refreshDB, setRefreshDB] = useState(1);
  const [displayBribes, setDisplayBribes] = useState(false);
  const [activeBribes, setActiveBribes] = useState(false);
  const [refreshBribes, setRefreshBribes] = useState(false);
  const [refreshPrice, setRefreshPrice] = useState(false);
  const [tokenPriceValue, setTokenPriceValue] = useState(0);
  const [gaugeTotalRef, setGaugeTotalRef] = useState(null);
  const [userGaugeWeightRef, setUserGaugeWeightRef] = useState(null);

  // theme setting 
  useEffect(() => {
    if (props.ctheme === 'light') {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [props.ctheme]);
  
  //get bribes from db
  useEffect(() => {
    async function getInfoFromDatabase() {
      if (props.db) {
        try {
          const bribesRef = ref(
            props.db,
            "vecrv/deposits/" + props.auctionWeek
          );
          const tokenPriceRef = ref(props.db, "api/tokens");
          const gaugeTotalRef = ref(props.db, "vecrv/gaugeTotals");
          setGaugeTotalRef(gaugeTotalRef);
          const userGaugeWeightRef = ref(
            props.db,
            "/vecrv/votes/" + props.address.toUpperCase()
          );
          setUserGaugeWeightRef(userGaugeWeightRef);
          onValue(bribesRef, (snapshot) => {
            let data = snapshot.val();
            if (data !== null && data !== undefined) {
              setBribes(data);
            } else {
              console.log("Couldn't get bribes from db");
              setBribes([]);
            }
          });
          onValue(tokenPriceRef, (snapshot) => {
            let data = snapshot.val();
            if (data !== null && data !== undefined) {
              setTokenPriceValue(data);
            } else {
              console.log("Couldn't get token value from db");
            }
          });
        } catch {
          setRefreshDB(refreshDB + 1);
          console.log("Couldn't get info from database");
        }
      }
    }
    try {
      if (refreshDB < 30) {
        getInfoFromDatabase();
      }
    } catch {
      console.log("Couldn't run getRefs/getInfoFromDatabase");
    }
  }, [props.db, refreshDB, props.auctionWeek, props.refreshAuction]);

  //display bribes
  useEffect(() => {
    async function getBribesFromEvent() {
      console.log(bribes);
      if (bribes !== undefined && bribes.length != 0) {
        setDisplayBribes(
          bribes.map((bribe) => {
            //console.log(bribe);
            let tokenValue =
              tokenPriceValue[bribe.token.toString().toUpperCase()];
            console.log(tokenValue);
            return (
              <Grid item>
                <AuctionCard
                  snapshot={props.auction}
                  bribe={bribe}
                  contract={props.contract}
                  proposalHash={props.proposalHash}
                  proposalId={props.id}
                  refreshAuction={props.refreshAuction}
                  provider={props.provider}
                  address={props.address}
                  db={props.db}
                  filter={props.filter}
                  auctionWeek={props.auctionWeek}
                  refreshBribes={refreshBribes}
                  tokenValue={tokenValue}
                  gaugeTotalRef={gaugeTotalRef}
                  userGaugeWeightRef={userGaugeWeightRef}
                />
              </Grid>
            );
          })
        );
      }
      if (bribes === undefined || bribes.length === 0) {
        setActiveBribes(false);
      }
    }
    if (props.db) {
      getBribesFromEvent();
      setRefreshBribes(!refreshBribes);
    }
  }, [
    props.db,
    props.refreshAuction,
    bribes,
    props.filter,
    props.auctionWeek,
    tokenPriceValue,
  ]);

  return (
    <>
      <Grid container direction="row" justifyContent="center">
        {bribes === undefined ? (
          <Paper className={classes.auctionCard}>
            <Grid container direction="column">
              <Grid item>
                <Typography className={classes.cardTitle}>
                    There are currently no incentives for this week.
                </Typography>
              </Grid>
              <Grid item>
                <img src={votBox} alt="votbox" className={classes.votBox}></img>
              </Grid>
            </Grid>
          </Paper>
        ) : bribes.length > 0 ? (
          <Grid container className={classes.bribesContainer}>
            {displayBribes}
          </Grid>
        ) : (
          <Paper className={classes.auctionCard}>
            <Grid container direction="column">
              <Grid item>
                <Typography className={classes.cardTitle}>
                    There are currently no incentives for this week.
                </Typography>
              </Grid>
              <Grid item>
                <img src={votBox} alt="votbox" className={classes.votBox}></img>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Grid>
    </>
  );
};

export default Bribes;
