import { makeStyles } from "@material-ui/core";

export const useStylesDark = makeStyles((theme) => ({
  wrapper: {
    justifyContent: "center",
  },
  paper: {
    width: "15%",
    margin: "2em",
    backgroundColor: "#141E27",
    [theme.breakpoints.down("md")]: {
      width: "22%",
    },
  },
  text: {
    marginTop: "0.3em",
    textAlign: "center",
    fontFamily: "Rubik",
    color: theme.palette.common.grey,
  },
  filterText: {
    textAlign: "center",
    fontFamily: "Rubik",
    color: "grey",
  },
  logoContainer: {},
  cvxLogo: {
    width: "3em",
    margin: "0.3em",
  },
  auctionsContainer: {
    textAlign: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  auctionCard: {
    margin: "1em",
    width: "20em",
    height: "10em",
  },
  noActiveAuctionCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "1em",
    width: "40em",
    zIndex: 1,
    paddingTop: "0.6em",
    marginTop: "-4em",
    paddingBottom: "1em",
    background: "rgb(34 36 38)",
  },
  noActiveAuctionTitle: {
    fontSize: "1.5em",
    marginTop: "0.2em",
    color: "rgb(232, 230, 227)",
  },
  noActiveAuctionText: {
    fontSize: "1em",
    marginTop: "0.8em",
    color: "grey",
  },
  togglePaper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "1em",
    marginBottom: "0em",
    width: "25em",
    zIndex: 1,
    background: "rgb(34 36 38)",
    [theme.breakpoints.up("sm")]: {
      //marginBottom: "12em",
    },
  },
  togglePaperTitle: {
    fontSize: "1.5em",
    marginTop: "0.2em",
    color: "rgb(232, 230, 227)",
  },
  togglePaperText: {
    fontSize: "1em",
    marginTop: "0.8em",
    color: "grey",
  },
  tablePaper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    zIndex: 1,
    background: "rgb(34 36 38)",
    [theme.breakpoints.up("sm")]: {
      //marginBottom: "12em",
    },
  },
  tableText: {
    color: "grey",
    borderColor: "rgb(24 25 27)",
  },
  tableSubtitle: {
    color: "grey",
    borderColor: "rgb(128, 128, 128, 0.3)",
  },
  timeText: {
    fontSize: "1.3em",
    marginTop: "1.05em",
    color: "grey",
    textAlign: "center",
  },
  tab: {
    margin: "auto",
    display: "block",
    textAlign: "center",
  },
  uniSpace: {
    maxWidth: "15%",
    marginBottom: "-0.4em",
  },
  cvxSpace: {
    maxWidth: "15%",
  },
  crvSpace: {
    maxWidth: "8%",
    marginTop: "0.5em",
    marginBottom: "0.6em",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  arrowButton: {
    color: "white",
  },
  votBox: {
    width: "3em",
    marginTop: "1em",
  },
  accordion: {
    width: "40em",
  },
  activeContainer: {
    alignItems: "center",
  },
  bribesContainer: {
    display: "grid",
    width: "75%",
    gridTemplateColumns: "repeat(auto-fit,360px)",
  },
  arrowsContainer: {
    marginBottom: "4em",
    marginTop: "-6em",
  },
  votTokenInput: {
    width: "2em",
  },
  submitButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "#141E27",
    color: theme.palette.common.lgrey,
    marginBottom: "-1em",
  },
  submitButtonDisabled: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: theme.palette.common.lgrey,
    color: "rgb(216 212 207)",
  },
  toggleButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "rgb(24 25 27)",
    color: "rgb(222, 220, 217)",
  },
  toggleButtonDisabled: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "rgb(24 25 27)",
    color: "grey",
  },
  incentivesAccordion: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "rgb(24 25 27)",
    color: "rgb(222, 220, 217)",
    width: "40em",
    borderRadius: "0.5em",
  },
  tableTextBottom: {
    borderBottom: "none",
  },
  faqButton: {
    color: "grey",
    textTransform: "none",
    margin: "1em",
    width: "40%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-25em",
  },
  modalPaper: {
    backgroundColor: "#070A0E",
    borderRadius: "3px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: "45em",
    width: "20em",
    alignItems: "center",
  },
  forwardModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-15em",
  },
  forwardModalPaper: {
    backgroundColor: "#070A0E",
    borderRadius: "3px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: "45em",
    width: "50em",
    alignItems: "center",
  },
  modalTitle: {
    fontSize: "1em",
    fontWeight: "700",
    marginTop: "-1em",
  },
  modalDelegateButton: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    margin: "1em",
    background: "#141e27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    height: "3.7em",
  },
  modalDelegateButtonDisabled: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    margin: "1em",
    background: "#cccecf",
    borderRadius: "5px",
    color: "white",
    height: "3.7em",
  },
  modalText: {
    textAlign: "center",
    margin: "1em",
    color: theme.palette.common.grey,
    fontWeight: "400",
  },
  modalTextSmall: {
    textAlign: "center",
    fontSize: "0.8em",
    margin: "1em",
    color: theme.palette.common.grey,
  },
  modalClaimButton: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "70px",
    background: "#141E27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    height: "3em",
    padding: "1em",
    left: "35%",
    marginTop: "1.5em",
    marginBottom: "-0.1em",
  },
  modalClaimButtonDisabled: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "70px",
    background: "#cccecf",
    borderRadius: "5px",
    height: "3em",
    padding: "1em",
    left: "28%",
    marginTop: "1.5em",
    marginBottom: "-0.1em",
  },
  modalInput: {
    width: "20em",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgb(216 212 207)", // Change the border color to white (#fff)
      },
      "&:hover fieldset": {
        borderColor: "rgb(216 212 207)", // Change the border color to white (#fff) on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(216 212 207)", // Change the border color to white (#fff) when focused
      },
    },
  },
  label: {
    color: "rgb(216 212 207)", // Use your desired color
    "&.Mui-focused": {
      color: "rgb(255 255 255)", // Use your desired color
    },
  },
  inputRoot: {
    color: "rgb(216 212 207)",
    fontWeight: "500",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#888",
    },
    cursor: "default",
    width: "100%",
  },
  auctionOverText: {
    marginTop: "1em",
    color: "grey",
  },
}));

export const useStylesLight = makeStyles((theme) => ({
  wrapper: {
    justifyContent: "center",
  },
  paper: {
    width: "15%",
    margin: "2em",
    backgroundColor: "#141E27",
    [theme.breakpoints.down("md")]: {
      width: "22%",
    },
  },
  text: {
    marginTop: "0.3em",
    textAlign: "center",
    fontFamily: "Rubik",
    color: theme.palette.common.grey,
  },
  filterText: {
    textAlign: "center",
    fontFamily: "Rubik",
    color: "grey",
  },
  logoContainer: {},
  cvxLogo: {
    width: "3em",
    margin: "0.3em",
  },
  auctionsContainer: {
    textAlign: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  auctionCard: {
    margin: "1em",
    width: "20em",
    height: "10em",
  },
  noActiveAuctionCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "1em",
    width: "40em",
    zIndex: 1,
    paddingTop: "0.6em",
    marginTop: "-4em",
    paddingBottom: "1em",
    background: "#e3e5e6",
  },
  noActiveAuctionTitle: {
    fontSize: "1.5em",
    marginTop: "0.2em",
    color: "rgb(12, 10, 27)",
  },
  noActiveAuctionText: {
    fontSize: "1em",
    marginTop: "0.8em",
    color: "rgb(32, 30, 37)",
  },
  auctionOverText: {
    marginTop: "1em",
    color: "grey",
  },
  togglePaper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "1em",
    marginBottom: "0em",
    width: "25em",
    zIndex: 1,
    background: "#e3e5e6",
    [theme.breakpoints.up("sm")]: {
      //marginBottom: "12em",
    },
  },
  togglePaperTitle: {
    fontSize: "1.5em",
    marginTop: "0.2em",
    color: "rgb(232, 230, 227)",
  },
  togglePaperText: {
    fontSize: "1em",
    marginTop: "0.8em",
    color: "grey",
  },
  tablePaper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    zIndex: 1,
    background: "rgb(207 209 210)",
    [theme.breakpoints.up("sm")]: {
      //marginBottom: "12em",
    },
  },
  tableText: {},
  timeText: {
    fontSize: "1.3em",
    marginTop: "1.05em",
    color: "grey",
    textAlign: "center",
  },
  tab: {
    margin: "auto",
    display: "block",
    textAlign: "center",
  },
  uniSpace: {
    maxWidth: "15%",
    marginBottom: "-0.4em",
  },
  cvxSpace: {
    maxWidth: "15%",
  },
  crvSpace: {
    maxWidth: "8%",
    marginTop: "0.5em",
    marginBottom: "0.6em",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  arrowButton: {
    color: "black",
  },
  votBox: {
    width: "3em",
    marginTop: "1em",
  },
  accordion: {
    width: "40em",
  },
  activeContainer: {
    alignItems: "center",
  },
  bribesContainer: {
    display: "grid",
    width: "75%",
    gridTemplateColumns: "repeat(auto-fit,360px)",
  },
  arrowsContainer: {
    marginBottom: "4em",
    marginTop: "-6em",
  },
  votTokenInput: {
    width: "2em",
  },
  submitButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "#141E27",
    color: theme.palette.common.lgrey,
    marginBottom: "-1em",
  },
  toggleButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "#e3e5e6",
    color: "black",
  },
  toggleButtonDisabled: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "rgb(24 25 27)",
    color: "grey",
  },
  incentivesAccordion: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "#e3e5e6",
    color: "black",
    width: "40em",
    borderRadius: "0.5em",
  },
  tableTextBottom: {
    borderBottom: "none",
  },
  faqButton: {
    color: "grey",
    textTransform: "none",
    margin: "1em",
    width: "40%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-25em",
  },
  modalPaper: {
    borderRadius: "3px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: "45em",
    width: "20em",
    alignItems: "center",
  },
  forwardModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-15em",
  },
  forwardModalPaper: {
    backgroundColor: "white",
    borderRadius: "3px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: "45em",
    width: "50em",
    alignItems: "center",
  },
  modalTitle: {
    fontSize: "1em",
    fontWeight: "700",
    marginTop: "-1em",
  },
  modalDelegateButton: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    margin: "1em",
    background: "#141e27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    height: "3.7em",
  },
  modalDelegateButtonDisabled: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    margin: "1em",
    background: "#cccecf",
    borderRadius: "5px",
    color: "white",
    height: "3.7em",
  },
  modalText: {
    textAlign: "center",
    margin: "1em",
    fontWeight: "400",
  },
  modalTextSmall: {
    textAlign: "center",
    fontSize: "0.8em",
    margin: "1em",
  },
  modalClaimButton: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "70px",
    background: "#141E27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    height: "3em",
    padding: "1em",
    left: "35%",
    marginTop: "1.5em",
    marginBottom: "-0.1em",
  },
  modalClaimButtonDisabled: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "70px",
    background: "#cccecf",
    borderRadius: "5px",
    height: "3em",
    padding: "1em",
    left: "28%",
    marginTop: "1.5em",
    marginBottom: "-0.1em",
  },
  modalInput: {
    color: "green",
    width: "20em",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black", // Change the border color to white (#fff)
      },
      "&:hover fieldset": {
        borderColor: "black", // Change the border color to white (#fff) on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "black", // Change the border color to white (#fff) when focused
      },
    },
  },
  label: {
    color: "rgb(50 50 50)", // Use your desired color
    "&.Mui-focused": {
      color: "black", // Use your desired color
    },
  },
  inputRoot: {
    color: "black",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#888",
    },
    cursor: "default",
    width: "100%",
  },
  helperText: {
    color: "black",
  },
}));
