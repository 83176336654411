/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ref, onValue } from "firebase/database";
import { Typography, Grid, Paper, Button, Checkbox } from "@material-ui/core";
import { ethers } from "ethers";
import { useStylesDark, useStylesLight } from "../../../Styles/ClaimCardStyles";
import {
  merkleABI,
  merkleAddressEthereumCurve,
  merkleAddressFantom,
  merkleAddressOptimism,
} from "../../../constants";

const ClaimCard = (props) => {
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  const [classes, setClasses] = useState(classesDark);
  const [isClaimed, setIsClaimed] = useState(false);
  const [disableCheckbox, setDisableCheckbox] = useState(true);
  const [earnedText, setEarnedText] = useState("-");
  const [amountValue, setAmountValue] = useState();
  const [merkleProofValue, setMerkleProofValue] = useState();
  const [indexValue, setIndexValue] = useState(null);
  const [tokenPriceValue, setTokenPriceValue] = useState("-");
  const [refreshDB, setRefreshDB] = useState(1);
  const [emptyArray, setEmptyArray] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(true);
  const [refreshNetowrk, setRefreshNetwork] = useState(0);
  const [connectedChain, setConnectedChain] = useState(0);
  const [claimText, setClaimText] = useState("Single Claim");
  const [merkleAddress, setMerkleAddress] = useState(
    merkleAddressEthereumCurve
  );

  // theme setting 
  useEffect(() => {
    if (props.ctheme === 'light') {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [props.ctheme]);
  
  //check provider network
  useEffect(() => {
    if (props.provider !== undefined) {
      if (props.provider._network !== undefined) {
        setRefreshNetwork(props.provider._network.chainId);
        switch (props.provider._network.chainId) {
          case 1:
            setMerkleAddress(merkleAddressEthereumCurve);
            setConnectedChain(1);
            break;
          case 250:
            setMerkleAddress(merkleAddressFantom);
            setConnectedChain(250);
	    break;
	  case 10:
	    setMerkleAddress(merkleAddressOptimism);
	    setConnectedChain(10);
	    break;
          default:
            break;
        }
        if (props.provider._network.chainId !== props.token.chainId) {
          switch (props.token.chainId) {
            case 1:
              setClaimText("Connect to Ethereum");
              break;
            case 250:
              setClaimText("Connect to Fantom");
	      break;
	    case 10:
	      setClaimText("Connect to Optimism");
	      break;
            default:
              break;
          }
        } else {
          setClaimText("Single Claim");
        }
      } else if (refreshNetowrk > -30) {
        setRefreshNetwork(refreshNetowrk - 1);
      }
    }
  }, [props.provider, props.db, refreshNetowrk]);

  useEffect(() => {
    async function getInfoFromDatabase() {
      if (props.db && props.provider && props.address) {
        try {
          if (amountValue === undefined) {
            onValue(
              ref(
                props.db,
                "vecrv/claims/" +
                  props.token.value.toUpperCase() +
                  "/claims/" +
                  props.address +
                  "/amount/"
              ),
              (snapshot) => {
                const data = snapshot.val();
                if (data) {
                  setAmountValue(data.toString());
                  const value = ethers.BigNumber.from(data);
                  let formattedData = (
                    value /
                    10 ** props.token.decimals
                  ).toFixed(4);
                  if (value === 0) {
                    setEarnedText("-");
                  } else {
                    if (value != 0 && formattedData == 0) {
                      setEarnedText("<0.01");
                    } else {
                      setEarnedText(formattedData);
                    }
                  }
                } else {
                  console.log("Couldn't get earned balance from db");
                  //setAmountValue(1);
                }
              }
            );
          }
          if (merkleProofValue === undefined) {
            onValue(
              ref(
                props.db,
                "vecrv/claims/" +
                  props.token.value.toUpperCase() +
                  "/claims/" +
                  props.address +
                  "/proof/"
              ),
              (snapshot) => {
                const data = snapshot.val();
                if (data) {
                  setMerkleProofValue(data);
                } else {
                  console.log("Couldn't get merkle proof from db");
                  //setMerkleProofValue(1);
                }
              }
            );
          }
          if (indexValue === null) {
            onValue(
              ref(
                props.db,
                "vecrv/claims/" +
                  props.token.value.toUpperCase() +
                  "/claims/" +
                  props.address +
                  "/index/"
              ),
              (snapshot) => {
                const data = snapshot.val();
                if (data !== null) {
                  setIndexValue(data.toString());
                } else {
                  console.log("Couldn't get index from db");
                  setEmptyArray(true);
                }
              }
            );
          }
          if (tokenPriceValue === "-") {
            onValue(
              ref(props.db, "api/tokens/" + props.token.value.toUpperCase()),
              (snapshot) => {
                const data = snapshot.val();
                if (data !== null) {
                  setTokenPriceValue(data.toString());
                } else {
                  setTokenPriceValue("?");
                  console.log("Couldn't get token usd value from db");
                }
              }
            );
          }
        } catch {
          setRefreshDB(refreshDB + 1);
          console.log("Couldn't get info from database");
        }
      }
    }
    try {
      if (refreshDB < 30 && props.db && props.provider && props.address) {
        getInfoFromDatabase();
      }
    } catch {
      console.log("Couldn't run getRefs/getInfoFromDatabase");
    }
  }, [props.db, props.provider, props.address, refreshDB]);

  //check if user already claimed airdrop
  useEffect(() => {
    async function isClaimed() {
      const { chainId } = await props.provider.getNetwork();
      if (chainId !== props.token.chainId) {
      } else {
        const merkleContract = new ethers.Contract(
          merkleAddress,
          merkleABI,
          props.provider
        );
        try {
          const isClaimedValue = await merkleContract.isClaimed(
            props.token.value,
            indexValue
          );
          if (isClaimedValue) {
            setIsClaimed(true);
            setDisableCheckbox(true);
            if (earnedText != 0) {
              setEarnedText("0.00");
            }
          } else {
            setDisableCheckbox(false);
          }
        } catch {
          console.log("Couldn't check if claimed");
        }
      }
    }
    try {
      if (indexValue) {
        isClaimed();
      }
    } catch {
      console.log("Couldn't check if claimed");
    }
  }, [props.provider, indexValue, earnedText]);

  const handleClaim = async () => {
    const signer = props.provider.getSigner();
    const merkleContract = new ethers.Contract(
      merkleAddress,
      merkleABI,
      signer
    );
    try {
      console.log(indexValue);
      console.log(amountValue);
      console.log(merkleProofValue);
      const tx = await merkleContract.claim(
        props.token.value,
        indexValue,
        props.address,
        amountValue,
        merkleProofValue
      );
      await tx.wait();
    } catch {
      console.log("Couldn't claim");
    }
  };

  //add token merkle array to claimPage claim array
  useEffect(() => {
    let merkleArray = [
      props.token.value,
      Number(indexValue),
      amountValue,
      merkleProofValue,
    ];
    if (emptyArray) {
      let merkleArray = [undefined, undefined, undefined, undefined];
      props.addToClaimArray(merkleArray);
    }
    if (
      props.token.value &&
      indexValue !== null &&
      props.address &&
      amountValue &&
      merkleProofValue
    ) {
      props.addToClaimArray(merkleArray);
    }
  }, [indexValue, amountValue, merkleProofValue, props.address, emptyArray]);

  const handleCheckboxChange = () => {
    props.handleCheckboxChange(props.checkboxIndex, checkboxValue);
    setCheckboxValue(!checkboxValue);
  };

  return (
    earnedText !== "-" && (
      <div
        className={classes.cardDiv}
        style={{ order: 10000000 - Math.floor(earnedText * tokenPriceValue) }}
      >
        <Grid container direction="row" className={classes.cardContainer}>
          <Grid item>
            <Paper className={classes.noActiveAuctionCard}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                className={classes.cardContainer}
              >
                <Grid item sm={2} xs={3}>
                  <Grid container direction="row" justifyContent="flex-start">
                    <Grid item className={classes.tokenImg}>
                      {props.token.img}
                    </Grid>
                    <Grid item>
                      <Typography className={classes.tokenText}>
                        {props.token.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={6} xs={4}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography className={classes.subTitle}>
                        Earned
                      </Typography>
                    </Grid>
                    <Grid item>
                      {props.token.chainId === connectedChain ? (
                        <Typography className={classes.earnedText}>
                          {earnedText}
                          <Typography className={classes.usdText}>
                            ≈ $
                            {isNaN(earnedText * tokenPriceValue)
                              ? "-"
                              : ethers.utils.commify(
                                  Number(earnedText * tokenPriceValue)
                                    .toFixed(2)
                                    .toString()
                                )}
                          </Typography>
                        </Typography>
                      ) : (
                        <Typography>?</Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={3} xs={2}>
                  <Button
                    classes={{
                      root: classes.submitButton,
                      disabled: classes.submitButtonDisabled,
                    }}
                    onClick={handleClaim}
                    disabled={
                      earnedText === "-" ||
                      isClaimed ||
                      props.disableButton ||
                      refreshNetowrk !== props.token.chainId
                    }
                  >
                    {claimText}
                  </Button>
                </Grid>{" "}
                <Grid item sm={1} xs={3} justify="center">
                  <Checkbox
                    className={classes.checkbox}
                    color="rgb(34 36 38)"
                    disabled={disableCheckbox}
                    onChange={handleCheckboxChange}
                  ></Checkbox>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  );
};

export default ClaimCard;
