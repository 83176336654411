import React, { useEffect, useState } from "react";
import { ref, onValue } from "firebase/database";
import {
  Grid,
  Toolbar,
  Button,
  IconButton,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import {
  FaDiscord,
  FaTwitterSquare,
  FaGithubSquare,
  FaBook,
} from "react-icons/fa";
import { IoIosJet } from "react-icons/io";
import logo from "../../assets/votpng.png";
import WalletButton from "./components/WalletButton";
import crvToken from "../../assets/crvToken.png";
import cvxLogo from "../../assets/cvxLogo.png";
import votBoxOutlined from "../../assets/votBoxOutlined.png";
import { ethers } from "ethers";
import {
  snapshotAddress,
  snapshotABI,
} from "../../constants";
import { useStylesDark, useStylesLight } from "../../Styles/HeaderStyles";
import { Link } from "react-router-dom";

const Header = (props) => {
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  const [classes, setClasses] = useState(classesDark);
  const [openModal, setOpenModal] = useState(false);
  const [totalLocked, setTotalLocked] = useState("0.00");
  const [totalveCRVRef, setTotalveCRVRef] = useState();
  const [totalveCRVAmount, setTotalveCRVAmount] = useState("0.00");
  const [delegatedAmount, setDelegatedAmount] = useState("0.00");
  const [delegateText, setDelegateText] = useState("Connect to Ethereum");
  const [buttonText, setButtonText] = useState("Claim Rewards");
  const [totalDelegatedRef, setTotalDelegatedRef] = useState();
  const [totalDelegatedAmount, setTotalDelegatedAmount] =
    useState("Loading...");
  const [totalLockedRef, setTotalLockedRef] = useState();
  const [totalLockedAmount, setTotalLockedAmount] = useState("Loading...");
  const [addressLockedRef, setAddressLockedRef] = useState(null);
  const [addressPendingAmount, setaddressPendingAmount] =
    useState("Loading...");
  const [addressVotingAmount, setAddressVotingAmount] = useState("Loading...");
  const [connectedNetwork, setConnectedNetwork] = useState(0);
  const [refreshAddressCheck, setRefreshAddressCheck] = useState(0);
  const [refreshDB, setRefreshDB] = useState(0);

  // theme setting
  useEffect(() => {
    if (props.ctheme === "light") {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [props.ctheme]);

  // check provider network
  useEffect(() => {
    if (props.provider !== undefined) {
      if (props.provider._network !== undefined) {
        setConnectedNetwork(props.provider._network.chainId);
        if (props.provider._network.chainId !== 1) {
          setDelegateText("Connect to Ethereum");
        } else {
          setDelegateText("Delegate");
        }
      } else if (connectedNetwork > -100) {
        setTimeout(() => {
          setConnectedNetwork(connectedNetwork - 1);
        }, 100);
      }
    }
  }, [props.provider, connectedNetwork]);

  //get address amount from database
  useEffect(() => {
    async function getRefs() {
      if (props.address) {
        const addressLockedRef = ref(
          props.db,
          "/vecrv/votes/" + props.address.toUpperCase() + "/totals/"
        );
        setAddressLockedRef(addressLockedRef);
      } else {
        setAddressLockedRef(null);
        setRefreshAddressCheck(refreshAddressCheck + 1);
      }
    }
    async function getInfoFromDatabase() {
      if (props.db) {
        try {
          const totalLockedAmountRef = ref(props.db, "vecrv/totals");
          onValue(totalLockedAmountRef, (snapshot) => {
            const data = snapshot.val();
            if (data !== null) {
              setTotalLockedAmount(
                ethers.utils.commify(
                  Number(data.effective).toFixed(0).toString()
                )
              );
              setTotalveCRVAmount(
                ethers.utils.commify(Number(data.locked).toFixed(0).toString())
              );
            } else {
              console.log("Couldn't get total delegated from db");
            }
          });
          if (addressLockedRef !== null) {
            onValue(addressLockedRef, (snapshot) => {
              const data = snapshot.val();
              if (data !== null) {
                setaddressPendingAmount(
                  ethers.utils.commify(
                    Number(data.locked).toFixed(2).toString()
                  )
                );
                setAddressVotingAmount(
                  ethers.utils.commify(
                    Number(data.effective).toFixed(2).toString()
                  )
                );
              } else {
                console.log("Couldn't get address amount from db");
                setAddressLockedRef(null);
              }
            });
          } else {
            setRefreshAddressCheck(refreshAddressCheck + 1);
          }
        } catch {
          console.log("Couldn't get info from database");
          setRefreshAddressCheck(refreshAddressCheck + 1);
        }
      }
    }
    if (refreshAddressCheck < 50) {
      try {
        getRefs();
        getInfoFromDatabase();
      } catch {
        if (props.provider !== undefined) {
          if (props.provider._network !== undefined) {
            console.log(props.provider._network.chainId);
          }
        }
        console.log();
        setRefreshAddressCheck(refreshAddressCheck + 1);
      }
    } else {
      console.log("Couldn't run getRefs / getInfoFromDatabase");
    }
  }, [props.db, props.provider, props.address, refreshAddressCheck]);

  const handleDelegate = async () => {
    const signer = props.provider.getSigner();
    const snapshotContract = new ethers.Contract(
      snapshotAddress,
      snapshotABI,
      signer
    );
    const tx = await snapshotContract.setDelegate(
      ethers.utils.formatBytes32String("cvx.eth"),
      "0xde1E6A7ED0ad3F61D531a8a78E83CcDdbd6E0c49"
    );
    await props.provider.sendTransaction(tx);
    setDelegateText("Succssesfully delegated!");
  };

  useEffect(() => {
    if (window.location.pathname === "/curve/claim") {
      setButtonText("View Bids");
    } else {
      setButtonText("Claim Rewards");
    }
  }, []);
  const handleHeaderButton = () => {
    if (buttonText === "Claim Rewards") {
      setButtonText("View Bids");
    } else {
      setButtonText("Claim Rewards");
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className={classes.header}>
        <Toolbar disableGutters className={classes.toolbar}>
          {/* Header logo and links */}
          <Grid container justifyContent="center" alignItems="center">
            <Grid item sm={3} xs={3}>
              <img alt="votium logo" className={classes.logo} src={logo} />
            </Grid>
            <Grid item sm={6} xs={4} align="center">
              <Grid container direction="row" justifyContent="center">
                <Grid item sm={6} xs={4}>
                  <Grid container direction="row" justifyContent="center">
                    <Grid item>
                      <IconButton
                        aria-label="discord"
                        onClick={() => {
                          window.open("https://discord.gg/dvPcqPAbTh");
                        }}
                      >
                        <FaDiscord className={classes.bookIcon}></FaDiscord>
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="twitter"
                        onClick={() => {
                          window.open("https://twitter.com/VotiumProtocol");
                        }}
                      >
                        <FaTwitterSquare className={classes.socialIcon} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="github"
                        onClick={() => {
                          window.open("https://github.com/oo-00/Votium");
                        }}
                      >
                        <FaGithubSquare className={classes.socialIcon} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="llama.airforce"
                        onClick={() => {
                          window.open("https://llama.airforce/#/votium/bribes");
                        }}
                      >
                        <IoIosJet className={classes.socialIcon} />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        aria-label="docs"
                        onClick={() => {
                          window.open("https://docs.votium.app/");
                        }}
                      >
                        <FaBook size="0.9em" className={classes.bookIcon} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={3} xs={5} className={classes.address}>
              <Grid container direction="column" alignItems="right">
                <Grid item>
                  <WalletButton
                    provider={props.provider}
                    connect={props.connect}
                    disconnect={props.disconnect}
                    address={props.address}
                    wallet={props.wallet}
                  />
                </Grid>
                <Grid item className={classes.switchButton}>
                  <Link to="/" className={classes.switchButtonLink}>
                    <Button className={classes.switchButtonText}>
                      Switch to
                      <img
                        alt="cvx logo"
                        className={classes.cvxButton}
                        src={cvxLogo}
                      />
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        {/* Balances */}
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            md={8}
            xs={12}
          >
            <Grid item xs={4}>
              <Typography className={classes.stakedVotiText}>
                Eligible for Incentives
              </Typography>
              <Grid container direction="row" justifyContent="center">
                <Grid item>
                  <img
                    alt="snapshot-logo"
                    src={crvToken}
                    className={classes.stakedCVXLogo}
                  ></img>
                </Grid>
                <Grid item>
                  <Typography className={classes.stakedVotiAmount}>
                    {totalLockedAmount}
                  </Typography>
                </Grid>
                <Grid container direction="column">
                  <Grid item>
                    <Typography className={classes.totalText}>
                      {totalveCRVAmount + " locked"}
                    </Typography>
                  </Grid>
                </Grid>
                {addressPendingAmount !== "0.0" ? (
                  <>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography className={classes.totalText}>
                          &nbsp;
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.delegatedSpacesTitle}>
                <div className={classes.headText}>
                  <div>
                    <img
                      src={votBoxOutlined}
                      alt="outlined"
                      className={classes.headImage}
                    />
                  </div>
                  <div className={classes.imgText}>
                    <Typography className={classes.textInside}></Typography>
                  </div>
                  <div className={classes.imgTextAmount}>
                    {window.location.pathname !== "/curve/claim" ? (
                      <Link
                        to="/curve/claim"
                        className={classes.claimButton}
                        variant="outlined"
                        onClick={handleHeaderButton}
                      >
                        {buttonText}
                      </Link>
                    ) : (
                      <Link
                        to="/curve"
                        className={classes.claimButton}
                        variant="outlined"
                        onClick={handleHeaderButton}
                      >
                        {buttonText}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
            {/* <Grid item xs={4}>
              <Typography className={classes.delegatedAmountText}>
                Your veCRV Amount
                <Tooltip title="Placeholder text.">
                  <Info className={classes.infoIcon} />
                </Tooltip>
              </Typography>
              <Grid container direction="column" justifyContent="center">
                <Grid container direction="row" justifyContent="center">
                  <Grid item>
                    <img
                      alt="snapshot-logo"
                      src={crvToken}
                      className={classes.stakedCVXLogo}
                    ></img>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.stakedVotiAmount}>
                      something
                    </Typography>
                  </Grid>
                </Grid>
                {connectedNetwork === 1 ? (
                  <></>
                ) : (
                  <Grid item>
                    <Typography className={classes.connectText}>
                      Connect to Ethereum to show veCRV balance
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid> */}
            <Grid item xs={4}>
              <Typography className={classes.delegatedAmountText}>
                Voting Power
                <Tooltip title="To increase your voting power after locking additional veCRV, you will have to vote again.">
                  <Info className={classes.infoIcon} />
                </Tooltip>
              </Typography>
              <Grid container direction="row" justifyContent="center">
                <Grid item>
                  <img
                    alt="snapshot-logo"
                    src={crvToken}
                    className={classes.stakedCVXLogo}
                  ></img>
                </Grid>
                <>
                  <Grid item>
                    <Typography className={classes.stakedVotiAmount}>
                      {addressLockedRef !== null ? addressVotingAmount : 0}
                    </Typography>
                  </Grid>
                  {addressPendingAmount !== "0.0" ? (
                    <>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography className={classes.pendingAmountText}>
                            Total locked:{" "}
                            <span className={classes.pendingAmount}>
                              {addressLockedRef !== null
                                ? addressPendingAmount
                                : 0}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </>
                <Grid item></Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            md={8}
            xs={12}
          ></Grid>
        </Grid>
      </div>
    </>
  );
};

export default Header;
