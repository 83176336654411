/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  IconButton,
  Switch,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ActiveAuctionCard from "./Components/ActiveAuctionCardCurve";
import AuctionAccordion from "./Components/AuctionAccordionCurve";
import Bribes from "./Components/BribesCurve";
import { useStylesDark, useStylesLight } from "../../../Styles/AuctionPageStyles";

const auctionsArray = [
  {
    id: 0,
  },
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
];

const AuctionPage = (props) => {
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  const [classes, setClasses] = useState(classesDark);
  const [closedAuctions, setClosedAuctions] = useState(auctionsArray);
  const [activeAuctions, setActiveAuctions] = useState(auctionsArray);
  const [noActiveAuction, setNoActiveAuction] = useState(true);
  const [currentActiveAuction, setCurrentActiveAuction] = useState(0);
  const [isForwardArrowValid, setIsForwardArrowValid] = useState(false);
  const [isBackArrowValid, setIsBackArrowValid] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [refreshAuction, setRefreshAuction] = useState(true);
  const [msgValue, setMsgValue] = useState(null);
  const [displayAccordion, setDisplayAccordion] = useState(true);
  const [filterValue, setFilterValue] = useState(true);
  const [nextDate, setNextDate] = useState("Loading...");
  const [currDate, setCurrDate] = useState("Loading...");
  const [prevDate, setPrevDate] = useState("Loading...");

  // theme setting 
  useEffect(() => {
    if (props.ctheme === 'light') {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [props.ctheme]);
  
  // get next date
  useEffect(async () => {
    try {
      let setDate =
        Math.floor(Math.floor(Date.now() / 1000) / (60 * 60 * 24 * 14)) *
          (60 * 60 * 24 * 14) +
        60 * 60 * 24 * (auctionsArray[currentActiveAuction].id + 2) * 7;
      setDate = new Date(setDate * 1000);
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var year = setDate.getFullYear();
      var month = months[setDate.getMonth()];
      var date = setDate.getDate();
      var j = Number(date) % 10;
      var k = Number(date) % 100;
      if (j == 1 && k != 11) {
        var ndate = date + "st";
      } else if (j == 2 && k != 12) {
        var ndate = date + "nd";
      } else if (j == 3 && k != 13) {
        var ndate = date + "rd";
      } else {
        var ndate = date + "th";
      }
      var hour =
        setDate.getHours() > 12
          ? setDate.getHours() - 12 + " PM"
          : setDate.getHours() + " AM";
      var zone = setDate
        .toLocaleDateString(undefined, {
          day: "2-digit",
          timeZoneName: "short",
        })
        .substring(4);
      var time = month + " " + ndate;
      setNextDate(time);
    } catch {
      console.log("Couldn't figure out date");
    }
  }, [currentActiveAuction]);

  // get curr date
  useEffect(async () => {
    try {
      let setDate =
        Math.floor(Math.floor(Date.now() / 1000) / (60 * 60 * 24 * 14)) *
          (60 * 60 * 24 * 14) +
        60 * 60 * 24 * (auctionsArray[currentActiveAuction].id + 1) * 7;
      setDate = new Date(setDate * 1000);
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var year = setDate.getFullYear();
      var month = months[setDate.getMonth()];
      var date = setDate.getDate();
      var j = Number(date) % 10;
      var k = Number(date) % 100;
      if (j == 1 && k != 11) {
        var ndate = date + "st";
      } else if (j == 2 && k != 12) {
        var ndate = date + "nd";
      } else if (j == 3 && k != 13) {
        var ndate = date + "rd";
      } else {
        var ndate = date + "th";
      }
      var hour =
        setDate.getHours() > 12
          ? setDate.getHours() - 12 + " PM"
          : setDate.getHours() + " AM";
      var zone = setDate
        .toLocaleDateString(undefined, {
          day: "2-digit",
          timeZoneName: "short",
        })
        .substring(4);
      var time = month + " " + ndate + ", " + hour + " " + zone;
      setCurrDate(time);
    } catch {
      console.log("Couldn't figure out date");
    }
  }, [currentActiveAuction]);

  // get prev date
  useEffect(async () => {
    try {
      let setDate =
        Math.floor(Math.floor(Date.now() / 1000) / (60 * 60 * 24 * 14)) *
          (60 * 60 * 24 * 14) +
        60 * 60 * 24 * auctionsArray[currentActiveAuction].id * 7;
      setDate = new Date(setDate * 1000);
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var year = setDate.getFullYear();
      var month = months[setDate.getMonth()];
      var date = setDate.getDate();
      var j = Number(date) % 10;
      var k = Number(date) % 100;
      if (j == 1 && k != 11) {
        var ndate = date + "st";
      } else if (j == 2 && k != 12) {
        var ndate = date + "nd";
      } else if (j == 3 && k != 13) {
        var ndate = date + "rd";
      } else {
        var ndate = date + "th";
      }
      var hour =
        setDate.getHours() > 12
          ? setDate.getHours() - 12 + " PM"
          : setDate.getHours() + " AM";
      var zone = setDate
        .toLocaleDateString(undefined, {
          day: "2-digit",
          timeZoneName: "short",
        })
        .substring(4);
      var time = month + " " + ndate;
      setPrevDate(time);
    } catch {
      console.log("Couldn't figure out date");
    }
  }, [currentActiveAuction]);

  //hide accordion if proposal is over
  const isAuctionOver = (deadlineReached) => {
    setDisplayAccordion(!deadlineReached);
  };

  let displayActiveAuctions = activeAuctions
    .slice(currentActiveAuction, currentActiveAuction + 1)
    .map((auction) => {
      const auctionWeek =
        Math.floor(Date.now() / 1000 / (60 * 60 * 24 * 7)) +
        currentActiveAuction;
      console.log(auctionWeek);
      return (
        <>
          <ActiveAuctionCard
            auction={auction}
            contract={props.contract}
            id={auction.id}
            refreshAuction={refreshAuction}
            provider={props.provider}
            isAuctionOver={isAuctionOver}
            db={props.db}
            currentActiveAuction={currentActiveAuction}
            currDate={currDate}
            ctheme={props.ctheme}
          />
          <AuctionAccordion
            auction={auction}
            contract={props.contract}
            stakingContract={props.stakingContract}
            provider={props.provider}
            address={props.address}
            id={auction.id}
            msgValue={msgValue}
            refreshAuction={refreshAuction}
            displayAccordion={displayAccordion}
            db={props.db}
            currentActiveAuction={currentActiveAuction}
            ctheme={props.ctheme}
          />
          <Bribes
            auction={auction}
            provider={props.provider}
            address={props.address}
            contract={props.contract}
            id={auction.id}
            auctionWeek={auctionWeek}
            refreshAuction={refreshAuction}
            db={props.db}
            filter={filterValue}
            ctheme={props.ctheme}
          />
        </>
      );
    });

  //set initial state of forward/back arrows
  useEffect(() => {
    setRefreshAuction(!refreshAuction);
    if (true) {
      setIsForwardArrowValid(true);
    }
  }, [props.provider, activeAuctions]);

  const handleNextActiveAuction = () => {
    setRefreshAuction(!refreshAuction);
    setIsBackArrowValid(true);
    let nextActiveAuctionNum = currentActiveAuction + 1;
    if (activeAuctions.length - 1 === nextActiveAuctionNum) {
      setIsForwardArrowValid(false);
    } else {
      setIsForwardArrowValid(true);
    }
    setCurrentActiveAuction(nextActiveAuctionNum);
  };

  const handlePrevActiveAuction = () => {
    setRefreshAuction(!refreshAuction);
    setIsForwardArrowValid(true);
    let prevActiveAuctionNum = currentActiveAuction - 1;
    if (prevActiveAuctionNum === 0) {
      setIsBackArrowValid(false);
    }
    setCurrentActiveAuction(prevActiveAuctionNum);
  };

  const handleFilter = () => {
    setFilterValue(!filterValue);
  };

  return (
    <>
      <>
        <Grid
          container
          direction="row"
          className={classes.arrowsContainer}
          justifyContent="center"
        >
          <IconButton
            onClick={handlePrevActiveAuction}
            disabled={!isBackArrowValid}
            disableRipple
            className={classes.arrowButton}
          >
            <Typography>Week of {prevDate}</Typography>
            <ArrowBackIcon />
          </IconButton>
          <IconButton
            onClick={handleNextActiveAuction}
            disabled={!isForwardArrowValid}
            disableRipple
            className={classes.arrowButton}
          >
            <ArrowForwardIcon />
            <Typography>Week of {nextDate}</Typography>
          </IconButton>
        </Grid>
      </>
      <Grid container direction="row" className={classes.auctionsContainer}>
        <>
          <Grid
            container
            direction="column"
            justifyContent="center"
            className={classes.activeContainer}
          >
            {displayActiveAuctions}
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Switch onClick={handleFilter} />
            </Grid>
            <Grid item>
              <Typography className={classes.filterText}>
                Show incentives below $10k total
              </Typography>
            </Grid>
          </Grid>
        </>
      </Grid>
    </>
  );
};

export default AuctionPage;
