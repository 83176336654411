/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Modal,
  Fade,
  Backdrop,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { Info, Edit, OpenInNew } from "@material-ui/icons";
import { MdContentCopy } from "react-icons/md";
import ClaimCard from "./ClaimCard";
import votBox from "../../assets/votBox.png";
import lafLogo from "../../assets/lafLogo.png";
import { ethers } from "ethers";
import { useStylesDark, useStylesLight } from "../../Styles/ClaimPageStyles";
import { tokenOptions } from "../Auction/tokenOptions";
import { ref, onValue } from "firebase/database";
import {
  merkleAddressEthereum,
  merkleABI,
  addressRegistryABI,
  addressRegistryAddress,
  snapshotAddress,
  snapshotABI,
  vlCVXAddress,
  vlCVXABI,
  depositAddressEthereum,
  depositABI,
} from "../../constants";
import cvxLogo from "../../assets/cvxLogo.png";
import { BsFillLightningFill } from "react-icons/bs";

var _ = require("lodash");

const specialColorDark = {
  style: { color: "rgb(232, 230, 227)" }, // Set the color of the text input in the TextField to white (#fff)
};

const AccountDetails = (props) => {
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  const [classes, setClasses] = useState(classesDark);
  const [unconsumedDeposits, setUnconsumedDeposits] = useState(false);
  const [lockedAmount, setLockedAmount] = useState("0");
  const [openModal, setOpenModal] = useState(false);
  const [openForwardingModal, setOpenForwardingModal] = useState(false);
  const [delegateText, setDelegateText] = useState("Connect to Ethereum");
  const [connectedNetwork, setConnectedNetwork] = useState(0);
  const [forwardAddress, setForwardAdress] = useState("");
  const [disableCancel, setDisableCancel] = useState(true);
  const [disableForward, setDisableForward] = useState(true);
  const [forwardedToText, setForwardedToText] = useState("N/A");
  const [bothDisabledText, setBothDisabledText] = useState("");
  const [isDelegated, setIsDelegated] = useState(true);

  const createData = (title, info, id) => {
    return { title, info, id };
  };
  const [rows, setRows] = useState([
    createData("Locked CVX Amount", "Loading...", 0), //make line an accordion which expands to show expiry date etc.
    createData(
      <>
        Delegation Status{" "}
        <Tooltip
          title="To be eligible for the rewards, you have to be locked and delegated
                before the convex snapshot proposal started.
                Votium will try to vote for incentives optimally, 
                giving vlCVX holders the best returns. 
                Delegating to Votium is not mandatory.
                You can vote for yourself to receive incentives."
        >
          <Info className={classes.infoIcon} />
        </Tooltip>
      </>,
      "Not Delegated to Votium",
      1
    ),
    createData(
      <>
        Current Forwarding{" "}
        <Tooltip
          title="Address Forwarding is a feature that allows you to forward future Votium rewards to be claimed by a different address/contract (e.g. The Union).
        All changes to address registrations have a one round waiting period before taking effect. When canceling an active registration, you will not be able to register a new forwarding address until the following round, meaning there will be one round with no forwarding."
        >
          <Info className={classes.infoIcon} />
        </Tooltip>
      </>,
      "The Union",
      2
    ),
    // createData("Current Vote", "ETH-USDC (0x42..21)", 3), //hide line if delegated.
    createData("Votes History"), // make accordion
  ]);

  // check provider network
  useEffect(() => {
    if (props.provider !== undefined) {
      if (props.provider._network !== undefined) {
        setConnectedNetwork(props.provider._network.chainId);
        if (props.provider._network.chainId !== 1) {
          setDelegateText("Connect to Ethereum");
          setDisableCancel(true);
          setDisableForward(true);
        } else {
          setDelegateText("Delegate");
          setDisableCancel(false);
          setDisableForward(false);
        }
      } else if (connectedNetwork > -100) {
        setTimeout(() => {
          setConnectedNetwork(connectedNetwork - 1);
        }, 100);
      }
    }
  }, [props.provider, connectedNetwork]);

  //check if user is delegated
  useEffect(() => {
    async function getInfoFromDatabase() {
      if (props.db) {
        try {
          const addressRef = ref(
            props.db,
            "delegation/users/" + props.address.toUpperCase()
          );
          onValue(addressRef, (snapshot) => {
            let data = snapshot.val();
            if (data !== null && data !== undefined) {
              setIsDelegated(true);
            } else {
              setIsDelegated(false);
            }
          });
        } catch {
          console.log("Couldn't get info from database");
        }
      }
    }

    getInfoFromDatabase();
  }, [props.db, props.proposalHash]);

  //check if address already forwarded
  useEffect(() => {
    //function to fetch amount of vlCVX for current address
    const getvlCVX = async () => {
      let signer = await props.provider.getSigner();
      let vlCVXContractWithSigner = new ethers.Contract(
        vlCVXAddress,
        vlCVXABI,
        signer
      );
      const tx = await vlCVXContractWithSigner.balanceOf(props.address);
      let numFloat = parseFloat(ethers.utils.formatEther(tx));
      let formattedAmount = numFloat.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      setLockedAmount(formattedAmount);
    };
    async function checkForward() {
      const addressRegistryContract = new ethers.Contract(
        addressRegistryAddress,
        addressRegistryABI,
        props.provider
      );
      let res = await addressRegistryContract.registry(props.address);

      //check if forwarded
      if (
        res.start.toNumber() != 0 &&
        res.expiration.toNumber() > Math.floor(Date.now() / 1000)
      ) {
        setDisableCancel(false);
        setDisableForward(true);
        if (
          res.to.toLowerCase() === "0xcc2a0f5e95c88aabd7b8e0db5c5252820cd47f91"
        ) {
          setForwardedToText("The Union");
        } else {
          setForwardedToText(
            res.to.substring(0, 4) +
              "..." +
              res.to.substring(res.to.length - 2, res.to.length)
          );
        }
      } else if (
        // check if they used setToExpire() (cant register new address)
        res.expiration.toNumber() <
        Math.floor(Date.now / 1000) + 60 * 60 * 24 * 14
      ) {
        setDisableCancel(true);
        setDisableForward(true);
        setBothDisabledText(
          "Must wait until next round for current registration to expire"
        );
      } else {
        setDisableCancel(true);
        setDisableForward(false);
      }
    }
    if (props.provider && props.address) {
      if (forwardedToText === "N/A") {
        checkForward();
      }
      if (lockedAmount === "0") {
        getvlCVX();
      }
    }
  }, [props.provider, props.address]);

  //check if user has unconsumed deposits
  useEffect(() => {
    //fetch userDeposits from db
  }, [props.provider, props.address, props.db]);

  //populate table
  useEffect(() => {
    const row0 = createData("Locked CVX Amount", lockedAmount, 0); //make line an accordion which expands to show expiry date etc.
    const row1 = createData(
      <>
        Delegation Status{" "}
        <Tooltip
          title="To be eligible for the rewards, you have to be locked and delegated
                before the convex snapshot proposal started.
                Votium will try to vote for incentives optimally, 
                giving vlCVX holders the best returns. 
                Delegating to Votium is not mandatory.
                You can vote for yourself to receive incentives."
        >
          <Info className={classes.infoIcon} />
        </Tooltip>
      </>,
      isDelegated ? "Votium" : "Not Delegated to Votium",
      1
    );
    const row2 = createData(
      <>
        Forwarding Status{" "}
        <Tooltip
          title="Address Forwarding is a feature that allows you to forward future Votium rewards to be claimed by a different address/contract (e.g. The Union).
        All changes to address registrations have a one round waiting period before taking effect. When canceling an active registration, you will not be able to register a new forwarding address until the following round, meaning there will be one round with no forwarding."
        >
          <Info className={classes.infoIcon} />
        </Tooltip>
      </>,
      forwardedToText + " ",
      2
    );
    // const row3 = createData(
    //   "Current Vote",
    //   "ETH-USDC (0x42..21)",
    //   3
    // ); //hide line if delegated.
    const row4 = createData("Votes History"); // make accordion
    // if (isDelegated) {
    //   array = array.filter((item) => item !== row3);
    // }

    let array = [row0, row1, row2, row4];
    setRows(array);
  }, [lockedAmount, forwardedToText, isDelegated]);

  // theme setting
  useEffect(() => {
    if (props.ctheme === "light") {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [props.ctheme]);

  const handleDelegate = async () => {
    setDelegateText("Waiting for Transaction...");
    const signer = props.provider.getSigner();
    const snapshotContract = new ethers.Contract(
      snapshotAddress,
      snapshotABI,
      signer
    );
    const tx = await snapshotContract.setDelegate(
      ethers.utils.formatBytes32String("cvx.eth"),
      "0xde1E6A7ED0ad3F61D531a8a78E83CcDdbd6E0c49"
    );
    await props.provider.sendTransaction(tx);
    setDelegateText("Succssesfully delegated!");
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenForwardingModal = () => {
    setOpenForwardingModal(true);
  };
  const handleCloseForwardingModal = () => {
    setOpenForwardingModal(false);
  };

  const handleForward = async () => {
    const signer = props.provider.getSigner();
    const addressRegistryContract = new ethers.Contract(
      addressRegistryAddress,
      addressRegistryABI,
      signer
    );
    try {
      const tx = await addressRegistryContract.setRegistry(forwardAddress);
      await tx.wait();
    } catch {
      console.log("Couldn't forward");
    }
  };
  const handleCancel = async () => {
    const signer = props.provider.getSigner();
    const addressRegistryContract = new ethers.Contract(
      addressRegistryAddress,
      addressRegistryABI,
      signer
    );
    try {
      const tx = await addressRegistryContract.setToExpire();
      await tx.wait();
    } catch {
      console.log("Couldn't cancel");
    }
  };

  const handleCopyClick = (value) => setForwardAdress(value);

  return (
    connectedNetwork !== 0 && (
      <>
        <Paper className={classes.accountDetailsCard}>
          {" "}
          <Grid container direction="column" alignItems="center">
            <Typography className={classes.noActiveAuctionTitle}>
              Your Votium information
            </Typography>{" "}
            {unconsumedDeposits && (
              <Typography className={classes.unconsumedDepositsText}>
                You have unconsumed deposits to withdraw: {"<click here>"}
              </Typography>
            )}
            <TableContainer className={classes.infoTable}>
              <Table className={classes.table}>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.title}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ borderBlockColor: "grey" }}
                      >
                        <Typography className={classes.tableText}>
                          {" "}
                          {row.title}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ borderBlockColor: "grey" }}
                      >
                        <Typography className={classes.tableSubText}>
                          {row.info === "The Union " && (
                            <>
                              <img
                                className={classes.icon}
                                alt="icon"
                                src={lafLogo}
                              ></img>
                            </>
                          )}
                          {row.info === "Votium" && (
                            <>
                              <img
                                className={classes.icon}
                                alt="icon"
                                src={votBox}
                              ></img>
                            </>
                          )}
                          {row.id === 0 && (
                            <img
                              src={cvxLogo}
                              alt="cvx-logo"
                              className={classes.icon}
                            ></img>
                          )}
                          {row.info}
                          {row.id === 0 && (
                            <OpenInNew
                              onClick={() => {
                                window.open(
                                  "https://www.convexfinance.com/lock-cvx",
                                  "_blank"
                                );
                              }}
                              className={classes.editButton}
                            />
                          )}
                          {row.id === 1 && (
                            <>
                              {row.info !== "Votium" && (
                                <>
                                  {" "}
                                  <Button
                                    classes={{
                                      root: classes.delegateButton,
                                      disabled: classes.delegateButtonDisabled,
                                    }}
                                    disableRipple
                                    onClick={handleOpenModal}
                                    endIcon={
                                      <BsFillLightningFill fontSize="small"></BsFillLightningFill>
                                    }
                                    disabled={
                                      delegateText === "Connect to Ethereum"
                                    }
                                  >
                                    Delegate
                                  </Button>
                                  <Modal
                                    className={classes.modal}
                                    open={openModal}
                                    onClose={handleCloseModal}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                      timeout: 500,
                                    }}
                                  >
                                    <Fade in={openModal}>
                                      <div className={classes.modalPaper}>
                                        <Grid
                                          container
                                          direction="column"
                                          alignItems="center"
                                        >
                                          <Grid item>
                                            <Typography
                                              className={classes.modalText}
                                            >
                                              To be eligible for the rewards,
                                              you have to be locked and
                                              delegated before the convex
                                              snapshot proposal began.
                                            </Typography>
                                          </Grid>
                                          <Grid item>
                                            <Button
                                              className={classes.faqButton}
                                              onClick={() => {
                                                window.open(
                                                  "https://docs.votium.app/faq/vlcvx-faq"
                                                );
                                              }}
                                            >
                                              Please read the FAQ before
                                              delegating
                                            </Button>
                                          </Grid>
                                          <Grid item>
                                            <Button
                                              className={
                                                classes.modalDelegateButton
                                              }
                                              disableRipple
                                              onClick={handleDelegate}
                                              endIcon={
                                                <BsFillLightningFill fontSize="small"></BsFillLightningFill>
                                              }
                                            >
                                              {delegateText}
                                            </Button>
                                          </Grid>
                                        </Grid>
                                      </div>
                                    </Fade>
                                  </Modal>
                                </>
                              )}
                            </>
                          )}
                          {row.id === 2 && (
                            <>
                              <Edit
                                onClick={handleOpenForwardingModal}
                                className={classes.editButton}
                              />
                              <Modal
                                className={classes.forwardModal}
                                open={openForwardingModal}
                                onClose={handleCloseForwardingModal}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                  timeout: 500,
                                }}
                              >
                                <Fade in={openForwardingModal}>
                                  <div className={classes.forwardModalPaper}>
                                    <Grid
                                      container
                                      direction="column"
                                      alignItems="center"
                                    >
                                      <Grid item>
                                        <Typography
                                          className={classes.modalText}
                                        >
                                          <p>
                                            {" "}
                                            The following transaction will let
                                            you forward your future rewards to
                                            be claimable by another
                                            contract/address.
                                          </p>
                                          <p>
                                            {
                                              "You can choose to forward your rewards to The Union (0xcc...91)"
                                            }
                                            <Button
                                              onClick={() => {
                                                handleCopyClick(
                                                  "0xcc2a0f5e95c88aabd7b8e0db5c5252820cd47f91"
                                                );
                                              }}
                                              disableElevation
                                              className={classes.copyButton}
                                            >
                                              <MdContentCopy></MdContentCopy>
                                            </Button>
                                            <a href="https://llama.airforce/#/union/member">
                                              (What's This?)
                                            </a>
                                          </p>
                                          <p>
                                            {" "}
                                            You can also choose to blacklist
                                            your address from reward
                                            calculations by forwarding to
                                            0x00...00{" "}
                                            <Button
                                              onClick={() => {
                                                handleCopyClick(
                                                  "0x0000000000000000000000000000000000000000"
                                                );
                                              }}
                                              disableElevation
                                              className={classes.copyButton}
                                            >
                                              <MdContentCopy></MdContentCopy>
                                            </Button>
                                          </p>
                                        </Typography>
                                      </Grid>
                                      <Grid item>
                                        <TextField
                                          className={classes.modalInput}
                                          label={"Recepient Address"}
                                          color="secondary"
                                          variant="outlined"
                                          value={forwardAddress}
                                          onChange={(e) => {
                                            setForwardAdress(e.target.value);
                                          }}
                                          inputProps={specialColorDark}
                                          InputLabelProps={specialColorDark}
                                          FormHelperTextProps={specialColorDark}
                                        ></TextField>
                                      </Grid>
                                      <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                      >
                                        <Grid item>
                                          <Button
                                            classes={{
                                              root: classes.modalDelegateButton,
                                              disabled:
                                                classes.modalDelegateButtonDisabled,
                                            }}
                                            disableRipple
                                            onClick={handleCancel}
                                            disabled={disableCancel}
                                          >
                                            Cancel existing forwarding
                                          </Button>
                                        </Grid>
                                        <Grid item>
                                          <Button
                                            classes={{
                                              root: classes.modalDelegateButton,
                                              disabled:
                                                classes.modalDelegateButtonDisabled,
                                            }}
                                            disableRipple
                                            onClick={handleForward}
                                            disabled={disableForward}
                                          >
                                            {forwardAddress ===
                                            "0x0000000000000000000000000000000000000000"
                                              ? "Confirm Blacklisting"
                                              : "Confirm new address"}
                                          </Button>
                                        </Grid>
                                      </Grid>
                                      {forwardedToText !== "" && (
                                        <Grid item>
                                          <Typography>
                                            {"Currently forwarded to: " +
                                              forwardedToText}
                                          </Typography>
                                        </Grid>
                                      )}
                                      {bothDisabledText !== "" && (
                                        <Grid item>
                                          <Typography>
                                            {bothDisabledText}
                                          </Typography>
                                        </Grid>
                                      )}
                                    </Grid>
                                  </div>
                                </Fade>
                              </Modal>
                            </>
                          )}
                          {row.id === 3 && (
                            <BsFillLightningFill
                              onClick={() => {
                                console.log("Edit Button");
                              }}
                              className={classes.snapshotIcon}
                            />
                          )}
                          {row.id === undefined && (
                            <OpenInNew
                              onClick={() => {
                                window.open(
                                  "https://llama.airforce/#/bribes/rounds/votium/",
                                  "_blank"
                                );
                              }}
                              className={classes.editButton}
                            />
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid item align="center">
              <Button
                className={classes.faqButton}
                onClick={() => {
                  window.open("https://docs.votium.app/faq/vlcvx-faq");
                }}
                variant="outlined"
              >
                Frequently Asked Questions
              </Button>
            </Grid>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <img
                  src={votBox}
                  alt="time-icon"
                  className={classes.votBox}
                ></img>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </>
    )
  );
};

export default AccountDetails;
