import { createTheme } from "@material-ui/core/styles";

//https://material-ui.com/customization/default-theme/

const votBlack = "#212121";
const votGrey = "#BEBEBE";

const theme = createTheme({
  palette: {
    common: {
      black: `${votBlack}`,
      grey: `${votGrey}`,
      lgrey: "#F5F5F5",
    },
    primary: {
      main: `${votBlack}`,
    },
    secondary: {
      main: `${votGrey}`,
    },
    action: {
      //disabled: "#BEBEBE",
    },
  },
  typography: {
    tab: {
      fontFamily: "Rubik",
      textTransform: "none",
      fontWeight: "400",
      fontSize: "1rem",
    },
    estimate: {
      fontFamily: "Rubik",
      fontSize: "1rem",
      textTransform: "none",
      color: "white",
    },
    h1: {
      fontFamily: "Rubik",
      fontWeight: 400,
      fontSize: "3.5rem",
      //lineHeight: 1.5,
    },
    h2: {
      fontFamily: "Rubik",
      fontWeight: 400,
      fontSize: "2.5rem",
      //lineHeight: 1.5,
    },
    h3: {
      ontFamily: "Rubik",
      fontWeight: 400,
      fontSize: "2rem",
    },
    h5: {
      fontFamily: "Rubik",
      fontSize: "1.25rem",
      //lineHeight: 1.5,
    },
  },
});

export default theme;
