import React, { useState, useEffect } from "react";
import { ref, onValue } from "firebase/database";
import { ethers } from "ethers";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Grid,
} from "@material-ui/core";
import Select from "react-select";
import { tokenOptions } from "../../tokenOptions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  veCRVABI,
  veCRVAddress,
  veCRVAddressOptimism,
  feeAddressEthereum,
  feeAddressOptimism,
  maxNum,
  erc20ABI,
} from "../../../../constants";
import {
  useStylesDark,
  useStylesLight,
} from "../../../../Styles/DepositFormStyles";

const AuctionAccordion = (props) => {
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  const [classes, setClasses] = useState(classesDark);
  const [selectedToken, setSelectedToken] = useState(tokenOptions[0]);
  const [poolIndex, setPoolIndex] = useState(null);
  const [bidAmount, setBidAmount] = useState("");
  const [isApproved, setIsApproved] = useState(false);
  const [depositRewardText, setdepositRewardText] =
    useState("Deposit Incentive");
  const [placeBidDisabled, setPlaceBidDisabled] = useState(false);
  const [approveText, setApproveText] = useState("Approve Token");
  const [approveDisabled, setApproveDisabled] = useState(false);
  const [currTokenOptions, setCurrTokenOptions] = useState(tokenOptions);
  const [currBribeAddress, setCurrBribeAddress] = useState(veCRVAddress);
  const [chainName, setChainName] = useState("Not supported");
  const [poolOptionsRef, SetPoolOptionsRef] = useState(null);
  const [poolOptions, setPoolOptions] = useState(undefined);
  const [poolOptionsArray, setPoolOptionsArray] = useState([]);
  const [refreshDB, setRefreshDB] = useState(1);
  const [refreshNetworkCheck, setRefreshNetworkCheck] = useState(1);

  // theme setting
  useEffect(() => {
    if (props.ctheme === "light") {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [props.ctheme]);

  //change contract address if changing chain
  useEffect(() => {
    if (props.provider) {
      if (props.provider._network) {
        if (props.provider._network.chainId === 1) {
          setCurrBribeAddress(veCRVAddress);
          setChainName("Ethereum");
        } else if (props.provider._network.chainId === 10) {
          setCurrBribeAddress(veCRVAddressOptimism);
          setChainName("Optimism");
        }
        let newTokenOptions = [];
        console.log("new Token Options array set");
        currTokenOptions.map((token) => {
          if (token.chainId == props.provider._network.chainId) {
            newTokenOptions.push(token);
          }
        });
        setCurrTokenOptions(newTokenOptions);
      } else if (refreshNetworkCheck < 30) {
        setRefreshNetworkCheck(refreshNetworkCheck + 1);
      }
    }
  }, [props.provider, refreshNetworkCheck]);

  //get pool options from db
  useEffect(() => {
    async function getRefs() {
      const poolOptionsRef = ref(props.db, "vecrv/gauges/");
      SetPoolOptionsRef(poolOptionsRef);
    }
    async function getInfoFromDatabase() {
      if (props.db) {
        try {
          onValue(poolOptionsRef, (snapshot) => {
            let data = snapshot.val();
            if (data !== null && data !== undefined) {
              setPoolOptions(data);
            } else {
              console.log("Couldn't get pool options from db");
            }
          });
        } catch {
          setRefreshDB(refreshDB + 1);
          console.log("Couldn't get info from database");
        }
      }
    }
    try {
      if (refreshDB < 30) {
        getRefs();
        getInfoFromDatabase();
      }
    } catch {
      console.log("Couldn't run getRefs/getInfoFromDatabase");
    }
  }, [props.db, refreshDB]);

  //push only ethereum tokens to reward selection options
  // useEffect(() => {
  //   if (props.provider) {
  //     if (props.provider._network) {
  //       let newTokenOptions = [];
  //       currTokenOptions.map((token) => {
  //         if (token.chainId === props.provider._network.chainId) {
  //           newTokenOptions.push(token);
  //         }
  //       });
  //       setCurrTokenOptions(newTokenOptions);
  //     }
  //   }
  // }, [props.provider]);

  let poolChoices = ["3crv", "frax", "steth"];

  //get pool choices array from ref for select
  useEffect(() => {
    if (poolOptions != undefined) {
      let res = [];
      for (const [value, label] of Object.entries(poolOptions)) {
        let choice = { value: value, label: label };
        res.push(choice);
      }
      setPoolOptionsArray(res);
    }
  }, [poolOptions]);

  async function approve() {
    setApproveText("Approving...");
    setApproveDisabled(true);
    let signer = await props.provider.getSigner();
    const erc20ContractWithSigner = new ethers.Contract(
      selectedToken.value,
      erc20ABI,
      signer
    );
    try {
      const tx = await erc20ContractWithSigner.approve(
        currBribeAddress,
        maxNum
      );
      await tx.wait();
      setIsApproved(true);
    } catch {
      setApproveDisabled(false);
      setApproveText("Approve Token");
    }
  }

  //check if approved
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    var tokenNetwork = false;

    if (props.provider) {
      if (props.provider._network) {
        for (var toks in currTokenOptions) {
          if (
            currTokenOptions[toks].chainId.toString() ===
              props.provider._network.chainId.toString() &&
            currTokenOptions[toks].value === selectedToken.value
          ) {
            tokenNetwork = true;
          }
        }
      }
    }

    if (props.provider && tokenNetwork) {
      const erc20Contract = new ethers.Contract(
        selectedToken.value,
        erc20ABI,
        props.provider
      );
      let allowance = await erc20Contract.allowance(
        props.address,
        currBribeAddress
      );
      if (allowance > 0) {
        setIsApproved(true);
      } else {
        setIsApproved(false);
        setApproveDisabled(false);
        setApproveText("Approve Token");
      }
    }
  }, [props.provider, selectedToken]);

  async function depositReward() {
    setdepositRewardText("Depositing...");
    setPlaceBidDisabled(true);
    let bribeContractWithProvider = new ethers.Contract(
      currBribeAddress,
      veCRVABI,
      props.provider
    );
    let currFeeAddress = await bribeContractWithProvider.feeAddress();
    let signer = await props.provider.getSigner();
    let bribeContractWithSigner = new ethers.Contract(
      currBribeAddress,
      veCRVABI,
      signer
    );
    console.log(selectedToken.value);
    console.log(ethers.utils.parseEther(bidAmount.toString()));
    console.log(
      Math.floor(Date.now() / 1000 / (60 * 60 * 24 * 7)) +
        props.currentActiveAuction
    );
    console.log(poolIndex.value);
    console.log(currBribeAddress);
    console.log(currFeeAddress);
    // try {
    if (
      (currBribeAddress.toUpperCase() === veCRVAddress.toUpperCase() &&
        currFeeAddress.toUpperCase() === feeAddressEthereum.toUpperCase()) ||
      (currBribeAddress.toUpperCase() === veCRVAddressOptimism.toUpperCase() &&
        currFeeAddress.toUpperCase() === feeAddressOptimism.toUpperCase())
    ) {
      if (selectedToken.decimals !== 18) {
        const tx = await bribeContractWithSigner.depositReward(
          selectedToken.value,
          10 ** selectedToken.decimals * bidAmount.toString(),
          Math.floor(Date.now() / 1000 / (60 * 60 * 24 * 7)) +
            props.currentActiveAuction, //week
          poolIndex.value
        );
        await tx.wait();
        window.location.reload();
      } else {
        const tx = await bribeContractWithSigner.depositReward(
          selectedToken.value,
          ethers.utils.parseEther(bidAmount.toString()),
          Math.floor(Date.now() / 1000 / (60 * 60 * 24 * 7)) +
            props.currentActiveAuction, //week
          poolIndex.value
        );

        await tx.wait();
        window.location.reload();
        // }
      }
    }
    // } catch {
    //   setPlaceBidDisabled(false);
    //   setdepositRewardText("Deposit Incentive");
    //   console.log("failed to deposit incentive");
    // }
  }

  const textFieldStyles = {
    // ...
    root: (base) => ({
      ...base,
      borderColor: "#fff", // Set the color of the outline to white (#fff)
    }),
    input: (base) => ({
      ...base,
      color: "#fff", // Set the color of the text in the TextField to white (#fff)
    }),
    notchedOutline: (base) => ({
      ...base,
      borderColor: "#fff", // Set the color of the outline to white (#fff)
    }),
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "rgb(20, 21, 23)",
      borderColor: "#222",
    }),
    option: (base) => ({
      ...base,
      backgroundColor: "#1f1f1f",
      color: "#fff",
    }),
    // Add these styles to change the color of the side scroll and arrows
    dropdownIndicator: (base) => ({
      ...base,
      color: "#fff",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: "#fff",
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "#1f1f1f",
    }),
    singleValue: (base) => ({
      ...base,
      color: "rgb(216 212 207)",
      fontWeight: "500",
    }),
    input: (base) => ({
      ...base,
      color: "rgb(216 212 207)",
    }),
    menuList: (base) => ({
      ...base,
      "&::-webkit-scrollbar": {
        width: "8px",
        backgroundColor: "#1f1f1f",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "5px",
        backgroundColor: "#555",
        "&:hover": {
          backgroundColor: "#888",
        },
      },
    }),
  };

  return (
    <>
      {props.currentActiveAuction !== 0 ? (
        <div className={classes.accordionContainer}>
          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              align="center"
            >
              <Typography className={classes.rewardTitle}>
                Deposit A New Incentive
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column">
                <Grid
                  container
                  direction="row"
                  className={classes.accordionRow}
                >
                  <Grid item xs={4} align="left">
                    <Typography style={{ color: "grey" }}>
                      Select Vote Choice:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} align="right">
                    <Select
                      maxMenuHeight="12em"
                      className={classes.selectIndex}
                      options={poolOptionsArray}
                      onChange={setPoolIndex}
                      label="Select Token To Bid With"
                      isSearchable
                      styles={customStyles}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  className={classes.accordionRow}
                >
                  <Grid item xs={4} align="left">
                    <Typography style={{ color: "grey" }}>
                      Select Reward Token:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} align="right">
                    <Select
                      maxMenuHeight="12em"
                      className={classes.select}
                      options={currTokenOptions}
                      onChange={setSelectedToken}
                      label="Select Token To Bid With"
                      isSearchable
                      styles={customStyles}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  className={classes.accordionRow}
                >
                  <Grid item xs={4} align="left">
                    <Typography style={{ color: "grey" }}>
                      Enter Rewards Amount:
                    </Typography>
                  </Grid>
                  <Grid item xs={8} align="right">
                    <TextField
                      className={classes.inputField}
                      label={"Total Rewards"}
                      variant="outlined"
                      type="number"
                      value={bidAmount}
                      onChange={(e) => {
                        setBidAmount(e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {selectedToken.img}
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        style: { color: "rgb(232, 230, 227)" }, // Set the color of the text input in the TextField to white (#fff)
                      }}
                      InputLabelProps={{
                        style: { color: "rgb(232, 230, 227)" }, // Set the color of the input label to white (#fff)
                      }}
                      FormHelperTextProps={{
                        style: { color: "rgb(232, 230, 227)" }, // Set the color of the helper text to white (#fff)
                      }}
                      SelectProps={{
                        MenuProps: {
                          classes: { paper: classes.menu },
                        },
                      }}
                      fullWidth
                      style={{ borderColor: "rgb(232, 230, 227)" }} // Set the color of the outline to white (#fff)
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.faqButton}
                    onClick={() => {
                      window.open("https://docs.votium.app/faq/disclaimers");
                    }}
                    variant="outlined"
                  >
                    Votium disclaimer - read before depositing
                  </Button>
                </Grid>

                <Grid item>
                  {isApproved ? (
                    <Button
                      classes={{
                        root: classes.submitButton,
                        disabled: classes.submitButtonDisabled,
                      }}
                      onClick={depositReward}
                      disabled={placeBidDisabled}
                    >
                      {depositRewardText}
                    </Button>
                  ) : (
                    <Button
                      classes={{
                        root: classes.approveButton,
                        disabled: classes.approveButtonDisabled,
                      }}
                      onClick={approve}
                      disabled={approveDisabled}
                    >
                      {approveText}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      ) : (
        <div className={classes.accordionContainer}>
          <Accordion className={classes.accordion} disabled>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              align="center"
            >
              <Typography className={classes.rewardTitle}>
                Depositing period for this gauge vote is over.
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction="column">
                <Grid
                  container
                  direction="row"
                  className={classes.accordionRow}
                >
                  <Grid item xs={4} align="left">
                    <Typography>Select Vote Choice:</Typography>
                  </Grid>
                  <Grid item xs={8} align="right">
                    <Select
                      maxMenuHeight="12em"
                      className={classes.selectIndex}
                      options={poolOptionsArray}
                      onChange={setPoolIndex}
                      label="Select Token To Bid With"
                      isSearchable
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  className={classes.accordionRow}
                >
                  <Grid item xs={4} align="left">
                    <Typography>Select Reward Token:</Typography>
                  </Grid>
                  <Grid item xs={8} align="right">
                    <Select
                      maxMenuHeight="12em"
                      className={classes.select}
                      options={currTokenOptions}
                      onChange={setSelectedToken}
                      label="Select Token To Bid With"
                      isSearchable
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  className={classes.accordionRow}
                >
                  <Grid item xs={4} align="left">
                    <Typography>Enter Rewards Amount:</Typography>
                  </Grid>
                  <Grid item xs={8} align="right">
                    <TextField
                      className={classes.inputField}
                      label={"Total Rewards"}
                      variant="outlined"
                      type="number"
                      value={bidAmount}
                      onChange={(e) => {
                        setBidAmount(e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {selectedToken.img}
                          </InputAdornment>
                        ),
                        // endAdornment: (
                        //   <Button
                        //     className={classes.maxButton}
                        //     onClick={handleMaxCRV}
                        //   >
                        //     Max
                        //   </Button>
                        // ),
                      }}
                    ></TextField>
                  </Grid>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.faqButton}
                    onClick={() => {
                      window.open("https://docs.votium.app/faq/disclaimers");
                    }}
                    variant="outlined"
                  >
                    Votium disclaimer - read before depositing
                  </Button>
                </Grid>

                <Grid item>
                  {isApproved ? (
                    <Button
                      classes={{
                        root: classes.submitButton,
                        disabled: classes.submitButtonDisabled,
                      }}
                      onClick={depositReward}
                      disabled={placeBidDisabled}
                    >
                      {depositRewardText}
                    </Button>
                  ) : (
                    <Button
                      classes={{
                        root: classes.approveButton,
                        disabled: classes.approveButtonDisabled,
                      }}
                      onClick={approve}
                      disabled={approveDisabled}
                    >
                      {approveText}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </>
  );
};

export default AuctionAccordion;
