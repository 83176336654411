import { makeStyles } from "@material-ui/core";
export const useStylesDark = makeStyles((theme) => ({
  logo: {
    height: "8em",
  },
  tabContainer: {
    marginLeft: "auto",
  },
  delegateButton: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    marginRight: "1em",
    marginLeft: "1em",
    marginTop: "-0.3em",
    background: "#141E27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    height: "3.7em",
  },
  delegateButtonDisabled: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    marginRight: "1em",
    marginLeft: "1em",
    marginTop: "-0.3em",
    background: "#141E27",
    borderRadius: "5px",
    color: "#BEBEBE !important",
    height: "3.7em",
  },
  stakedAmountButton: {},
  tvlText: {
    textAlign: "center",
    color: theme.palette.common.grey,
    fontWeight: "400",
    marginBottom: "-0.2em",
  },
  address: {
    padding: theme.spacing(6),
    textAlign: "right",
  },
  claimButton: {
    textDecoration: "none",
    fontSize: "0.9em",
    fontFamily: "Rubik",
    minWidth: "70px",
    color: theme.palette.common.grey,
    borderRadius: "5px",
    height: "3em",
    padding: "1em",
    background: "rgba(26, 38, 48, 0.2)",
  },
  modalClaimButton: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "70px",
    background: "#141E27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    height: "3em",
    padding: "1em",
    left: "35%",
    marginTop: "1.5em",
    marginBottom: "-0.1em",
  },
  modalClaimButtonDisabled: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "70px",
    background: "#cccecf",
    borderRadius: "5px",
    height: "3em",
    padding: "1em",
    left: "28%",
    marginTop: "1.5em",
    marginBottom: "-0.1em",
  },
  button: {
    ...theme.typography.estimate,
    borderRadius: "50px",
    marginLeft: "50px",
    marginRight: "25px",
  },
  auctionButton: {
    ...theme.typography.estimate,
    borderRadius: "50px",
    textAlign: "center",
    marginRight: "25px",
    alignItems: "center",
    fontFamily: "Roboto Mono",
  },
  logoContainer: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  menu: {
    backgroundColor: theme.palette.common.black,
    color: "white",
  },
  menuItem: {
    ...theme.typography.tab,
    opacity: 0.7,
    "&:hover": {
      opacity: 1,
    },
    left: "0.25em",
  },
  menuItemMore: {
    ...theme.typography.tab,
    opacity: 1,
    left: "2.5em",
  },
  drawerIconContainer: {
    color: "white",
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  drawerIcon: {
    height: "30px",
    width: "30px",
    marginRight: "2em",
  },
  drawer: {
    backgroundColor: theme.palette.common.lgrey,
  },
  drawerItem: {
    ...theme.typography.tab,
  },
  drawerItemAuction: {
    backgroundColor: "#b0d9b3",
  },
  drawerItemAuctionText: {
    fontFamily: "Roboto Mono",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  header: {
    zIndex: 0,
    height: "30em",
    position: "sticky",
    backgroundColor: "rgb(6 8 11)",
    marginTop: "-1em",
  },
  snapshotLogo: {
    maxWidth: "1.5em",
    marginBottom: "-0.5em",
  },
  stakedVotiLogo: {
    maxWidth: "1.5em",
    marginBottom: "-0.55em",
  },
  cvxcrvLogo: {
    maxWidth: "1em",
    marginBottom: "-0.18em",
    marginRight: "0.2em",
  },
  stakedCVXLogo: {
    maxWidth: "1.5em",
    marginBottom: "-0.4em",
    marginRight: "0.3em",
  },
  delegatedSpacesTitle: {
    textAlign: "center",
    color: theme.palette.common.grey,
    fontWeight: "400",
  },
  stakedVotiText: {
    textAlign: "center",
    color: theme.palette.common.grey,
    fontWeight: "400",
    marginBottom: "1em",
  },
  stakedVotiAmount: {
    textAlign: "center",
    color: "white",
    fontWeight: "400",
  },
  totalText: {
    textAlign: "center",
    color: "grey",
    fontWeight: "100",
    fontSize: "0.8em",
    marginBottom: "-0.5em",
    marginTop: "0.5em",
  },
  totalTextUndelegated: {
    textAlign: "center",
    color: "grey",
    fontWeight: "100",
    fontSize: "0.8em",
    marginBottom: "-10em",
    marginTop: "0.5em",
  },
  delegatedAmountText: {
    textAlign: "center",
    color: theme.palette.common.grey,
    fontWeight: "400",
    marginBottom: "0.8em",
    marginTop: "-0.5em",
  },
  pendingAmountText: {
    textAlign: "center",
    color: "white",
    fontWeight: "100",
    fontSize: "0.8em",
    marginBottom: "-0.5em",
    marginTop: "0.5em",
  },
  pendingAmount: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: "1em",
    marginBottom: "-0.5em",
    marginTop: "0.5em",
  },
  connectText: {
    textAlign: "center",
    color: "grey",
    fontWeight: "100",
    fontSize: "0.85em",
  },
  delegatedSpacesDiv: {
    marginLeft: "3em",
    position: "relative",
    width: "100%",
  },
  votBoxOutlined: {
    width: "10em",
    textAlign: "center",
  },
  headText: {
    position: "relative",
  },
  imgText: {
    marginTop: "-8.2em",
  },
  imgTextAmount: {
    marginTop: "-1em",
    marginBottom: "5em",
  },
  headImage: {
    width: "13em",
  },
  textInside: {
    marginBottom: "1.5em",
    marginLeft: "-0.2em",
  },
  stepper: {
    backgroundColor: "transparent",
    paddingTop: "3.5em",
    width: "100%",
  },
  infoIcon: {
    color: theme.palette.common.grey,
    marginLeft: "0.3em",
    marginBottom: "-0.2em",
    zIndex: 100,
  },
  linkIcon: {
    color: theme.palette.common.grey,
    marginLeft: "3em",
    marginBottom: "5em",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0em",
    },
  },
  claimContainer: {
    width: "50%",
    marginLeft: "-3em",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  clipboardText: {
    margin: "1em",
  },
  socialIcon: {
    background: "transparent",
    borderRadius: "5px",
    padding: "0.3em",
    color: "#BEBEBE",
  },
  bookIcon: {
    background: "transparent",
    borderRadius: "5px",
    padding: "0.3em",
    color: "#BEBEBE",
    marginTop: "0.05em",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-25em",
  },
  modalPaper: {
    backgroundColor: "#070A0E",
    borderRadius: "3px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: "45em",
    width: "20em",
    alignItems: "center",
  },
  modalTitle: {
    fontSize: "1em",
    fontWeight: "700",
    marginTop: "-1em",
  },
  cvxcrvClaim: {
    width: "4em",
  },
  noBottomBorder: {
    borderBottom: "none",
  },
  totalCredited: {
    fontWeight: "700",
    marginTop: "-1em",
  },
  tableSubtitle: {
    color: "grey",
    fontSize: "0.9em",
  },
  tableAmount: {
    fontSize: "0.9em",
  },
  stepperText: {
    color: "grey",
    fontSize: "0.9em",
    marginTop: "-1em",
  },
  airdropText: {
    color: "grey",
    marginTop: "0.5em",
    fontSize: "0.9em",
  },
  faqButton: {
    color: theme.palette.common.grey,
    fontSize: "0.7em",
    textTransform: "none",
    background: "#141e27",
    margin: "1em",
  },
  modalDelegateButton: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    margin: "1em",
    background: "#141e27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    height: "3.7em",
  },
  modalDelegateButtonDisabled: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    margin: "1em",
    background: "#cccecf",
    borderRadius: "5px",
    color: "white",
    height: "3.7em",
  },
  modalText: {
    textAlign: "center",
    margin: "1em",
    color: theme.palette.common.grey,
    fontWeight: "400",
  },
  curveButton: {
    maxWidth: "2.5em",
    marginLeft: "0.5em",
  },
  cvxButton: {
    maxWidth: "2.5em",
    marginLeft: "0.5em",
  },
  vlcvxLogo: {
    maxWidth: "2em",
    marginLeft: "0.5em",
    zIndex: 2,
  },
  prismaLogoSmall: {
    maxWidth: "2em",
    marginLeft: "-0.9em",
  },
  curveLogoSmall: {
    maxWidth: "1.7em",
    marginLeft: "-0.5em",
    zIndex: 1,
  },
  fraxLogoSmall: {
    maxWidth: "1.5em",
    marginLeft: "-0.5em",
  },
  switchButton: {
    marginBottom: "-3em",
    marginRight: "0.9em",
  },
  selectMenu: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    background: "#141e27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    marginTop: "0.5em",
    paddingLeft: "0.5em",
    "&:before": {
      borderBottom: "none",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "&:after": {
      borderBottom: "none",
    },
    "& svg": {
      color: theme.palette.common.grey,
    },
  },
  selectMenuItems: {
    textTransform: "none",
    fontSize: "0.9em",
    fontFamily: "Rubik",
    background: "#141e27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    marginTop: "0.5em",
    marginLeft: "-0.5em",
  },
  switchButtonText: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    //minWidth: "50px",
    //background: "rgba(26, 38, 48, 0.2)",
    background: "#141e27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    height: "3em",
    marginTop: "0.5em",
    paddingLeft: "0.5em",
  },
  switchButtonLink: {
    textDecoration: "none",
  },
  addressButton: {
    padding: theme.spacing(6),
    textAlign: "right",
  },
}));

export const useStylesLight = makeStyles((theme) => ({
  logo: {
    height: "8em",
  },
  tabContainer: {
    marginLeft: "auto",
  },
  delegateButton: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    marginRight: "1em",
    marginLeft: "1em",
    marginTop: "-0.3em",
    background: "#141E27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    height: "3.7em",
  },
  delegateButtonDisabled: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    marginRight: "1em",
    marginLeft: "1em",
    marginTop: "-0.3em",
    background: "#141E27",
    borderRadius: "5px",
    color: "#BEBEBE !important",
    height: "3.7em",
  },
  stakedAmountButton: {},
  tvlText: {
    textAlign: "center",
    color: theme.palette.common.grey,
    fontWeight: "400",
    marginBottom: "-0.2em",
  },
  address: {
    padding: theme.spacing(6),
    textAlign: "right",
  },
  claimButton: {
    textDecoration: "none",
    fontSize: "0.9em",
    fontFamily: "Rubik",
    minWidth: "70px",
    color: theme.palette.common.grey,
    borderRadius: "5px",
    height: "3em",
    padding: "1em",
    background: "rgba(26, 38, 48, 0.2)",
  },
  modalClaimButton: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "70px",
    background: "#141E27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    height: "3em",
    padding: "1em",
    left: "35%",
    marginTop: "1.5em",
    marginBottom: "-0.1em",
  },
  modalClaimButtonDisabled: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "70px",
    background: "#cccecf",
    borderRadius: "5px",
    height: "3em",
    padding: "1em",
    left: "28%",
    marginTop: "1.5em",
    marginBottom: "-0.1em",
  },
  button: {
    ...theme.typography.estimate,
    borderRadius: "50px",
    marginLeft: "50px",
    marginRight: "25px",
  },
  auctionButton: {
    ...theme.typography.estimate,
    borderRadius: "50px",
    textAlign: "center",
    marginRight: "25px",
    alignItems: "center",
    fontFamily: "Roboto Mono",
  },
  logoContainer: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  menu: {
    backgroundColor: theme.palette.common.black,
    color: "white",
  },
  menuItem: {
    ...theme.typography.tab,
    opacity: 0.7,
    "&:hover": {
      opacity: 1,
    },
    left: "0.25em",
  },
  menuItemMore: {
    ...theme.typography.tab,
    opacity: 1,
    left: "2.5em",
  },
  drawerIconContainer: {
    color: "white",
    marginLeft: "auto",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  drawerIcon: {
    height: "30px",
    width: "30px",
    marginRight: "2em",
  },
  drawer: {
    backgroundColor: theme.palette.common.lgrey,
  },
  drawerItem: {
    ...theme.typography.tab,
  },
  drawerItemAuction: {
    backgroundColor: "#b0d9b3",
  },
  drawerItemAuctionText: {
    fontFamily: "Roboto Mono",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  header: {
    zIndex: 0,
    height: "30em",
    position: "sticky",
    backgroundColor: "#070A0E",
    marginTop: "-1em",
  },
  snapshotLogo: {
    maxWidth: "1.5em",
    marginBottom: "-0.5em",
  },
  stakedVotiLogo: {
    maxWidth: "1.5em",
    marginBottom: "-0.55em",
  },
  cvxcrvLogo: {
    maxWidth: "1em",
    marginBottom: "-0.18em",
    marginRight: "0.2em",
  },
  stakedCVXLogo: {
    maxWidth: "1.5em",
    marginBottom: "-0.4em",
    marginRight: "0.3em",
  },
  delegatedSpacesTitle: {
    textAlign: "center",
    color: theme.palette.common.grey,
    fontWeight: "400",
  },
  stakedVotiText: {
    textAlign: "center",
    color: theme.palette.common.grey,
    fontWeight: "400",
    marginBottom: "1em",
  },
  stakedVotiAmount: {
    textAlign: "center",
    color: "white",
    fontWeight: "400",
  },
  totalText: {
    textAlign: "center",
    color: "grey",
    fontWeight: "100",
    fontSize: "0.8em",
    marginBottom: "-0.5em",
    marginTop: "0.5em",
  },
  totalTextUndelegated: {
    textAlign: "center",
    color: "grey",
    fontWeight: "100",
    fontSize: "0.8em",
    marginBottom: "-10em",
    marginTop: "0.5em",
  },
  delegatedAmountText: {
    textAlign: "center",
    color: theme.palette.common.grey,
    fontWeight: "400",
    marginBottom: "0.8em",
    marginTop: "-0.5em",
  },
  pendingAmountText: {
    textAlign: "center",
    color: "white",
    fontWeight: "100",
    fontSize: "0.8em",
    marginBottom: "-0.5em",
    marginTop: "0.5em",
  },
  pendingAmount: {
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
    fontSize: "1em",
    marginBottom: "-0.5em",
    marginTop: "0.5em",
  },
  connectText: {
    textAlign: "center",
    color: "grey",
    fontWeight: "100",
    fontSize: "0.85em",
  },
  delegatedSpacesDiv: {
    marginLeft: "3em",
    position: "relative",
    width: "100%",
  },
  votBoxOutlined: {
    width: "10em",
    textAlign: "center",
  },
  headText: {
    position: "relative",
  },
  imgText: {
    marginTop: "-8.2em",
  },
  imgTextAmount: {
    marginTop: "-1em",
    marginBottom: "5em",
  },
  headImage: {
    width: "13em",
  },
  textInside: {
    marginBottom: "1.5em",
    marginLeft: "-0.2em",
  },
  stepper: {
    backgroundColor: "transparent",
    paddingTop: "3.5em",
    width: "100%",
  },
  infoIcon: {
    color: theme.palette.common.grey,
    marginLeft: "0.3em",
    marginBottom: "-0.2em",
    zIndex: 100,
  },
  linkIcon: {
    color: theme.palette.common.grey,
    marginLeft: "3em",
    marginBottom: "5em",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0em",
    },
  },
  claimContainer: {
    width: "50%",
    marginLeft: "-3em",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  clipboardText: {
    margin: "1em",
  },
  socialIcon: {
    background: "transparent",
    borderRadius: "5px",
    padding: "0.3em",
    color: "#BEBEBE",
  },
  bookIcon: {
    background: "transparent",
    borderRadius: "5px",
    padding: "0.3em",
    color: "#BEBEBE",
    marginTop: "0.05em",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-25em",
  },
  modalPaper: {
    backgroundColor: "#070A0E",
    borderRadius: "3px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: "45em",
    width: "20em",
    alignItems: "center",
  },
  modalTitle: {
    fontSize: "1em",
    fontWeight: "700",
    marginTop: "-1em",
  },
  cvxcrvClaim: {
    width: "4em",
  },
  noBottomBorder: {
    borderBottom: "none",
  },
  totalCredited: {
    fontWeight: "700",
    marginTop: "-1em",
  },
  tableSubtitle: {
    color: "grey",
    fontSize: "0.9em",
  },
  tableAmount: {
    fontSize: "0.9em",
  },
  stepperText: {
    color: "grey",
    fontSize: "0.9em",
    marginTop: "-1em",
  },
  airdropText: {
    color: "grey",
    marginTop: "0.5em",
    fontSize: "0.9em",
  },
  faqButton: {
    color: theme.palette.common.grey,
    fontSize: "0.7em",
    textTransform: "none",
    background: "#141e27",
    margin: "1em",
  },
  modalDelegateButton: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    margin: "1em",
    background: "#141e27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    height: "3.7em",
  },
  modalDelegateButtonDisabled: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    margin: "1em",
    background: "#cccecf",
    borderRadius: "5px",
    color: "white",
    height: "3.7em",
  },
  modalText: {
    textAlign: "center",
    margin: "1em",
    color: theme.palette.common.grey,
    fontWeight: "400",
  },
  curveButton: {
    maxWidth: "2.5em",
    marginLeft: "0.5em",
  },
  cvxButton: {
    maxWidth: "2.5em",
    marginLeft: "0.5em",
  },
  vlcvxLogo: {
    maxWidth: "2em",
    marginLeft: "0.5em",
    zIndex: 2,
  },
  prismaLogoSmall: {
    maxWidth: "2em",
    marginLeft: "-0.9em",
  },
  curveLogoSmall: {
    maxWidth: "1.7em",
    marginLeft: "-0.5em",
    zIndex: 1,
  },
  fraxLogoSmall: {
    maxWidth: "1.5em",
    marginLeft: "-0.5em",
  },
  switchButton: {
    marginBottom: "-3em",
    marginRight: "0.9em",
  },
  selectMenu: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    background: "#141e27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    marginTop: "0.5em",
    paddingLeft: "0.5em",
    "&:before": {
      borderBottom: "none",
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    "&:after": {
      borderBottom: "none",
    },
    "& svg": {
      color: theme.palette.common.grey,
    },
  },
  selectMenuItems: {
    textTransform: "none",
    fontSize: "0.9em",
    fontFamily: "Rubik",
    background: "#141e27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    marginTop: "0.5em",
    marginLeft: "-0.5em",
  },
  switchButtonText: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    //minWidth: "50px",
    //background: "rgba(26, 38, 48, 0.2)",
    background: "#141e27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    height: "3em",
    marginTop: "0.5em",
    paddingLeft: "0.5em",
  },
  switchButtonLink: {
    textDecoration: "none",
  },
  addressButton: {
    padding: theme.spacing(6),
    textAlign: "right",
  },
}));
