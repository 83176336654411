import React, { useEffect, useState } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import votBox from "../../assets/votBoxBW.png";
import { useStylesDark, useStylesLight } from "../../Styles/FooterStyles";
import ethLogo from "../../assets/ethLogoInv.png";
import optimismLogo from "../../assets/optimismLogo.webp";
import { ethers } from "ethers";

const FooterCurve = (props) => {
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  const [classes, setClasses] = useState(classesDark);
  const [connectedNetwork, setConnectedNetwork] = useState(0);
  const [refreshAddressCheck, setRefreshAddressCheck] = useState(0);

  // theme setting 
  useEffect(() => {
    if (props.ctheme === 'light') {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [props.ctheme]);
  
  // check provider network
  useEffect(() => {
    if (props.provider !== undefined) {
      if (props.provider._network !== undefined) {
        setConnectedNetwork(props.provider._network.chainId);
      } else if (connectedNetwork > -100) {
        setTimeout(() => {
          setConnectedNetwork(connectedNetwork - 1);
        }, 100);
      }
    }
    if (refreshAddressCheck < 50) {
      if (props.provider !== undefined) {
        if (props.provider._network !== undefined) {
          console.log(props.provider._network.chainId);
        }
      }
      setRefreshAddressCheck(refreshAddressCheck + 1);
    }
  }, [props.provider, connectedNetwork, refreshAddressCheck]);

  async function chainSwitch(networkId) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: ethers.utils.hexValue(networkId) }],
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <footer className={classes.footer}>
      <div className={classes.footerMargin} />
      <Grid container justify="center" className={classes.nameContainer}>
        <Hidden smDown>
          <Grid item className={classes.gridItem}>
            <Grid container direction="column" spacing={2}></Grid>
          </Grid>
        </Hidden>
        <Grid item className={classes.gridItem}>
          <Grid container direction="column" spacing={2}>
            <Grid item className={classes.title}>
              Community
            </Grid>
            <Grid
              item
              className={classes.link}
              component={Link}
              onClick={() => {
                window.open("https://twitter.com/VotiumProtocol", "_blank");
              }}
            >
              Twitter
            </Grid>
            <Grid
              item
              className={classes.link}
              component={Link}
              onClick={() => {
                window.open("https://discord.gg/j6UnnVsH8t", "_blank");
              }}
            >
              Discord
            </Grid>
            <Grid
              item
              className={classes.link}
              component={Link}
              onClick={() => {
                window.open("https://votiumprotocol.medium.com/", "_blank");
              }}
            >
              Medium
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.gridItem}>
          <Grid container direction="column" spacing={2}>
            <Grid item className={classes.title}>
              Resources
            </Grid>
            <Grid
              item
              className={classes.link}
              component={Link}
              onClick={() => {
                window.open("http://docs.votium.app", "_blank");
              }}
            >
              Documentation
            </Grid>
            <Grid
              item
              className={classes.link}
              component={Link}
              onClick={() => {
                window.open("https://github.com/oo-00/Votium", "_blank");
              }}
            >
              Github
            </Grid>
            <Grid
              item
              className={classes.link}
              component={Link}
              onClick={() => {
                window.open("https://llama.airforce/#/votium/bribes", "_blank");
              }}
            >
              llama.airforce
            </Grid>
            <Grid item className={classes.link}></Grid>
          </Grid>
        </Grid>
        {props.provider ? (
          props.provider._network ? (
            <Grid item className={classes.switchNetwork}>
              {props.provider._network.chainId == 1 ? (
                <img
                  alt="Mainnet"
                  className={classes.activeNetwork}
                  src={ethLogo}
                />
              ) : (
                <img
                  alt="Mainnet"
                  className={classes.networkButton}
                  src={ethLogo}
                  onClick={() => {
                    chainSwitch(1);
                  }}
                />
              )}
              {props.provider._network.chainId == 10 ? (
                <img
                  alt="Optimism"
                  className={classes.activeNetwork}
                  src={optimismLogo}
                />
              ) : (
                <img
                  alt="Optimism"
                  className={classes.networkButton}
                  src={optimismLogo}
                  onClick={() => {
                    chainSwitch(10);
                  }}
                />
              )}
            </Grid>
          ) : (
            <Grid item className={classes.switchNetwork}></Grid>
          )
        ) : (
          <Grid item className={classes.switchNetwork}></Grid>
        )}
      </Grid>
      <Hidden smDown>
        <img alt="votium logo" src={votBox} className={classes.footerLogo} />
      </Hidden>
    </footer>
  );
};

export default FooterCurve;
