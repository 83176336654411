import React, { useEffect, useState } from "react";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { Paper, makeStyles, useMediaQuery } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { useUserAddress } from "eth-hooks";
import { ethers } from "ethers";
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import ls from "local-storage";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AuctionPage from "./Pages/Auction/AuctionPage";
import AuctionPageCurve from "./Pages/Auction/Curve/AuctionPageCurve";
import ClaimPage from "./Pages/Claim/ClaimPage";
import ClaimPageCurve from "./Pages/Claim/Curve/ClaimPageCurve";
import toggleDark from "./assets/toggleDark.png";
import votBox40 from "./assets/votBox40.png";
import votBox56 from "./assets/votBox56.png";
import Footer from "./components/Footer/Footer";
import FooterCurve from "./components/Footer/FooterCurve";
import Header from "./components/Header/Header";
import HeaderCurve from "./components/Header/HeaderCurve";
import MobileHeader from "./components/Header/MobileHeader";
import MobileHeaderCurve from "./components/Header/MobileHeaderCurve";
import theme from "./components/Theme";
import {
  NETWORKS,
  auctionABI,
  auctionAddress,
  crvABI,
  crvAddress,
} from "./constants";

//web3onboard:
import coinbaseWalletModule from "@web3-onboard/coinbase";
import gnosisModule from "@web3-onboard/gnosis";
import injectedModule from "@web3-onboard/injected-wallets/";
import { init, useConnectWallet } from "@web3-onboard/react";
import walletConnectModule from "@web3-onboard/walletconnect";
import frameModule from "@web3-onboard/frame";

const apiKey = "f8c1cda2-a22e-450a-b5a4-796aad6013ae";
const injected = injectedModule();
const rpcUrl = NETWORKS.mainnet.rpcUrl;
const walletConnect = walletConnectModule({
  projectId: "1a07a069e30a07b437bcef114b700cfb", // ***New Param* Project ID associated with [WalletConnect account](https://cloud.walletconnect.com)
  dappUrl: "https://votium.app",
});
const gnosis = gnosisModule();
const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: true });
const frame = frameModule();

const appMetadata = {
  name: "Votium",
  icon: votBox40,
  logo: votBox56,
  description: "My app using Onboard",
  recommendedInjectedWallets: [
    { name: "Coinbase", url: "https://wallet.coinbase.com/" },
    { name: "MetaMask", url: "https://metamask.io" },
  ],
};

// initialize Onboard
init({
  apiKey,
  connect: {
    autoConnectLastWallet: true,
  },
  theme: "dark",
  wallets: [injected, walletConnect, gnosis, coinbaseWalletSdk, frame],
  chains: [
    {
      id: "0x1",
      token: "ETH",
      label: "Ethereum Mainnet",
      rpcUrl,
    },
  ],
  appMetadata,
});

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB5m79DPiFl6lfBzEAIi4Es5frVjNdhA70",
  authDomain: "test-54f45.firebaseapp.com",
  databaseURL: "https://test-54f45-default-rtdb.firebaseio.com",
  projectId: "test-54f45",
  storageBucket: "test-54f45.appspot.com",
  messagingSenderId: "1041511688078",
  appId: "1:1041511688078:web:ac1873792d9f48463226df",
  measurementId: "G-WFSV46SNJF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

const useStylesDark = makeStyles({
  background: {
    backgroundColor: "rgb(19, 20, 22)",
    backgroundRepeat: "repeat",
    height: "100%",
  },
  "@global": {
    body: {
      backgroundColor: "#3d4244",
    },
  },
  toggleDarkButton: {
    backgroundColor: "#888",
    borderRadius: "10px",
    position: "absolute",
    top: "2px",
    right: "2px",
    zIndex: "999",
    width: "30px",
    height: "30px",
    "&:hover": {
      backgroundColor: "#eee",
      cursor: "pointer",
    },
  },
});

const useStylesLight = makeStyles({
  background: {
    backgroundColor: "#F9FAFB",
    backgroundRepeat: "repeat",
    height: "100%",
  },
  "@global": {
    body: {
      backgroundColor: "#3d4244",
    },
  },
  toggleDarkButton: {
    backgroundColor: "#888",
    borderRadius: "10px",
    position: "absolute",
    top: "2px",
    right: "2px",
    zIndex: "999",
    width: "30px",
    height: "30px",
    "&:hover": {
      backgroundColor: "#eee",
      cursor: "pointer",
    },
  },
});

const scrollbarStyles = `
  ::-webkit-scrollbar {
    width: 12px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #1a1a1a;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #444;
    border-radius: 6px;
  }
`;

function App() {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  let provider;
  if (wallet) {
    provider = new ethers.providers.Web3Provider(wallet.provider, "any");
    // if using ethers v6 this is:
    // provider = new ethers.BrowserProvider(wallet.provider, 'any')
  }

  //mobile:
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  if (ls.get("Mode") === null) {
    ls.set("Mode", "dark");
  }
  const [classes, setClasses] = useState(classesDark);
  const [ctheme, setTheme] = useState(ls.get("Mode"));
  const address = useUserAddress(provider);

  const weenusContract = new ethers.Contract(crvAddress, crvABI, provider);

  const auctionContract = new ethers.Contract(
    auctionAddress,
    auctionABI,
    provider
  );

  const toggleTheme = () => {
    if (ctheme === "light") {
      console.log("dark");
      ls.set("Mode", "dark");
      setTheme("dark");
    } else {
      console.log("light");
      ls.set("Mode", "light");
      setTheme("light");
    }
  };

  useEffect(() => {
    if (ctheme === "light") {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [ctheme]);

  return (
    <ThemeProvider theme={theme}>
      <style>{scrollbarStyles}</style>
      <Paper className={classes.background} elevation={0}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Switch>
            <Route
              exact
              path="/claim"
              component={() => (
                <div>
                  <img
                    className={classes.toggleDarkButton}
                    src={toggleDark}
                    onClick={toggleTheme}
                  />
                  {mobile ? (
                    <MobileHeader
                      provider={provider}
                      connect={connect}
                      disconnect={disconnect}
                      wallet={wallet}
                      address={address}
                      contract={weenusContract}
                      db={db}
                      ctheme={ctheme}
                    />
                  ) : (
                    <Header
                      provider={provider}
                      connect={connect}
                      disconnect={disconnect}
                      wallet={wallet}
                      address={address}
                      contract={weenusContract}
                      db={db}
                      ctheme={ctheme}
                    />
                  )}
                  <ClaimPage
                    provider={provider}
                    address={address}
                    contract={weenusContract}
                    db={db}
                    ctheme={ctheme}
                  />
                  <Footer
                    provider={provider}
                    address={address}
                    ctheme={ctheme}
                  />
                </div>
              )}
            />
            <Route
              path="/curve/claim"
              component={() => (
                <div>
                  <img
                    className={classes.toggleDarkButton}
                    src={toggleDark}
                    onClick={toggleTheme}
                  />
                  {mobile ? (
                    <MobileHeaderCurve
                      provider={provider}
                      connect={connect}
                      disconnect={disconnect}
                      wallet={wallet}
                      address={address}
                      contract={weenusContract}
                      db={db}
                      ctheme={ctheme}
                    />
                  ) : (
                    <HeaderCurve
                      provider={provider}
                      connect={connect}
                      disconnect={disconnect}
                      wallet={wallet}
                      address={address}
                      contract={weenusContract}
                      db={db}
                      ctheme={ctheme}
                    />
                  )}

                  <ClaimPageCurve
                    provider={provider}
                    address={address}
                    contract={weenusContract}
                    db={db}
                    ctheme={ctheme}
                  />
                  <FooterCurve
                    provider={provider}
                    connect={connect}
                    disconnect={disconnect}
                    wallet={wallet}
                    address={address}
                    ctheme={ctheme}
                  />
                </div>
              )}
            />
            <Route
              path="/curve"
              component={() => (
                <div>
                  <img
                    className={classes.toggleDarkButton}
                    src={toggleDark}
                    onClick={toggleTheme}
                  />
                  {mobile ? (
                    <MobileHeaderCurve
                      provider={provider}
                      connect={connect}
                      disconnect={disconnect}
                      wallet={wallet}
                      address={address}
                      contract={weenusContract}
                      db={db}
                      ctheme={ctheme}
                    />
                  ) : (
                    <HeaderCurve
                      provider={provider}
                      connect={connect}
                      disconnect={disconnect}
                      wallet={wallet}
                      address={address}
                      contract={weenusContract}
                      db={db}
                      ctheme={ctheme}
                    />
                  )}
                  <AuctionPageCurve
                    provider={provider}
                    contract={auctionContract}
                    stakingContract={weenusContract}
                    address={address}
                    db={db}
                    ctheme={ctheme}
                  />
                  <FooterCurve
                    provider={provider}
                    connect={connect}
                    disconnect={disconnect}
                    wallet={wallet}
                    address={address}
                    ctheme={ctheme}
                  />
                </div>
              )}
            />
            <Route
              path="/"
              component={() => (
                <div>
                  <img
                    className={classes.toggleDarkButton}
                    src={toggleDark}
                    onClick={toggleTheme}
                  />
                  {mobile ? (
                    <MobileHeader
                      provider={provider}
                      connect={connect}
                      disconnect={disconnect}
                      wallet={wallet}
                      address={address}
                      contract={weenusContract}
                      db={db}
                      ctheme={ctheme}
                    />
                  ) : (
                    <Header
                      provider={provider}
                      connect={connect}
                      disconnect={disconnect}
                      wallet={wallet}
                      address={address}
                      contract={weenusContract}
                      db={db}
                      ctheme={ctheme}
                    />
                  )}
                  <AuctionPage
                    provider={provider}
                    contract={auctionContract}
                    stakingContract={weenusContract}
                    address={address}
                    db={db}
                    ctheme={ctheme}
                  />
                  <Footer
                    ctheme={ctheme}
                    provider={provider}
                    address={address}
                  />
                </div>
              )}
            />
          </Switch>
        </BrowserRouter>
      </Paper>
      <script src="/__/firebase/8.7.1/firebase-app.js"></script>

      <script src="/__/firebase/8.7.1/firebase-analytics.js"></script>

      <script src="/__/firebase/init.js"></script>
    </ThemeProvider>
  );
}

export default App;
