import { makeStyles } from "@material-ui/core";
export const useStylesDark = makeStyles((theme) => ({
  footer: {
    backgroundColor: "rgb(61 66 68)",
    width: "100%",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      height: "14em",
    },
    borderBottom: "none",
    boxShadow: "none", // Add this line
    border: 0, // Add this line
    margin: 0, // Add this line
  },
  footerLogo: {
    width: "14em",
    verticalAlign: "bottom",
    marginLeft: "5em",
    marginBottom: "1em",
    marginTop: "1em",
    [theme.breakpoints.down("md")]: {
      marginTop: "0.5em",
      width: "13em",
    },
  },
  nameContainer: {
    position: "absolute",
  },
  title: {
    fontFamily: "Roboto Mono",
    fontSize: "1rem",
    fontWeight: "bold",
    textDecoration: "none",
    color: "black",
  },
  link: {
    fontFamily: "Roboto Mono",
    fontSize: "0.75rem",
    fontWeight: "bold",
    textDecoration: "none",
    color: "black",
  },
  gridItem: {
    margin: "3em",
  },
  footerMargin: {
    marginTop: "5em",
  },
  switchNetwork: {
    position: "fixed",
    bottom: "2.5em",
    right: "2.5em",
    zIndex: 9999,
  },
  networkButton: {
    maxWidth: "2.1em",
    maxHeight: "2.1em",
    cursor: "pointer",
    padding: ".5em",
    "&:hover": {
      maxWidth: "3.1em",
      maxHeight: "3.1em",
      width: "3.1em",
      height: "3.1em",
      padding: "0em",
    },
  },
  activeNetwork: {
    maxWidth: "2.9em",
    maxHeight: "2.9em",
    padding: ".1em",
  },
}));

export const useStylesLight = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.common.grey,
    width: "100%",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      height: "14em",
    },
    borderBottom: "none",
    boxShadow: "none", // Add this line
    border: 0, // Add this line
    margin: 0, // Add this line
  },
  footerLogo: {
    width: "14em",
    verticalAlign: "bottom",
    marginLeft: "5em",
    marginBottom: "1em",
    marginTop: "1em",
    [theme.breakpoints.down("md")]: {
      marginTop: "0.5em",
      width: "13em",
    },
  },
  nameContainer: {
    position: "absolute",
  },
  title: {
    fontFamily: "Roboto Mono",
    fontSize: "1rem",
    fontWeight: "bold",
    textDecoration: "none",
    color: "black",
  },
  link: {
    fontFamily: "Roboto Mono",
    fontSize: "0.75rem",
    fontWeight: "bold",
    textDecoration: "none",
    color: "black",
  },
  gridItem: {
    margin: "3em",
  },
  footerMargin: {
    marginTop: "5em",
  },
  switchNetwork: {
    position: "fixed",
    bottom: "2.5em",
    right: "2.5em",
    zIndex: 9999,
  },
  networkButton: {
    maxWidth: "2.1em",
    maxHeight: "2.1em",
    cursor: "pointer",
    padding: ".5em",
    "&:hover": {
      maxWidth: "3.1em",
      maxHeight: "3.1em",
      width: "3.1em",
      height: "3.1em",
      padding: "0em",
    },
  },
  activeNetwork: {
    maxWidth: "2.9em",
    maxHeight: "2.9em",
    padding: ".1em",
  },
}));
