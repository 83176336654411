import { makeStyles } from "@material-ui/core";
export const useStylesDark = makeStyles((theme) => ({
  cvxcrvLogo: {
    width: "2em",
    marginBottom: "-0.2em",
    marginRight: "-0.2em",
  },
  bottomCell: {
    borderBottom: "none",
  },
  cvxLogo: {
    width: "2.8em",
    marginBottom: "-0.4em",
    marginRight: "-0.6em",
  },
  timeIcon: {
    marginBottom: "-0.2em",
    width: "1.5em",
  },
  auctionCard: {
    margin: "1em",
    width: "25em",
    paddingTop: "0.6em",
    backgroundColor: "#fcfcfc",
    [theme.breakpoints.down("sm")]: {
      width: "22em",
      //height: "20em",
    },
    [theme.breakpoints.up("sm")]: {
      width: "23em",
      height: "19em",
    },
  },
  auctionCardCurve: {
    margin: "1em",
    width: "25em",
    paddingTop: "0.6em",
    backgroundColor: "rgb(34 36 38)",
    [theme.breakpoints.down("sm")]: {
      width: "22em",
      //height: "20em",
    },
    [theme.breakpoints.up("sm")]: {
      width: "23em",
      height: "30.7em",
    },
  },
  table: {},
  tableText: {
    color: "grey",
  },
  tableTextBottom: {
    color: "grey",
    borderBottom: "none",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#F9FAFB",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    width: "50%",
    height: "80%",
  },
  modalAuctionName: {
    fontSize: "4em",
    fontFamily: "Rubik",
    fontWeight: "400",
    textAlign: "center",
  },
  modalTimeRemaining: {
    fontSize: "3em",
    fontFamily: "Rubik",
    fontWeight: "400",
    textAlign: "center",
  },
  modalHighestBid: {
    fontSize: "3em",
    fontFamily: "Rubik",
    fontWeight: "400",
    textAlign: "center",
  },
  inputField: {
    width: "70%",
  },
  inputLogo: {
    width: "2em",
  },
  modalContainer: {
    width: "50%",
  },
  submitButton: {
    left: "44%",
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "#141E27",
    color: theme.palette.common.lgrey,
  },
  cardTitle: {
    fontSize: "1em",
    margin: "0.5em",
    marginTop: "-2.5em",
    color: "rgb(206 205 203)",
  },
  cardSubtitle: {
    fontSize: "0.9em",
    marginTop: "-1em",
    color: "rgb(206 205 203)",
  },
  gaugeAddressSubtitle: {
    fontSize: "0.8em",
    color: "rgb(206 205 203)",
  },
  caption: {
    textTransform: "none",
    textAlign: "center",
    justifyContent: "center",
    marginLeft: "7.3em",
  },
  button: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "0.9em",
    backgroundColor: "#141E27",
    color: "rgb(206 205 203)",
    margin: "1em",
    borderRadius: "5px",
  },
  disabledButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "0.9em",
    background: "transparent",
    borderRadius: "5px",
    color: "grey",
    margin: "1em",
  },
  bribeText: {
    width: "90%",
    textAlign: "center",
    marginTop: "1em",
    fontSize: "0.9em",
  },
  votBox: {
    marginTop: "0.5em",
    width: "8em",
  },
  tokenImg: {
    margin: "0.5em",
    marginLeft: "1em",
  },
}));

export const useStylesLight = makeStyles((theme) => ({
  cvxcrvLogo: {
    width: "2em",
    marginBottom: "-0.2em",
    marginRight: "-0.2em",
  },
  bottomCell: {
    borderBottom: "none",
  },
  cvxLogo: {
    width: "2.8em",
    marginBottom: "-0.4em",
    marginRight: "-0.6em",
  },
  timeIcon: {
    marginBottom: "-0.2em",
    width: "1.5em",
  },
  auctionCard: {
    margin: "1em",
    width: "25em",
    paddingTop: "0.6em",
    backgroundColor: "#fcfcfc",
    [theme.breakpoints.down("sm")]: {
      width: "22em",
      //height: "20em",
    },
    [theme.breakpoints.up("sm")]: {
      width: "23em",
      height: "19em",
    },
  },
  auctionCardCurve: {
    margin: "1em",
    width: "25em",
    paddingTop: "0.6em",
    backgroundColor: "rgb(34 36 38)",
    [theme.breakpoints.down("sm")]: {
      width: "22em",
      //height: "20em",
    },
    [theme.breakpoints.up("sm")]: {
      width: "23em",
      height: "30.7em",
    },
  },
  table: {},
  tableText: {
    color: "grey",
  },
  tableTextBottom: {
    color: "grey",
    borderBottom: "none",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#F9FAFB",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    width: "50%",
    height: "80%",
  },
  modalAuctionName: {
    fontSize: "4em",
    fontFamily: "Rubik",
    fontWeight: "400",
    textAlign: "center",
  },
  modalTimeRemaining: {
    fontSize: "3em",
    fontFamily: "Rubik",
    fontWeight: "400",
    textAlign: "center",
  },
  modalHighestBid: {
    fontSize: "3em",
    fontFamily: "Rubik",
    fontWeight: "400",
    textAlign: "center",
  },
  inputField: {
    width: "70%",
  },
  inputLogo: {
    width: "2em",
  },
  modalContainer: {
    width: "50%",
  },
  submitButton: {
    left: "44%",
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "#141E27",
    color: theme.palette.common.lgrey,
  },
  cardTitle: {
    fontSize: "1em",
    margin: "0.5em",
    marginTop: "-2.5em",
    color: "rgb(206 205 203)",
  },
  cardSubtitle: {
    fontSize: "0.9em",
    marginTop: "-1em",
    color: "rgb(206 205 203)",
  },
  gaugeAddressSubtitle: {
    fontSize: "0.8em",
    color: "rgb(206 205 203)",
  },
  caption: {
    textTransform: "none",
    textAlign: "center",
    justifyContent: "center",
    marginLeft: "7.3em",
  },
  button: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "0.9em",
    backgroundColor: "#141E27",
    color: "rgb(206 205 203)",
    margin: "1em",
    borderRadius: "5px",
  },
  disabledButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "0.9em",
    background: "transparent",
    borderRadius: "5px",
    color: "grey",
    margin: "1em",
  },
  bribeText: {
    width: "90%",
    textAlign: "center",
    marginTop: "1em",
    fontSize: "0.9em",
  },
  votBox: {
    marginTop: "0.5em",
    width: "8em",
  },
  tokenImg: {
    margin: "0.5em",
    marginLeft: "1em",
  },
}));
