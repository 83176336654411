import { makeStyles } from "@material-ui/core";

export const useStylesDark = makeStyles((theme) => ({
  wrapper: {
    justifyContent: "center",
  },
  cardDiv: {
    marginBottom: "2.5em",
    zIndex: "1",
  },
  paper: {
    width: "15%",
    margin: "2em",
    backgroundColor: "#141E27",
    [theme.breakpoints.down("md")]: {
      width: "22%",
    },
  },
  text: {
    marginTop: "0.3em",
    textAlign: "center",
    fontFamily: "Rubik",
    color: theme.palette.common.grey,
  },
  logoContainer: {},
  cvxLogo: {
    width: "3em",
    margin: "0.3em",
  },
  auctionsContainer: {
    textAlign: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  auctionCard: {
    margin: "1em",
    width: "20em",
    height: "10em",
  },
  noActiveAuctionCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "1em",
    width: "45em",
    [theme.breakpoints.down("xs")]: {
      width: "21em",
    },
    zIndex: 1,
    paddingTop: "1em",
    marginTop: "-2.5em",
    paddingBottom: "1em",
    background: "rgb(34 36 38)",
  },
  noActiveAuctionTitle: {
    fontSize: "1.5em",
    marginTop: "0.2em",
  },
  noActiveAuctionText: {
    fontSize: "1em",
    marginTop: "0.8em",
    color: "grey",
  },
  timeText: {
    fontSize: "1.3em",
    marginTop: "1.05em",
    color: "grey",
    textAlign: "center",
  },
  tab: {
    margin: "auto",
    display: "block",
    textAlign: "center",
  },
  uniSpace: {
    maxWidth: "15%",
    marginBottom: "-0.4em",
  },
  cvxSpace: {
    maxWidth: "15%",
  },
  crvSpace: {
    maxWidth: "8%",
    marginTop: "0.5em",
    marginBottom: "0.6em",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  arrowButton: {
    color: "white",
  },
  votBox: {
    width: "3em",
    marginTop: "1em",
  },
  accordion: {
    width: "40em",
  },
  activeContainer: {
    alignItems: "center",
  },
  arrowsContainer: {
    marginBottom: "4em",
    marginTop: "-6em",
  },
  votTokenInput: {
    width: "2em",
  },
  submitButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "#141E27",
    color: "rgb(232, 230, 227)",
  },
  submitButtonDisabled: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "rgb(29, 31, 32)",
    color: "white",
  },
  tableTextBottom: {
    borderBottom: "none",
  },
  subTitle: {
    color: "grey",
  },
  tokenImg: { marginLeft: "1em", width: "30px" },
  tokenText: {
    marginTop: "0.16em",
    marginLeft: "0.4em",
    color: "rgb(222, 220, 217)",
  },
  usdText: {
    color: "grey",
    fontSize: "0.8em",
  },
  earnedText: {
    color: "white",
    fontSize: "0.8em",
  },
  checkbox: {
    color: "rgb(79, 81, 82)",
  },
  cardContainer: {
    alignItems: "center",
  },
}));

export const useStylesLight = makeStyles((theme) => ({
  wrapper: {
    justifyContent: "center",
  },
  cardDiv: {
    marginBottom: "2.5em",
    zIndex: "1",
  },
  paper: {
    width: "15%",
    margin: "2em",
    backgroundColor: "#141E27",
    [theme.breakpoints.down("md")]: {
      width: "22%",
    },
  },
  text: {
    marginTop: "0.3em",
    textAlign: "center",
    fontFamily: "Rubik",
    color: "#000",
  },
  logoContainer: {},
  cvxLogo: {
    width: "3em",
    margin: "0.3em",
  },
  auctionsContainer: {
    textAlign: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  auctionCard: {
    margin: "1em",
    width: "20em",
    height: "10em",
  },
  noActiveAuctionCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "1em",
    width: "45em",
    [theme.breakpoints.down("xs")]: {
      width: "21em",
    },
    zIndex: 1,
    paddingTop: "1em",
    marginTop: "-2.5em",
    paddingBottom: "1em",
    background: "linear-gradient(20deg, #FDFCFB 30%, #D7D7D8 90%)",
  },
  noActiveAuctionTitle: {
    fontSize: "1.5em",
    marginTop: "0.2em",
  },
  noActiveAuctionText: {
    fontSize: "1em",
    marginTop: "0.8em",
    color: "grey",
  },
  timeText: {
    fontSize: "1.3em",
    marginTop: "1.05em",
    color: "grey",
    textAlign: "center",
  },
  tab: {
    margin: "auto",
    display: "block",
    textAlign: "center",
  },
  uniSpace: {
    maxWidth: "15%",
    marginBottom: "-0.4em",
  },
  cvxSpace: {
    maxWidth: "15%",
  },
  crvSpace: {
    maxWidth: "8%",
    marginTop: "0.5em",
    marginBottom: "0.6em",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  arrowButton: {
    color: "black",
  },
  votBox: {
    width: "3em",
    marginTop: "1em",
  },
  accordion: {
    width: "40em",
  },
  activeContainer: {
    alignItems: "center",
  },
  arrowsContainer: {
    marginBottom: "4em",
    marginTop: "-6em",
  },
  votTokenInput: {
    width: "2em",
  },
  submitButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "#141E27",
    color: theme.palette.common.lgrey,
  },
  submitButtonDisabled: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: theme.palette.common.lgrey,
    color: "black",
  },
  tableTextBottom: {
    borderBottom: "none",
  },
  subTitle: {
    color: "grey",
  },
  tokenImg: { marginLeft: "1em", width: "30px" },
  tokenText: {
    marginTop: "0.16em",
    marginLeft: "0.4em",
  },
  usdText: {
    color: "grey",
    fontSize: "0.8em",
  },
  earnedText: {
    color: "black",
  },
  checkbox: {
    color: "#070A0E",
  },
  cardContainer: {
    alignItems: "center",
  },
}));
