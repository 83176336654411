/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Typography, Grid, Paper, Button } from "@material-ui/core";
import votBox from "../../assets/votBox.png";
import ActiveAuctionCard from "./Components/ActiveAuctionCard";
import UserIncentivesAccordion from "./Components/UserIncentivesAccordion";
import DepositForm from "./Components/DepositForm";
import Bribes from "./Components/Bribes";
import { useStylesDark, useStylesLight } from "../../Styles/AuctionPageStyles";
import { set } from "lodash";

const AuctionPage = (props) => {
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  var [classes, setClasses] = useState(classesDark);
  const [noActiveAuction, setNoActiveAuction] = useState(false);
  const [nextDate, setNextDate] = useState("Loading...");
  const [toggleText, setToggleText] = useState("Deposit a New Incentive");
  const [isAuctionOver, setIsAuctionOver] = useState(false);
  const [displayWarning, setDisplayWarning] = useState(false);

  const handleAuctionOver = (isOver) => {
    setIsAuctionOver(isOver);
  };

  let round = Math.floor(Math.floor(Date.now() / 1000) / (86400 * 14)) - 1348;
  const location = window.location.href;
  const suffix = location.split("/").pop();

  if (suffix === "prisma") {
    round = round - 57;
  }
  if (suffix === "fxn") {
    round = round - 65;
  }

  useEffect(() => {
    let currRound = round;
    if (suffix === "prisma") {
      currRound = currRound + 57;
    }
    if (suffix === "fxn") {
      currRound = currRound + 65;
    }
    if (
      (currRound + 1348) * 86400 * 14 + 60 * 60 * 24 * 5 <
      Math.floor(Date.now() / 1000)
    ) {
      currRound++;
      setDisplayWarning(true);
    }
  }, []);

  // check if there is an active round
  useEffect(() => {
    let currRound = round;
    if (suffix === "prisma") {
      currRound = currRound + 57;
    }
    if (suffix === "fxn") {
      currRound = currRound + 65;
    }
    if (
      (currRound + 1348) * 86400 * 14 + 60 * 60 * 24 * 5 <
      Math.floor(Date.now() / 1000)
    ) {
      setNoActiveAuction(true);
    }
  }, []);

  // theme setting
  useEffect(() => {
    if (props.ctheme === "light") {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [props.ctheme]);

  // get next date
  useEffect(async () => {
    try {
      let setDate =
        Math.floor(Math.floor(Date.now() / 1000) / (60 * 60 * 24 * 14)) *
          (60 * 60 * 24 * 14) +
        60 * 60 * 24 * 14;
      setDate = new Date(setDate * 1000);
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var month = months[setDate.getMonth()];
      var date = setDate.getDate();
      var j = Number(date) % 10;
      var k = Number(date) % 100;
      if (j == 1 && k != 11) {
        var ndate = date + "st";
      } else if (j == 2 && k != 12) {
        var ndate = date + "nd";
      } else if (j == 3 && k != 13) {
        var ndate = date + "rd";
      } else {
        var ndate = date + "th";
      }
      var hour =
        setDate.getHours() > 12
          ? setDate.getHours() - 12 + " PM"
          : setDate.getHours() + " AM";
      var zone = setDate
        .toLocaleDateString(undefined, {
          day: "2-digit",
          timeZoneName: "short",
        })
        .substring(4);
      var time = month + " " + ndate + ", " + hour + " " + zone;
      setNextDate(time);
    } catch {
      console.log("Couldn't figure out date");
    }
  }, []);

  const handleToggleClick = () => {
    if (toggleText === "Deposit a New Incentive") {
      setToggleText("Show All Incentives");
    } else {
      setToggleText("Deposit a New Incentive");
    }
  };

  useEffect(() => {
    if (toggleText === "Show All Incentives") {
      setToggleText("Deposit a New Incentive");
    }
  }, [location]);

  return (
    <>
      <Grid container direction="row" className={classes.auctionsContainer}>
        <>
          <Grid
            container
            direction="column"
            justifyContent="center"
            className={classes.activeContainer}
          >
            <>
              {!noActiveAuction ? (
                <>
                  <ActiveAuctionCard
                    ctheme={props.ctheme}
                    onAuctionCompletion={handleAuctionOver}
                  />
                </>
              ) : (
                <>
                  <Paper className={classes.noActiveAuctionCard}>
                    <Typography
                      variant="h2"
                      className={classes.noActiveAuctionTitle}
                    >
                      There is currently no active gauge proposal vote{" "}
                    </Typography>
                    <Typography
                      variant="h3"
                      className={classes.noActiveAuctionText}
                    >
                      <p>Next vote starts @ {nextDate}</p>
                      <p>
                        To be eligible for rewards, you must lock your CVX
                        before the date shown above.
                      </p>
                      <p>
                        If delegating to Votium, you must delegate your locked
                        CVX before this date as well.
                      </p>
                    </Typography>
                    <Grid item align="center">
                      <Button
                        className={classes.faqButton}
                        onClick={() => {
                          window.open("https://docs.votium.app/faq/vlcvx-faq");
                        }}
                        variant="outlined"
                      >
                        Frequently Asked Questions
                      </Button>
                    </Grid>

                    <Grid container direction="row" justifyContent="center">
                      <Grid item>
                        <img
                          src={votBox}
                          alt="time-icon"
                          className={classes.votBox}
                        ></img>
                      </Grid>
                    </Grid>
                  </Paper>
                </>
              )}
              <UserIncentivesAccordion
                provider={props.provider}
                db={props.db}
                address={props.address}
                ctheme={props.ctheme}
              />

              <Paper className={classes.togglePaper}>
                <Button
                  classes={{
                    root: classes.toggleButton,
                    disabled: classes.toggleButtonDisabled,
                  }}
                  onClick={handleToggleClick}
                >
                  {toggleText}
                </Button>
              </Paper>

              {toggleText === "Deposit a New Incentive" ? (
                <Bribes db={props.db} ctheme={props.ctheme} />
              ) : (
                <>
                  {(isAuctionOver === true || displayWarning === true) && (
                    <Typography className={classes.auctionOverText}>
                      The depositing period for round {round} has ended. You can
                      now start depositing for round {round + 1}.
                    </Typography>
                  )}{" "}
                  <DepositForm
                    provider={props.provider}
                    address={props.address}
                    db={props.db}
                    displayForm={true}
                    ctheme={props.ctheme}
                  />
                </>
              )}
            </>
          </Grid>
        </>
      </Grid>
    </>
  );
};

export default AuctionPage;
