import { makeStyles } from "@material-ui/core";
export const useStylesDark = makeStyles((theme) => ({
  cvxcrvLogo: {
    width: "2em",
    marginBottom: "-0.2em",
    marginRight: "-0.2em",
  },
  bottomCell: {
    borderBottom: "none",
  },
  cvxLogo: {
    width: "2.8em",
    marginBottom: "-0.4em",
    marginRight: "-0.6em",
  },
  timeIcon: {
    marginBottom: "-0.2em",
    width: "1.5em",
    filter: "invert(50%) grayscale(100%)",
  },
  auctionCard: {
    margin: "1em",
    width: "40em",

    zIndex: 1,
    paddingTop: "0.6em",
    marginTop: "-4em",
    background: "rgb(34 36 38)",
    [theme.breakpoints.down("xs")]: {
      width: "20em",
    },
    borderBottom: "none",
  },
  table: {},
  tableText: {
    color: "#fff",
  },
  proposalText: {
    color: "grey",
    margin: "1em",
  },
  tableTextBottom: {
    color: "#fff",
    borderBottom: "none",
  },
  noBottom: {
    borderBottom: "none",
  },
  paper: {
    backgroundColor: "#F9FAFB",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    width: "50%",
    height: "80%",
    zIndex: 1000,
  },
  inputField: {
    left: "26%",
    margin: "0.8em",
  },
  inputLogo: {
    width: "2em",
  },
  cardTitle: {
    fontSize: "1.1em",
    margin: "0.5em",
    color: "rgb(232, 230, 227)",
  },
  auctionEndedButton: {
    textTransform: "none",
    color: "grey",
    fontSize: "0.9em",
    fontWeight: "400",
    margin: "-0.4em",
  },
  auctionEndedVoteButton: {
    textTransform: "none",
    fontSize: "0.9em",
    fontWeight: "400",
    margin: "-0.4em",
    color: "rgb(232, 230, 227)",
  },
  caption: {
    textTransform: "none",
    textAlign: "center",
    justifyContent: "center",
    marginLeft: "7.3em",
  },
  cvxcrvLogoInput: {
    width: "2.5em",
  },
  accordion: {},
  bidText: {
    alignItems: "center",
  },
  snapshotButton: {
    textTransform: "none",
    fontSize: "0.8em",
    minWidth: "50px",
    background: "transparent",
    borderRadius: "5px",
    color: "grey",
    textAlign: "right",
    borderColor: "rgb(50,50,50)", // Set the color of the outline to white (#fff)
    "&:hover": {
      backgroundColor: "#313131",
    },
  },
  voteButton: {
    color: "rgb(190, 190, 190)",
    textTransform: "none",
    margin: "0em",
    width: "60%",
    borderColor: "grey", // Set the color of the outline to white (#fff)
    "&:hover": {
      backgroundColor: "#313131",
    },
  },
  bidsButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    minWidth: "50px",
    background: "transparent",
    borderRadius: "5px",
    color: "grey",
    textAlign: "right",
  },
  disclaimerText: {
    textTransform: "none",
    color: "grey",
    fontSize: "0.9em",
    fontWeight: "400",
    fontStyle: "italic",
    margin: "-0.4em",
  },
  disclaimerInfoIcon: {
    paddingBottom: "0.6em",
  },
  votingPowerInfoIcon: {
    marginBottom: "-0.3em",
    marginLeft: "0.1em",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down("xs")]: {
      width: "18em",
    },
  },
  pagination: {
    marginTop: "1em",
  },
  modalTitle: {
    fontSize: "1.5em",
  },
}));

export const useStylesLight = makeStyles((theme) => ({
  cvxcrvLogo: {
    width: "2em",
    marginBottom: "-0.2em",
    marginRight: "-0.2em",
  },
  bottomCell: {
    borderBottom: "none",
  },
  cvxLogo: {
    width: "2.8em",
    marginBottom: "-0.4em",
    marginRight: "-0.6em",
  },
  timeIcon: {
    marginBottom: "-0.2em",
    width: "1.5em",
    filter: "invert(50%) grayscale(100%)",
  },
  auctionCard: {
    margin: "1em",
    width: "40em",

    zIndex: 1,
    paddingTop: "0.6em",
    marginTop: "-4em",
    background: "linear-gradient(20deg, #FDFCFB 30%, #D7D7D8 90%)",
    [theme.breakpoints.down("xs")]: {
      width: "20em",
    },
    borderBottom: "none",
  },
  table: {},
  tableText: {
    color: "grey",
  },

  tableTextBottom: {
    color: "grey",
    borderBottom: "none",
  },
  noBottom: {
    borderBottom: "none",
  },
  paper: {
    backgroundColor: "#F9FAFB",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    width: "50%",
    height: "80%",
    zIndex: 1000,
  },
  inputField: {
    left: "26%",
    margin: "0.8em",
  },
  inputLogo: {
    width: "2em",
  },
  cardTitle: {
    fontSize: "1.1em",
    margin: "0.5em",
    color: "rgb(32, 30, 27)",
  },
  proposalText: {
    color: "grey",
    margin: "1em",
  },
  auctionEndedButton: {
    textTransform: "none",
    color: "grey",
    fontSize: "0.9em",
    fontWeight: "400",
    margin: "-0.4em",
  },
  auctionEndedVoteButton: {
    textTransform: "none",
    fontSize: "0.9em",
    fontWeight: "400",
    margin: "-0.4em",
    color: "rgb(232, 230, 227)",
  },
  voteButton: {
    color: "grey",
    textTransform: "none",
    margin: "0em",
    width: "60%",
    borderColor: "grey", // Set the color of the outline to white (#fff)
    "&:hover": {
      backgroundColor: "#313131",
    },
  },
  caption: {
    textTransform: "none",
    textAlign: "center",
    justifyContent: "center",
    marginLeft: "7.3em",
  },
  cvxcrvLogoInput: {
    width: "2.5em",
  },
  accordion: {},
  bidText: {
    alignItems: "center",
  },
  snapshotButton: {
    textTransform: "none",
    fontSize: "0.8em",
    minWidth: "50px",
    background: "transparent",
    borderRadius: "5px",
    color: "grey",
    textAlign: "right",
    borderColor: "rgb(50,50,50)", // Set the color of the outline to white (#fff)
    "&:hover": {
      backgroundColor: "#313131",
    },
  },
  bidsButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    minWidth: "50px",
    background: "transparent",
    borderRadius: "5px",
    color: "grey",
    textAlign: "right",
  },
  disclaimerText: {
    textTransform: "none",
    color: "grey",
    fontSize: "0.9em",
    fontWeight: "400",
    fontStyle: "italic",
    margin: "-0.4em",
  },
  disclaimerInfoIcon: {
    paddingBottom: "0.6em",
  },
  votingPowerInfoIcon: {
    marginBottom: "-0.3em",
    marginLeft: "0.1em",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down("xs")]: {
      width: "18em",
    },
  },
  pagination: {
    marginTop: "1em",
  },
  modalTitle: {
    fontSize: "1.5em",
  },
  snapshotLogo: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    marginRight: "1em",
    marginLeft: "1em",
    background: "#141e27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    height: "3.7em",
  },
}));
