import { makeStyles } from "@material-ui/core";

export const useStylesDark = makeStyles((theme) => ({
  wrapper: {
    justifyContent: "center",
  },
  depositPaper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "0.5em",
    width: "40em",
    zIndex: 1,
    padding: "1em",
    background: "rgb(29 31 33)",
    paddingBottom: "0em",
    paddingTop: "1.2em",
    [theme.breakpoints.up("sm")]: {
      //marginBottom: "12em",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  addPoolPaper: {
    display: "flex",
    flexDirection: "column",
    marginTop: "0.5em",
    width: "12em",
    zIndex: 1,
    background: "rgb(34 36 38)",
    [theme.breakpoints.up("sm")]: {
      //marginBottom: "12em",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  paper: {
    width: "15%",
    margin: "2em",
    backgroundColor: "#141E27",
    [theme.breakpoints.down("md")]: {
      width: "22%",
    },
  },
  text: {
    fontSize: "0.9em",
    textAlign: "center",
    fontFamily: "Rubik",
    color: "grey",
    marginTop: "0.6em",
  },
  logoContainer: {},
  cvxLogo: {
    width: "3em",
    margin: "0.3em",
  },
  auctionsContainer: {
    width: "90%",
    textAlign: "center",
    justifyContent: "center",
    marginLeft: "6.4em",

    [theme.breakpoints.down("xs")]: {
      marginLeft: "1.5em",
    },
    zIndex: 1000,
  },
  auctionCard: {
    margin: "1em",
    width: "20em",
    height: "10em",
  },
  noActiveAuctionCard: {
    margin: "1em",
    width: "40em",
    height: "10em",
    zIndex: 1000,
    paddingTop: "0.6em",
    marginTop: "-4em",
  },
  noActiveAuctionTitle: {
    fontSize: "2em",
    marginTop: "0.2em",
  },
  noActiveAuctionText: {
    fontSize: "1.5em",
    marginTop: "0.8em",
    color: "grey",
  },
  timeText: {
    fontSize: "1.3em",
    marginTop: "1.05em",
    color: "grey",
    textAlign: "center",
  },
  tab: {
    margin: "auto",
    display: "block",
    textAlign: "center",
  },
  uniSpace: {
    maxWidth: "15%",
    marginBottom: "-0.4em",
  },
  cvxSpace: {
    maxWidth: "15%",
  },
  crvSpace: {
    maxWidth: "8%",
    marginTop: "0.5em",
    marginBottom: "0.6em",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  arrowButton: {
    color: "white",
  },
  timeIcon: {
    width: "1.5em",
    marginBottom: "-1.75em",
    marginLeft: "0.8em",
  },
  accordion: {
    width: "40em",
    background: "rgb(34 36 38)",
    [theme.breakpoints.down("xs")]: {
      width: "20em",
    },
  },
  accordionContainer: {
    width: "40em",
    [theme.breakpoints.down("xs")]: {
      width: "20em",
    },
  },
  activeContainer: {
    alignItems: "center",
  },
  arrowsContainer: {
    marginBottom: "4em",
    marginTop: "-6em",
  },
  crvTokenInput: {
    width: "2em",
  },
  submitButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "rgb(220 220 220)",
    color: "black",
    margin: "1em",
    marginTop: "0.8em",
  },
  addPoolButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "rgb(24 25 27)",
    color: "rgb(232, 230, 227)",
  },
  submitButtonDisabled: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: theme.palette.common.lgrey,
    color: "rgb(216 212 207)",
    margin: "1em",
  },
  approveButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "#141E27",
    color: "white",
    margin: "1em",
  },
  approveButtonDisabled: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: theme.palette.common.lgrey,
    color: "white",
    margin: "1em",
  },
  refreshButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    marginBottom: "-1em",
    marginTop: "-1em",
    marginLeft: "0.3em",
    marginRight: "-3em",
  },
  tableTextBottom: {
    borderBottom: "none",
  },
  maxButton: {
    textTransform: "none",
  },
  bidWarning: {
    marginBottom: "1.5em",
    marginTop: "-1em",
  },
  warningIcon: {
    width: "1.3em",
    marginBottom: "-0.2em",
    color: "red",
  },
  textField: {},
  selectContainer: { width: "16em", marginRight: "2em", marginBottom: "1em" },
  inputRoot: {
    color: "rgb(216 212 207)",
    fontWeight: "500",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#888",
    },
    cursor: "default",
    width: "100%",
  },
  input: {
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  label: {
    color: "rgb(216 212 207)", // Use your desired color
    "&.Mui-focused": {
      color: "rgb(255 255 255)", // Use your desired color
    },
  },
  listbox: {
    backgroundColor: "#1f1f1f",
  },
  option: {
    color: "#fff",
    "&.Mui-selected": {
      backgroundColor: "#555",
      color: "#fff",
    },
    "&.Mui-selected:hover": {
      backgroundColor: "#888",
      color: "#fff",
    },
  },
  popupIndicator: { color: "#888" },
  select: {
    zIndex: "5",
    textAlign: "left",
    fontFamily: "Roboto",
    backgroundColor: "transparent",
  },
  selectIndex: {
    zIndex: "4",
    textAlign: "left",
    fontFamily: "Roboto",
    backgroundColor: "transparent",
  },
  selectDeposit: {
    zIndex: "6",
    textAlign: "left",
    fontFamily: "Roboto",
    backgroundColor: "transparent",
  },
  selectRounds: {
    zIndex: "6",
    textAlign: "left",
    fontFamily: "Roboto",
    backgroundColor: "transparent",
  },
  inputField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "grey", // Change the border color to white (#fff)
      },
      "&:hover fieldset": {
        borderColor: "rgb(216 212 207)", // Change the border color to white (#fff) on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(216 212 207)", // Change the border color to white (#fff) when focused
      },
    },
  },
  accordionRow: {
    marginBottom: "1.5em",
  },
  rewardTitle: {
    fontWeight: "700",
    color: "rgb(216 212 207)",
  },
  disclaimerText: {
    fontSize: "0.8em",
    marginTop: "-0.8em",
    marginBottom: "1em",
    color: "grey",
  },
  faqButton: {
    color: "grey",
    textTransform: "none",
    marginBottom: "1.3em",
    fontSize: "0.8em",
    height: "2em",
    borderColor: "grey", // Set the color of the outline to white (#fff)
    "&:hover": {
      backgroundColor: "#313131",
    },
  },
  smallTokenIcon: {
    width: "20px",
    marginBottom: "-0.3em",
    marginRight: "0.4em",
  },
  expandIcon: {
    color: "grey",
  },
  amountText: {
    fontFamily: "Roboto",
    color: "#fff",
  },
}));

export const useStylesLight = makeStyles((theme) => ({
  wrapper: {
    justifyContent: "center",
  },
  depositPaper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "0.5em",
    width: "40em",
    zIndex: 1,
    padding: "1em",
    background: "#e3e5e6",
    paddingBottom: "0em",
    paddingTop: "1.2em",
    [theme.breakpoints.up("sm")]: {
      //marginBottom: "12em",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  addPoolPaper: {
    display: "flex",
    flexDirection: "column",
    marginTop: "0.5em",
    width: "12em",
    zIndex: 1,
    background: "rgb(34 36 38)",
    [theme.breakpoints.up("sm")]: {
      //marginBottom: "12em",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  paper: {
    width: "15%",
    margin: "2em",
    backgroundColor: "#141E27",
    [theme.breakpoints.down("md")]: {
      width: "22%",
    },
  },
  text: {
    fontSize: "0.9em",
    textAlign: "center",
    fontFamily: "Rubik",
    color: "grey",
    marginTop: "0.6em",
  },
  logoContainer: {},
  cvxLogo: {
    width: "3em",
    margin: "0.3em",
  },
  auctionsContainer: {
    width: "90%",
    textAlign: "center",
    justifyContent: "center",
    marginLeft: "6.4em",

    [theme.breakpoints.down("xs")]: {
      marginLeft: "1.5em",
    },
    zIndex: 1000,
  },
  auctionCard: {
    margin: "1em",
    width: "20em",
    height: "10em",
  },
  noActiveAuctionCard: {
    margin: "1em",
    width: "40em",
    height: "10em",
    zIndex: 1000,
    paddingTop: "0.6em",
    marginTop: "-4em",
  },
  noActiveAuctionTitle: {
    fontSize: "2em",
    marginTop: "0.2em",
  },
  noActiveAuctionText: {
    fontSize: "1.5em",
    marginTop: "0.8em",
    color: "grey",
  },
  timeText: {
    fontSize: "1.3em",
    marginTop: "1.05em",
    color: "grey",
    textAlign: "center",
  },
  tab: {
    margin: "auto",
    display: "block",
    textAlign: "center",
  },
  uniSpace: {
    maxWidth: "15%",
    marginBottom: "-0.4em",
  },
  cvxSpace: {
    maxWidth: "15%",
  },
  crvSpace: {
    maxWidth: "8%",
    marginTop: "0.5em",
    marginBottom: "0.6em",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  arrowButton: {
    color: "white",
  },
  timeIcon: {
    width: "1.5em",
    marginBottom: "-1.75em",
    marginLeft: "0.8em",
  },
  accordion: {
    width: "40em",
    background: "linear-gradient(20deg, #FDFCFB 30%, #D7D7D8 90%)",
    [theme.breakpoints.down("xs")]: {
      width: "20em",
    },
  },
  accordionContainer: {
    width: "40em",
    [theme.breakpoints.down("xs")]: {
      width: "20em",
    },
  },
  activeContainer: {
    alignItems: "center",
  },
  arrowsContainer: {
    marginBottom: "4em",
    marginTop: "-6em",
  },
  crvTokenInput: {
    width: "2em",
  },
  addPoolButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "rgb(227 229 230)",
    color: "black",
  },
  submitButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "#141E27",
    color: "rgb(232, 230, 227)",
    margin: "1em",
  },
  submitButtonDisabled: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: theme.palette.common.lgrey,
    color: "rgb(216 212 207)",
    margin: "1em",
  },
  approveButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "#141E27",
    color: "white",
  },
  approveButtonDisabled: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: theme.palette.common.lgrey,
    color: "white",
  },
  refreshButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    marginBottom: "-1em",
    marginTop: "-1em",
    marginLeft: "0.3em",
    marginRight: "-3em",
  },
  tableTextBottom: {
    borderBottom: "none",
  },
  maxButton: {
    textTransform: "none",
  },
  bidWarning: {
    marginBottom: "1.5em",
    marginTop: "-1em",
  },
  warningIcon: {
    width: "1.3em",
    marginBottom: "-0.2em",
    color: "red",
  },
  selectContainer: { width: "16em", marginRight: "2em", marginBottom: "1em" },
  inputRoot: {
    fontWeight: "500",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#888",
    },
    cursor: "default",
    width: "100%",
  },
  input: {
    backgroundColor: "transparent",
    cursor: "pointer",
  },
  label: {
    "&.Mui-focused": {
      color: "rgb(255 255 255)", // Use your desired color
    },
  },
  listbox: {
    backgroundColor: "#1f1f1f",
  },
  option: {
    color: "#fff",
    "&.Mui-selected": {
      backgroundColor: "#555",
      color: "#fff",
    },
    "&.Mui-selected:hover": {
      backgroundColor: "#888",
      color: "#fff",
    },
  },

  select: {
    zIndex: "5",
    textAlign: "left",
    fontFamily: "Roboto",
    backgroundColor: "transparent",
  },
  selectIndex: {
    zIndex: "4",
    textAlign: "left",
    fontFamily: "Roboto",
    backgroundColor: "transparent",
  },
  selectDeposit: {
    zIndex: "6",
    textAlign: "left",
    fontFamily: "Roboto",
    backgroundColor: "transparent",
  },
  selectRounds: {
    zIndex: "6",
    textAlign: "left",
    fontFamily: "Roboto",
    backgroundColor: "transparent",
  },
  inputField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#000", // Change the border color to white (#fff)
      },
      "&:hover fieldset": {
        borderColor: "#000", // Change the border color to white (#fff) on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#000", // Change the border color to white (#fff) when focused
      },
    },
  },
  accordionRow: {
    marginBottom: "1.5em",
  },
  rewardTitle: {
    fontWeight: "700",
    color: "rgb(6 12 7)",
  },
  disclaimerText: {
    fontSize: "0.8em",
    marginTop: "-0.8em",
    marginBottom: "1em",
    color: "grey",
  },
  faqButton: {
    color: "grey",
    textTransform: "none",
    marginBottom: "1.5em",
    width: "60%",
    borderColor: "grey", // Set the color of the outline to white (#fff)
    "&:hover": {
      backgroundColor: "#313131",
    },
  },
  smallTokenIcon: {
    width: "20px",
    marginBottom: "-0.3em",
    marginRight: "0.4em",
  },
  expandIcon: {
    color: "grey",
  },
  amountText: {
    fontFamily: "Roboto",
    color: "black",
  },
}));
