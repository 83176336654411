import React, { useState, useEffect } from "react";
import { onValue, ref } from "firebase/database";
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { useStylesDark, useStylesLight } from "../../../Styles/BribesStyles";
import votBox from "../../../assets/votBox.png";
import BribesRow from "./BribesRow";
import { useLocation } from "react-router-dom";

const Bribes = (props) => {
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  const [classes, setClasses] = useState(classesLight);
  const [depositsArray, setDepositsArray] = useState([]);
  const [bribes, setBribes] = useState(undefined);
  const [tokenPriceValue, setTokenPriceValue] = useState(0);
  const [depositsArrayWithDollarValue, setDepositsArrayWithDollarValue] =
    useState([]);

  const location = useLocation();
  let round = Math.floor(Math.floor(Date.now() / 1000) / (86400 * 14)) - 1348;
  const url = window.location.href;
  const suffix = url.split("/").pop();
  if (
    (round + 1348) * 86400 * 14 + 60 * 60 * 24 * 5 <
    Math.floor(Date.now() / 1000)
  ) {
    round++;
  }
  if (suffix === "prisma") {
    round = round - 57;
  }
  if (suffix === "fxn") {
    round = round - 65;
  }
  // theme setting
  useEffect(() => {
    if (props.ctheme === "light") {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [props.ctheme]);

  useEffect(() => {
    async function getInfoFromDatabase(market) {
      if (props.db) {
        try {
          const bribesRef = ref(
            props.db,
            market + "/depositData/" + round + "/mainnet"
          );
          onValue(bribesRef, (snapshot) => {
            let data = snapshot.val();
            if (data !== null && data !== undefined) {
              setBribes(data);
            } else {
              console.log("Couldn't get incentives from db");
            }
          });
          const tokenPriceRef = ref(props.db, "api/tokens");
          onValue(tokenPriceRef, (snapshot) => {
            let data = snapshot.val();
            if (data !== null && data !== undefined) {
              setTokenPriceValue(data);
            } else {
              console.log("Couldn't get token value from db");
            }
          });
        } catch {
          console.log("Couldn't get info from database");
        }
      }
    }
    const location = window.location.href;
    const suffix = location.split("/").pop();
    let market = "v2";
    if (suffix === "prisma") {
      market = "v2prisma";
    } else if (suffix === "fxn") {
      market = "v2fxn";
    }
    getInfoFromDatabase(market);
  }, [props.db, location]);

  useEffect(() => {
    if (bribes) {
      const sortedDeposits = Object.values(bribes).sort((a, b) => {
        const getTotalRewards = (tokensArray) =>
          tokensArray.reduce((acc, token) => acc + token.amount, 0);
        return getTotalRewards(b) - getTotalRewards(a);
      });
      setDepositsArray(sortedDeposits);
    }
  }, [bribes]);

  useEffect(() => {
    if (depositsArray.length > 0 && Object.keys(tokenPriceValue).length > 0) {
      const updatedDepositsArray = depositsArray.map((tokensArray) => {
        const updatedTokens = tokensArray
          .map((tokenInfo) => {
            const tokenAmount = tokenInfo.amount;
            const tokenAddress = tokenInfo.token.toLowerCase();
            const tokenPrice = tokenPriceValue[tokenAddress.toUpperCase()];
            let dollarValue = 0;
            if (tokenPrice) {
              dollarValue =
                (tokenAmount / 10 ** tokenInfo.decimals) * tokenPrice;
            }

            return {
              ...tokenInfo,
              tokenAddress,
              dollarValue,
            };
          })
          // .filter((token) => token.dollarValue > 100)
          .sort((a, b) => b.dollarValue - a.dollarValue);

        return { tokens: updatedTokens };
      });
      // .filter((deposit) => deposit.tokens.length > 0);

      updatedDepositsArray.sort((a, b) => {
        const getTotalRewards = (tokensArray) =>
          tokensArray.tokens.reduce((acc, token) => acc + token.dollarValue, 0);
        return getTotalRewards(b) - getTotalRewards(a);
      });

      setDepositsArrayWithDollarValue(updatedDepositsArray);
    }
  }, [depositsArray, tokenPriceValue]);

  return (
    <>
      {depositsArray.length === 0 ? (
        <Grid container direction="row" justifyContent="center">
          <Paper className={classes.auctionCard}>
            <Grid container direction="column">
              <Grid item>
                <Typography className={classes.cardTitle}>
                  <p>There are currently no incentives for this round.</p>
                  <p>Please check back later!</p>
                </Typography>
              </Grid>
              <Grid item>
                <img src={votBox} alt="votbox" className={classes.votBox}></img>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ) : (
        <Grid container direction="row" justifyContent="center">
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classes.tableCellNoBorder}
                    style={{ borderRadius: "10px 0 0 10px" }}
                  >
                    Pool
                  </TableCell>
                  <TableCell align="left" className={classes.tableCellNoBorder}>
                    Token Rewards
                  </TableCell>
                  <TableCell align="left" className={classes.tableCellNoBorder}>
                    <Tooltip title="The maximum amount of tokens rewarded per vlCVX">
                      <span> Max Reward/vlCVX </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="left" className={classes.tableCellNoBorder}>
                    Token Rewards
                  </TableCell>

                  <TableCell
                    className={classes.tableCellNoBorder}
                    style={{ borderRadius: "0 10px 10px 0" }}
                  />
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>
                <TableRow>
                  <TableCell
                    colSpan={6}
                    style={{
                      paddingBottom: "0.1em",
                      backgroundColor: "transparent",
                    }}
                  />
                </TableRow>
                {depositsArrayWithDollarValue.map((bribe, index) => (
                  <>
                    <BribesRow
                      key={index}
                      bribe={bribe}
                      className={classes.row}
                      ctheme={props.ctheme}
                    />
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        style={{
                          paddingBottom: "0.1em",
                          backgroundColor: "transparent",
                          borderBottom: "none",
                        }}
                      />
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
};

export default Bribes;
