import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import {
  Typography,
  TextField,
  InputAdornment,
  Button,
  Grid,
  Paper,
  Checkbox,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import { NumericFormat } from "react-number-format";
import { tokenOptions } from "../tokenOptions";
import ClearIcon from "@material-ui/icons/Clear";
import InfoIcon from "@material-ui/icons/Info";
import {
  depositABI,
  prismaDepositABI,
  fxnDepositABI,
  depositAddressEthereum,
  depositAddressFantom,
  feeAddressEthereum,
  feeAddressFantom,
  maxNum,
  erc20ABI,
  prismaDepositAddress,
  fxnDepositAddress,
} from "../../../constants";
import {
  useStylesDark,
  useStylesLight,
} from "../../../Styles/DepositFormStyles";
import { Autocomplete } from "@material-ui/lab";
import { onValue, ref } from "firebase/database";
import { useLocation } from "react-router-dom";

const specialColorDark = {
  style: { color: "rgb(232, 230, 227)" }, // Set the color of the text input in the TextField to white (#fff)
};
const specialColorLight = {
  style: { color: "rgb(20, 21, 23)" }, // Set the color of the text input in the TextField to white (#fff)
};

const customStylesDark = {
  control: (base) => ({
    ...base,
    backgroundColor: "rgb(20, 21, 23)",
    borderColor: "#222",
  }),
  option: (base) => ({
    ...base,
    backgroundColor: "#1f1f1f",
    color: "#fff",
  }),
  // Add these styles to change the color of the side scroll and arrows
  dropdownIndicator: (base) => ({
    ...base,
    color: "#fff",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: "#fff",
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "#1f1f1f",
  }),
  singleValue: (base) => ({
    ...base,
    color: "rgb(216 212 207)",
    fontWeight: "500",
  }),
  input: (base) => ({
    ...base,
    color: "rgb(216 212 207)",
  }),
  menuList: (base) => ({
    ...base,
    "&::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "#1f1f1f",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "5px",
      backgroundColor: "#555",
      "&:hover": {
        backgroundColor: "#888",
      },
    },
  }),
};

const customStylesLight = {
  control: (base) => ({
    ...base,
    backgroundColor: "#fff",
    borderColor: "#222",
  }),
  option: (base) => ({
    ...base,
    backgroundColor: "#fff",
    color: "#000",
  }),
  // Add these styles to change the color of the side scroll and arrows
  dropdownIndicator: (base) => ({
    ...base,
    color: "#000",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: "#000",
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: "#fff",
  }),
  singleValue: (base) => ({
    ...base,
    color: "rgb(16 12 7)",
    fontWeight: "500",
  }),
  input: (base) => ({
    ...base,
    color: "rgb(16 12 07)",
  }),
  menuList: (base) => ({
    ...base,
    "&::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: "#fff",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "5px",
      backgroundColor: "#eee",
      "&:hover": {
        backgroundColor: "#aaa",
      },
    },
  }),
};

const DepositForm = (props) => {
  const provider = props.provider;

  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  const [classes, setClasses] = useState(classesDark);
  const [customStyles, setCustomStyles] = useState(customStylesDark);
  const [specialColor, setSpecialColor] = useState(specialColorDark);
  const [numOfRounds, setNumOfRounds] = useState(1);
  const [selectedToken, setSelectedToken] = useState(tokenOptions[0]);
  const [poolIndex, setPoolIndex] = useState(null);
  const [indexArray, setIndexArray] = useState([]);
  const [gaugesArray, setGaugesArray] = useState([]);
  const [isApproved, setIsApproved] = useState(false);
  const [depositIncentiveText, setDepositIncentiveText] =
    useState("Deposit Incentive");
  const [depositButtonDisabled, setDepositButtonDisabled] = useState(false);
  const [approveText, setApproveText] = useState("Approve Token");
  const [approveDisabled, setApproveDisabled] = useState(false);
  const [chainName, setChainName] = useState("Not supported");
  const [currDepositAddress, setCurrDepositAddress] = useState(
    depositAddressEthereum
  );
  const [currABI, setCurrABI] = useState(depositABI);
  const [currTokenOptions, setCurrTokenOptions] = useState(tokenOptions);
  const [rows, setRows] = useState([{ pool: "", rewards: "" }]);
  const [totalReward, setTotalReward] = useState(0);
  const [isCapPerVote, setIsCapPerVote] = useState(false);
  const [maxCap, setMaxCap] = useState(0);

  let round = Math.floor(Math.floor(Date.now() / 1000) / (86400 * 14)) - 1348;
  const location = useLocation();
  const url = window.location.href;
  const suffix = url.split("/").pop();
  if (
    (round + 1348) * 86400 * 14 + 60 * 60 * 24 * 4 + 60 * 60 * 18 <
    Math.floor(Date.now() / 1000)
  ) {
    round++;
  }
  if (suffix === "prisma") {
    round = round - 57;
  }
  if (suffix === "fxn") {
    round = round - 65;
  }
  const numberOfRoundsOptions = [
    {
      value: 0,
      label: `${round}`,
    },
    {
      value: 1,
      label: `${round}, ${round + 1}`,
    },
    {
      value: 2,
      label: `${round}, ${round + 1}, ${round + 2}`,
    },
    {
      value: 3,
      label: `${round}, ${round + 1}, ${round + 2}, ${round + 3}`,
    },
    {
      value: 4,
      label: `${round}, ${round + 1}, ${round + 2}, ${round + 3}, ${round + 4}`,
    },
  ];

  //get gauge addresses
  useEffect(() => {
    async function getInfoFromDatabase() {
      if (props.db && indexArray !== []) {
        if (gaugesArray.length === 0) {
          if (suffix === "prisma") {
            try {
              const gaugesRef = ref(props.db, "v2prisma/gauges/gauges");
              onValue(gaugesRef, (snapshot) => {
                let data = snapshot.val();
                if (data !== null && data !== undefined) {
                  setGaugesArray(data);
                }
              });
            } catch {
              console.log("Couldn't get info from database");
            }
          } else if (suffix === "fxn") {
            try {
              const gaugesRef = ref(props.db, "v2fxn/gauges/gauges");
              onValue(gaugesRef, (snapshot) => {
                let data = snapshot.val();
                if (data !== null && data !== undefined) {
                  setGaugesArray(data);
                }
              });
            } catch {
              console.log("Couldn't get info from database");
            }
          } else {
            try {
              const gaugesRef = ref(props.db, "v2/gauges/gauges");
              onValue(gaugesRef, (snapshot) => {
                let data = snapshot.val();
                if (data !== null && data !== undefined) {
                  setGaugesArray(data);
                }
              });
            } catch {
              console.log("Couldn't get info from database");
            }
          }
        }
      }
    }

    getInfoFromDatabase();
  }, [props.db, indexArray, location]);

  const addRow = () => {
    setRows((prevRows) => [...prevRows, { pool: "", rewards: "" }]);
  };
  const updateRow = (index, key, value) => {
    setRows((prevRows) => {
      const newRows = prevRows.map((row, i) =>
        i === index ? { ...row, [key]: value } : row
      );
      const totalReward = newRows.reduce(
        (total, row) =>
          total + (isNaN(Number(row.rewards)) ? 0 : Number(row.rewards)),
        0
      );
      setTotalReward(totalReward);
      console.log(newRows);

      return newRows;
    });
  };

  const removeRow = (indexToRemove) => {
    setRows((prevRows) => {
      const newRows = prevRows.filter((_, index) => index !== indexToRemove);

      // calculate total rewards
      const totalReward = newRows.reduce(
        (total, row) => total + Number(row.rewards),
        0
      );
      setTotalReward(totalReward);

      return newRows;
    });
  };

  const checkDisplayDescription = () => {
    if (totalReward > 0 && numOfRounds !== 1) {
      return true;
    } else {
      return false;
    }
  };

  const handleCapPerVote = () => {
    setMaxCap(0);
    setIsCapPerVote(!isCapPerVote);
  };

  //if all of the fields of rows are filled, disable deposit button
  useEffect(() => {
    if (
      rows.every((row) => row.pool !== "" && row.rewards !== "") &&
      totalReward > 0
    ) {
      setDepositButtonDisabled(false);
      setDepositIncentiveText("Deposit Incentive");
    } else {
      setDepositButtonDisabled(true);
      setDepositIncentiveText("Missing Pool or Amount Selection");
    }
  }, [rows, totalReward]);

  // theme setting
  useEffect(() => {
    if (props.ctheme === "light") {
      setClasses(classesLight);
      setCustomStyles(customStylesLight);
      setSpecialColor(specialColorLight);
    } else {
      setClasses(classesDark);
      setCustomStyles(customStylesDark);
      setSpecialColor(specialColorDark);
    }
  }, [props.ctheme]);

  //change contract address if using prisma / fxn / changing chain
  useEffect(() => {
    if (props.provider) {
      if (props.provider._network.chainId) {
        if (props.provider._network.chainId === 1) {
          if (suffix === "prisma") {
            setCurrDepositAddress(prismaDepositAddress);
            setCurrABI(prismaDepositABI);
          } else if (suffix === "fxn") {
            setCurrDepositAddress(fxnDepositAddress);
            setCurrABI(fxnDepositABI);
          } else {
            setCurrDepositAddress(depositAddressEthereum);
            setCurrABI(depositABI);
          }
          setChainName("Ethereum");
        } else {
          setApproveDisabled(true);
          setDepositButtonDisabled(true);
          setDepositIncentiveText("Connect to mainnet");
        }
        let newTokenOptions = [];
        currTokenOptions.map((token) => {
          if (token.chainId == props.provider._network.chainId) {
            newTokenOptions.push(token);
          }
        });
        setCurrTokenOptions(newTokenOptions);
      }
    }
  }, [props.provider, location]);

  useEffect(() => {}, [props.provider, currDepositAddress]);

  useEffect(() => {
    if (gaugesArray && Object.keys(gaugesArray).length !== 0) {
      let res = [];

      Object.entries(gaugesArray).forEach(([address, pool]) => {
        let choice = { value: address, label: pool.shortName };
        res.push(choice);
      });
      console.log("choices array:", res);
      setIndexArray(res);
    }
  }, [gaugesArray, location]);

  async function approve() {
    setApproveText(<CircularProgress size={18} />);
    setApproveDisabled(true);
    let signer = await provider.getSigner();
    const erc20ContractWithSigner = new ethers.Contract(
      selectedToken.value,
      erc20ABI,
      signer
    );
    try {
      const tx = await erc20ContractWithSigner.approve(
        currDepositAddress,
        maxNum
      );
      await tx.wait();
      setIsApproved(true);
    } catch (error) {
      setApproveDisabled(false);
      setApproveText("Approve Token");
      console.error("failed to deposit incentive: ", error);
    }
  }

  //check if approved
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (props.provider && props.address) {
      try {
        const erc20Contract = new ethers.Contract(
          selectedToken.value,
          erc20ABI,
          props.provider
        );
        let allowance = await erc20Contract.allowance(
          props.address,
          currDepositAddress
        );
        if (
          Number(allowance) >=
            Number(10 ** selectedToken.decimals * totalReward * numOfRounds) &&
          Number(allowance) > 0
        ) {
          setIsApproved(true);
          // console.log(
          //   "amount approved is enough: " +
          //     Number(allowance / 10 ** selectedToken.decimals)
          // );
          // console.log("Amount to deposit: " + totalReward * numOfRounds);
        } else {
          setIsApproved(false);
          setApproveDisabled(false);
          setApproveText("Approve Token");
          // console.log("insufficient amount approved");
          // console.log("allowance: " + allowance / 10 ** selectedToken.decimals);
          // console.log("Amount to deposit: " + totalReward * numOfRounds);
        }
      } catch (error) {
        console.log("Couldn't get allowance");
        console.error(error);
        setApproveText("Connect to Mainnet");
        setDepositIncentiveText("Connect to Mainnet");
      }
    } else {
      setApproveText("Connect Wallet to Deposit");
      setApproveDisabled(true);
    }
  }, [
    props.provider,
    props.address,
    selectedToken,
    currDepositAddress,
    totalReward,
    numOfRounds,
    location,
  ]);

  async function depositIncentive() {
    const areRewardsEqual = (rows) => {
      const firstReward = rows[0].rewards;
      return rows.every((row) => row.rewards === firstReward);
    };

    setDepositIncentiveText(<CircularProgress size={18} />);
    setDepositButtonDisabled(true);
    try {
      let depositContractWithProvider = new ethers.Contract(
        currDepositAddress,
        currABI,
        props.provider
      );
      const currFeeAddress = await depositContractWithProvider.feeAddress();
      const activeRound = await depositContractWithProvider.activeRound();
      let signer = await provider.getSigner();
      let depositContractWithSigner = new ethers.Contract(
        currDepositAddress,
        currABI,
        signer
      );
      let gaugeAddress = null;
      let gaugeAddressArray = null;
      let amountsArray = null;
      if (suffix === "prisma") {
        // If the suffix is 'prisma', parse the gauge addresses as integers
        if (rows.length > 1) {
          gaugeAddressArray = rows.map((row) => parseInt(row.pool.value));
          let rowsClone = [...rows];
          amountsArray = rowsClone.map((row) =>
            ethers.utils.parseUnits(
              row.rewards.toString(),
              selectedToken.decimals
            )
          );
        } else {
          gaugeAddress = parseInt(rows[0].pool.value);
        }
      } else {
        if (rows.length > 1) {
          gaugeAddressArray = rows.map((row) => row.pool.value);
          let rowsClone = [...rows];
          amountsArray = rowsClone.map((row) =>
            ethers.utils.parseUnits(
              row.rewards.toString(),
              selectedToken.decimals
            )
          );
        } else {
          gaugeAddress = rows[0].pool.value;
        }
      }
      console.log(gaugeAddress);
      console.log(gaugeAddressArray);

      if (
        (currDepositAddress.toUpperCase() ===
          depositAddressEthereum.toUpperCase() &&
          currFeeAddress.toUpperCase() === feeAddressEthereum.toUpperCase()) ||
        (currDepositAddress.toUpperCase() ===
          prismaDepositAddress.toUpperCase() &&
          currFeeAddress.toUpperCase() === feeAddressEthereum.toUpperCase()) ||
        (currDepositAddress.toUpperCase() === fxnDepositAddress.toUpperCase() &&
          currFeeAddress.toUpperCase() === feeAddressEthereum.toUpperCase())
      ) {
        if (numOfRounds === 1) {
          if (rows.length > 1) {
            if (areRewardsEqual(rows)) {
              const tx = await depositContractWithSigner.depositSplitGauges(
                selectedToken.value, //token address
                ethers.utils.parseUnits(
                  rows[0].rewards.toString(),
                  selectedToken.decimals
                ), //amount
                activeRound, // round #
                gaugeAddressArray, //gauges array
                ethers.utils.parseUnits(
                  maxCap.toString(),
                  selectedToken.decimals
                ), //maxPerVote
                [] //blacklisted addresses array
              );
              await tx.wait();
              window.location.reload();
            } else {
              if (maxCap === 0) {
                const tx =
                  await depositContractWithSigner.depositUnevenSplitGaugesSimple(
                    selectedToken.value, //token address
                    gaugeAddressArray, //gauges array
                    amountsArray //amounts array
                  );
                await tx.wait();
                window.location.reload();
              } else {
                const tx =
                  await depositContractWithSigner.depositUnevenSplitGauges(
                    selectedToken.value, //token address
                    activeRound, // round #
                    gaugeAddressArray, //gauges array
                    amountsArray, //amounts array
                    ethers.utils.parseUnits(
                      maxCap.toString(),
                      selectedToken.decimals
                    ), //maxPerVote
                    [] //blacklisted addresses array
                  );
                await tx.wait();
                window.location.reload();
              }
            }
          } else {
            if (maxCap === 0) {
              console.log(
                selectedToken.value, //token address
                ethers.utils.parseUnits(
                  totalReward.toString(),
                  selectedToken.decimals
                ), //amount
                gaugeAddress //gauge address
              );
              const tx = await depositContractWithSigner.depositIncentiveSimple(
                selectedToken.value, //token address
                ethers.utils.parseUnits(
                  totalReward.toString(),
                  selectedToken.decimals
                ), //amount
                gaugeAddress //gauge address
              );
              await tx.wait();
              window.location.reload();
            } else {
              const tx = await depositContractWithSigner.depositIncentive(
                selectedToken.value, //token address
                ethers.utils.parseUnits(
                  totalReward.toString(),
                  selectedToken.decimals
                ), //amount
                activeRound, // round #
                gaugeAddress, //gauge address
                ethers.utils.parseUnits(
                  maxCap.toString(),
                  selectedToken.decimals
                ), //maxPerVote
                [] //blacklisted addresses array
              );
              await tx.wait();
              window.location.reload();
            }
          }
        } else {
          if (rows.length > 1) {
            if (areRewardsEqual(rows)) {
              const tx =
                await depositContractWithSigner.depositSplitGaugesRounds(
                  selectedToken.value, //token address
                  ethers.utils.parseUnits(
                    rows[0].rewards.toString(),
                    selectedToken.decimals
                  ), //amount
                  numOfRounds, // # of rounds
                  gaugeAddressArray, //gauges array
                  ethers.utils.parseUnits(
                    maxCap.toString(),
                    selectedToken.decimals
                  ), //maxPerVote
                  [] //blacklisted addresses array
                );
              await tx.wait();
              window.location.reload();
            } else {
              const tx =
                await depositContractWithSigner.depositUnevenSplitGaugesRounds(
                  selectedToken.value, //token address
                  numOfRounds, // # of rounds
                  gaugeAddressArray, //gauges array
                  amountsArray, //amounts array
                  ethers.utils.parseUnits(
                    maxCap.toString(),
                    selectedToken.decimals
                  ), //maxPerVote
                  [] //blacklisted addresses array
                );
              await tx.wait();
              window.location.reload();
            }
          } else {
            const tx = await depositContractWithSigner.depositSplitRounds(
              selectedToken.value, //token address
              ethers.utils.parseUnits(
                totalReward.toString(),
                selectedToken.decimals
              ), //amount
              numOfRounds, // # of rounds (starts from activeRound())
              gaugeAddress, //rows[0].pool, //gauge
              ethers.utils.parseUnits(
                maxCap.toString(),
                selectedToken.decimals
              ), //maxPerVote
              [] //blacklisted addresses array
            );
            await tx.wait();
            window.location.reload();
          }
        }
      }
    } catch (error) {
      setDepositButtonDisabled(false);
      setDepositIncentiveText("Deposit Incentive");
      console.error("Failed to deposit incentive: ", error);
    }
  }

  return (
    <>
      {props.displayForm && (
        <>
          <div className={classes.depositContainer}>
            <Paper className={classes.depositPaper} elevation={1}>
              <Grid container direction="column">
                <Grid item className={classes.accordionRow}>
                  <Autocomplete
                    disableClearable={true}
                    id="round-selection"
                    options={numberOfRoundsOptions}
                    defaultValue={numberOfRoundsOptions[0]}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      setNumOfRounds(newValue.value + 1);
                    }}
                    classes={{
                      listbox: classes.listbox,
                      option: classes.option,
                      input: classes.input,
                      popupIndicator: classes.popupIndicator,
                    }}
                    renderInput={(params) => (
                      <TextField
                        disabled={true}
                        {...params}
                        label={"Select Rounds"}
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          classes: {
                            root: classes.inputRoot,
                          },
                        }}
                        InputLabelProps={{
                          className: classes.label,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item className={classes.accordionRow}>
                  <Autocomplete
                    disableClearable={true}
                    id="token-selection"
                    options={currTokenOptions}
                    getOptionLabel={(option) => option.label}
                    value={selectedToken}
                    onChange={(event, newValue) => {
                      setSelectedToken(newValue);
                    }}
                    classes={{
                      listbox: classes.listbox,
                      option: classes.option,
                      input: classes.input,
                      popupIndicator: classes.popupIndicator,
                    }}
                    renderInput={(params) => (
                      <TextField
                        disabled={true}
                        {...params}
                        label="Select Token To Bid With"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          classes: {
                            root: classes.inputRoot,
                          },
                        }}
                        InputLabelProps={{
                          className: classes.label,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    style={{ marginTop: "-1.5em" }}
                  >
                    <Grid item>
                      <Checkbox
                        size="small"
                        style={{ marginRight: "-0.2em" }}
                        onChange={() => {
                          handleCapPerVote();
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: "grey", fontSize: "0.8em" }}>
                        Cap Max ${selectedToken.label} per vote
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Downsides and upsides of putting a cap on $/vote">
                        <InfoIcon
                          fontSize="small"
                          style={{
                            color: "grey",
                            marginLeft: "0.2em",
                            marginBottom: "-0.1em",
                          }}
                        ></InfoIcon>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  style={{ marginBottom: "0.3em" }}
                >
                  {isCapPerVote && (
                    <Grid container direction="row" justifyContent="flex-start">
                      <Grid
                        item
                        align="right"
                        style={{
                          marginLeft: "0.7em",
                          marginTop: "0.5em",
                          width: "12em",
                          marginBottom: "0.3em",
                        }}
                      >
                        <NumericFormat
                          customInput={TextField}
                          className={classes.inputField}
                          label={"Max $" + selectedToken.label + " Per Vote"}
                          variant="outlined"
                          type="text"
                          value={maxCap}
                          onValueChange={(value) => {
                            setMaxCap(value.floatValue);
                          }}
                          NumericString
                          thousandSeparator
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <div
                                  style={{
                                    width: "30px",
                                    marginBottom: "-0.3em",
                                  }}
                                >
                                  {selectedToken.img}
                                </div>
                              </InputAdornment>
                            ),
                            ...specialColor,
                          }}
                          InputLabelProps={specialColor}
                          FormHelperTextProps={specialColor}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Paper>
            {rows.map((row, index) => (
              <React.Fragment key={index}>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <Paper className={classes.depositPaper}>
                      <Grid container direction="row">
                        <Grid item className={classes.selectContainer}>
                          <Autocomplete
                            disableClearable={true}
                            id="pool-selection"
                            options={indexArray}
                            getOptionLabel={(option) => option.label}
                            classes={{
                              listbox: classes.listbox,
                              option: classes.option,
                              input: classes.input,
                              popupIndicator: classes.popupIndicator,
                            }}
                            renderInput={(params) => (
                              <TextField
                                style={{ width: "17em" }}
                                disabled={true}
                                {...params}
                                label="Select Pool to Incentivise"
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  classes: {
                                    root: classes.inputRoot,
                                  },
                                }}
                                InputLabelProps={{
                                  className: classes.label,
                                }}
                              />
                            )}
                            onChange={(event, newValue) => {
                              updateRow(index, "pool", newValue);
                            }}
                          />
                        </Grid>
                        <Grid item style={{ width: "22em" }}>
                          <NumericFormat
                            customInput={TextField}
                            className={classes.inputField}
                            label={"Rewards for This Pool"}
                            variant="outlined"
                            type="text"
                            value={row.rewards}
                            onValueChange={(values) =>
                              updateRow(index, "rewards", values.floatValue)
                            }
                            isNumericString
                            thousandSeparator
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <div
                                    style={{
                                      width: "30px",
                                      marginBottom: "-0.3em",
                                    }}
                                  >
                                    {selectedToken.img}
                                  </div>
                                </InputAdornment>
                              ),
                              ...specialColor,
                            }}
                            InputLabelProps={specialColor}
                            FormHelperTextProps={specialColor}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  {rows.length > 1 && (
                    <Grid item>
                      <ClearIcon
                        style={{
                          cursor: "pointer",
                          color: "grey",
                          marginRight: "-1.5em",
                        }}
                        onClick={() => removeRow(index)}
                      />
                    </Grid>
                  )}
                </Grid>
              </React.Fragment>
            ))}{" "}
            <Grid container justifyContent="flex-end" alignItems="center">
              <Paper className={classes.addPoolPaper}>
                {" "}
                <Button className={classes.addPoolButton} onClick={addRow}>
                  + Add Another Pool
                </Button>
              </Paper>{" "}
            </Grid>{" "}
            <Paper className={classes.depositPaper}>
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  direction="row"
                >
                  <Grid item align="center">
                    <Typography style={{ color: "grey" }}>
                      Total Rewards: &nbsp;
                    </Typography>
                  </Grid>
                  <Grid item align="center">
                    <Grid
                      container
                      direction="row"
                      alignContent="center"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Grid item>
                        <div className={classes.smallTokenIcon}>
                          {selectedToken.img}
                        </div>
                      </Grid>
                      <Grid item>
                        <NumericFormat
                          className={classes.amountText}
                          displayType="text"
                          isNumericString
                          thousandSeparator
                          fixedDecimalScale={true}
                          decimalScale={2}
                          value={totalReward * numOfRounds}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {checkDisplayDescription() && (
                  <Grid item align="center">
                    <Typography
                      style={{
                        marginTop: "0.1em",
                        marginBottom: "0.3em",
                        color: "grey",
                        fontSize: "0.75em",
                      }}
                    >
                      <NumericFormat
                        displayType="text"
                        isNumericString
                        thousandSeparator
                        fixedDecimalScale={true}
                        decimalScale={2}
                        value={totalReward}
                      />
                      &nbsp;{selectedToken.label} x {numOfRounds} rounds
                    </Typography>
                  </Grid>
                )}
                {isCapPerVote && (
                  <>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      direction="row"
                      style={{ marginTop: "0.1em" }}
                    >
                      <Grid item align="center">
                        <Typography style={{ color: "grey" }}>
                          Max ${selectedToken.label} Per Vote: &nbsp;
                        </Typography>
                      </Grid>
                      <Grid item align="center">
                        <Grid
                          container
                          direction="row"
                          alignContent="center"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <Grid item>
                            <div className={classes.smallTokenIcon}>
                              {selectedToken.img}
                            </div>
                          </Grid>
                          <Grid item>
                            <NumericFormat
                              className={classes.amountText}
                              displayType="text"
                              isNumericString
                              thousandSeparator
                              value={maxCap}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid item>
                  {isApproved ? (
                    <Button
                      classes={{
                        root: classes.submitButton,
                        disabled: classes.submitButtonDisabled,
                      }}
                      onClick={depositIncentive}
                      disabled={depositButtonDisabled}
                    >
                      {depositIncentiveText}
                    </Button>
                  ) : (
                    <Button
                      classes={{
                        root: classes.submitButton,
                        disabled: classes.submitButtonDisabled,
                      }}
                      onClick={approve}
                      disabled={approveDisabled}
                    >
                      {approveText}
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    className={classes.faqButton}
                    onClick={() => {
                      window.open("https://docs.votium.app/faq/disclaimers");
                    }}
                    variant="outlined"
                  >
                    Votium disclaimer - read before depositing
                  </Button>
                </Grid>
                {/* <Grid item>
                  <Typography className={classes.text}>
                    Chain: {chainName}
                  </Typography>
                </Grid> */}
              </Grid>
            </Paper>
          </div>
        </>
      )}
    </>
  );
};

export default DepositForm;
