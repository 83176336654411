import React, { useState, useEffect } from "react";
import {
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Table,
  TableHead,
  TableBody,
  Box,
  Grid,
  Tooltip,
} from "@material-ui/core";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import { Rule, Check, Clear, Warning } from "@mui/icons-material";
import { useStylesDark, useStylesLight } from "../../../Styles/BribesStyles";
import { tokenOptions } from "../tokenOptions";
import { BsFillLightningFill } from "react-icons/bs";
import { ethers } from "ethers";
const BribesRow = (props) => {
  const { bribe } = props;
  const pool = bribe.tokens[0].pool;
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  const [classes, setClasses] = useState(classesDark);
  const [open, setOpen] = useState(false);

  // theme setting
  useEffect(() => {
    if (props.ctheme === "light") {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [props.ctheme]);

  const determineIcon = () => {
    const maxPerVoteValues = bribe.tokens.map((token) => {
      const maxPerVote = Number(
        ethers.utils.formatUnits(token.maxPerVote.toString(), token.decimals)
      );
      if (maxPerVote > token.dollarValue) {
        return 0;
      } else {
        return maxPerVote;
      }
    });

    if (maxPerVoteValues.every((value) => value !== 0))
      return (
        <Tooltip
          arrow
          title="These deposits have a max reward per vlCVX, expand to see more."
        >
          <Check />
        </Tooltip>
      );
    if (maxPerVoteValues.every((value) => value === 0))
      return (
        <Tooltip arrow title="These deposits have no max reward per vlCVX :)">
          <Clear />
        </Tooltip>
      );
    return (
      <Tooltip
        arrow
        title="Some of these deposits have a max reward per vlCVX, expand to see more."
      >
        <Rule />
      </Tooltip>
    );
  };

  useEffect(() => {
    console.log(bribe);
  }, [bribe]);

  const totalAmount = bribe.tokens.reduce((acc, token) => {
    const maxPerVote = ethers.utils.formatUnits(
      token.maxPerVote,
      token.decimals
    );
    if (maxPerVote !== "0.0" && maxPerVote * 20 * 1000000 < token.dollarValue) {
      return acc + maxPerVote * 20 * 1000000;
    } else {
      return acc + token.dollarValue / 0.96;
    }
  }, 0);
  const usdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const formattedDollarAmount = usdFormatter.format(totalAmount);
  const getTokenImage = (tokenAddress) => {
    if (!tokenAddress) return null;
    const token = tokenOptions.find(
      (option) => option.value.toLowerCase() === tokenAddress.toLowerCase()
    );
    return token
      ? React.cloneElement(token.img, {
          style: { width: "24px", borderRadius: "50%" },
          title: token.label,
        })
      : null;
  };

  const getTokenLabel = (tokenAddress) => {
    if (!tokenAddress) return null;
    const token = tokenOptions.find(
      (option) => option.value.toLowerCase() === tokenAddress.toLowerCase()
    );
    return token ? token.label : null;
  };

  const getUniqueTokens = (tokens) => {
    return tokens.reduce((uniqueTokens, currentToken) => {
      if (
        !uniqueTokens.some(
          (token) => token.tokenAddress === currentToken.tokenAddress
        )
      ) {
        uniqueTokens.push(currentToken);
      }
      return uniqueTokens;
    }, []);
  };

  return (
    <>
      <TableRow
        className={open ? classes.openedTableRow : classes.tableRow}
        onClick={() => {
          setOpen(!open);
        }}
        style={{ cursor: "pointer" }}
      >
        <TableCell
          component="th"
          scope="row"
          style={{
            borderRadius: "10px 0 0 10px",
            fontWeight: "500",
          }}
          className={classes.tableCell}
        >
          {pool}
        </TableCell>
        <TableCell align="left" style={{ width: "39%" }}>
          {getUniqueTokens(bribe.tokens).map((token, index) => (
            <span key={index} style={{ marginRight: "-4px" }}>
              {getTokenImage(token.tokenAddress)}
            </span>
          ))}
        </TableCell>{" "}
        <TableCell align="left" className={classes.tableCell}>
          {determineIcon()}
        </TableCell>
        <TableCell align="left" className={classes.tableCell}>
          <Typography style={{ fontWeight: "500" }}>
            {" "}
            {formattedDollarAmount}
          </Typography>
        </TableCell>
        <TableCell align="right" style={{ borderRadius: "0 10px 10px 0" }}>
          <IconButton aria-label="expand row" size="small">
            {open ? (
              <KeyboardArrowUp className={classes.tableCell} />
            ) : (
              <KeyboardArrowDown className={classes.tableCell} />
            )}
          </IconButton>
        </TableCell>
      </TableRow>

      {open && (
        <>
          <Box style={{ marginBottom: "0.15em" }}></Box>
          <TableRow className={classes.openedTableRow}>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                borderRadius: "10px 10px 10px 10px",
              }}
              colSpan={8}
            >
              <Table size="small" aria-label="bribe tokens">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ borderBottom: "none" }}
                    >
                      Token
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ borderBottom: "none" }}
                      className={classes.tableCell}
                    >
                      Max Reward/vlCVX
                    </TableCell>{" "}
                    <TableCell
                      align="left"
                      style={{ borderBottom: "none" }}
                      className={classes.tableCell}
                    >
                      USD Amount
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bribe.tokens.map((token, index) => (
                    <TableRow key={index}>
                      <TableCell
                        style={{ width: "23.5%", borderBottom: "none" }}
                      ></TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.tableCell}
                        style={{ width: "40.5%", borderBottom: "none" }}
                      >
                        <Grid container direction="row">
                          <Grid item style={{ marginRight: "6px" }}>
                            {getTokenImage(token.tokenAddress)}
                          </Grid>
                          <Grid item style={{ marginTop: "0.17em" }}>
                            {(
                              ethers.utils.formatUnits(
                                token.amount,
                                token.decimals
                              ) / 0.96
                            ).toLocaleString()}
                            {" $"}
                            {getTokenLabel(token.tokenAddress)}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classes.tableCell}
                        style={{ width: "17%", borderBottom: "none" }}
                      >
                        {Number(
                          ethers.utils.formatUnits(
                            token.maxPerVote.toString(),
                            token.decimals
                          )
                        ) === 0 ||
                        Number(
                          ethers.utils.formatUnits(
                            token.maxPerVote.toString(),
                            token.decimals
                          )
                        ) >
                          token.dollarValue / 0.96
                          ? "N/A"
                          : ethers.utils.formatUnits(
                              token.maxPerVote.toString(),
                              token.decimals
                            ) +
                            " $" +
                            getTokenLabel(token.tokenAddress)}
                      </TableCell>{" "}
                      <TableCell
                        align="left"
                        className={classes.tableCell}
                        style={{ borderBottom: "none" }}
                      >
                        {token.maxPerVote !== "0" &&
                        ethers.utils.formatUnits(
                          token.maxPerVote.toString(),
                          token.decimals
                        ) *
                          20 *
                          1000000 <
                          token.dollarValue ? (
                          <>
                            {usdFormatter.format(
                              ethers.utils.formatUnits(
                                token.maxPerVote.toString(),
                                token.decimals
                              ) *
                                20 *
                                1000000
                            )}{" "}
                            <Tooltip
                              arrow
                              title={
                                "Due to a low Max Reward/vlCVX, the total possible USD shown is based on gauge receiving votes from 20m vlCVX. The original deposit amount is " +
                                usdFormatter.format(token.dollarValue / 0.96)
                              }
                            >
                              <Warning
                                fontSize="small"
                                style={{ marginBottom: "-0.2em" }}
                              />
                            </Tooltip>
                          </>
                        ) : (
                          usdFormatter.format(token.dollarValue / 0.96)
                        )}
                      </TableCell>
                      {/* {index === 0 ? (
                        <Button
                          className={classes.snapshotLogo}
                          onClick={() => {
                            window.open(snapshotURL);
                          }}
                        >
                          <BsFillLightningFill
                            align="right"
                            style={{ marginRight: "-1.5em" }}
                            color="#f3b04e"
                            onClick={() => {
                              //goto snapshot page
                              window.open(snapshotURL);
                            }}
                          />
                        </Button>
                      ) : null} */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
};
export default BribesRow;
