/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@material-ui/core";
import Countdown, {
  zeroPad,
} from "react-countdown";
import timeIcon from "../../../../assets/timeIcon.png";
import { useStylesDark, useStylesLight } from "../../../../Styles/ActiveAuctionCardStyles";

const ActiveAuctionCard = (props) => {
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  const [classes, setClasses] = useState(classesDark);
  const [isAuctionOver, setIsAuctionOver] = useState(false);
  const [isVotingOver, setIsVotingOver] = useState(false);
  const [vlCVXAtEpoch, setvlCVXAtEpoch] = useState("0.00");

  // theme setting 
  useEffect(() => {
    if (props.ctheme === 'light') {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [props.ctheme]);
  
  //countdown comp
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setIsAuctionOver(true);
      props.isAuctionOver(true);
      return (
        <Typography className={classes.auctionEndedButton}>
          00:00:00:00
        </Typography>
      );
    } else {
      setIsAuctionOver(false);
      props.isAuctionOver(false);
      return (
        <span style={{ color: "rgb(232, 230, 227)" }}>
          {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };
  const rendererVoting = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setIsVotingOver(true);
      return (
        <Typography className={classes.auctionEndedButton}>
          00:00:00:00
        </Typography>
      );
    } else {
      setIsVotingOver(false);
      return (
        <span className={classes.auctionEndedVoteButton}>
          {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };

  return (
    <>
      <Paper className={classes.auctionCard}>
        <Typography className={classes.cardTitle}>
          {props.auction.title}
        </Typography>
        <Typography className={classes.proposalText}>
          <p>veCRV Gauge vote rewards for the week of {props.currDate}</p>
          <Button
            className={classes.snapshotButton}
            onClick={() => {
              window.open("https://dao.curve.fi/gaugeweight");
            }}
            variant="outlined"
          >
            Click here to view the current active Curve gauge vote
          </Button>
        </Typography>
        <TableContainer>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell
                  align="left"
                  className={classes.tableText}
                  style={{ borderBottom: "none" }}
                >
                  {isAuctionOver
                    ? "Depositing Period Ended"
                    : "Time Remaining for Deposits"}
                </TableCell>
                <TableCell align="right" style={{ borderBottom: "none" }}>
                  {
                    <Countdown
                      date={
                        (Math.floor(Date.now() / 1000 / (60 * 60 * 24 * 7)) *
                          (60 * 60 * 24 * 7) +
                          props.currentActiveAuction * 60 * 60 * 24 * 7) *
                        1000
                      }
                      renderer={renderer}
                    />
                  }
                </TableCell>
                <TableCell align="right" style={{ borderBottom: "none" }}>
                  <img
                    src={timeIcon}
                    className={classes.timeIcon}
                    alt="time-icon"
                  ></img>
                </TableCell>
              </TableRow>
              {isAuctionOver ? (
                <TableRow>
                  <TableCell
                    align="left"
                    className={classes.tableText}
                    style={{ borderBottom: "none" }}
                  >
                    {isVotingOver
                      ? "Depositing Period Ended"
                      : "Time Remaining to Vote"}
                  </TableCell>
                  <TableCell align="right" style={{ borderBottom: "none" }}>
                    {
                      <Countdown
                        date={
                          (Math.floor(Date.now() / 1000 / (60 * 60 * 24 * 7)) *
                            (60 * 60 * 24 * 7) +
                            props.currentActiveAuction +
                            1 * 60 * 60 * 24 * 7) *
                          1000
                        }
                        renderer={rendererVoting}
                      />
                    }
                  </TableCell>
                  <TableCell align="right" style={{ borderBottom: "none" }}>
                    <img
                      src={timeIcon}
                      className={classes.timeIcon}
                      alt="time-icon"
                    ></img>
                  </TableCell>
                </TableRow>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default ActiveAuctionCard;
