import alcx from "../../assets/tokens/alcx.png";
import alusd from "../../assets/tokens/alusd.png";
// import cream from "../../assets/tokens/cream.png";
import crv from "../../assets/tokens/crv.png";
import dai from "../../assets/tokens/dai.png";
import frax from "../../assets/tokens/frax.png";
import fxs from "../../assets/tokens/fxs.png";
import ldo from "../../assets/tokens/ldo.png";
import link from "../../assets/tokens/link.png";
import mim from "../../assets/tokens/mim.png";
import snx from "../../assets/tokens/snx.png";
import spell from "../../assets/tokens/spell.png";
import usdc from "../../assets/tokens/usdc.png";
import stusdt from "../../assets/tokens/stusdt.png";
// import usdn from "../../assets/tokens/usdn.png";
import weth from "../../assets/tokens/weth.png";
import yfi from "../../assets/tokens/yfi.png";
import ftm from "../../assets/tokens/ftm.png";
import matic from "../../assets/tokens/matic.png";
import badger from "../../assets/tokens/badger.png";
import threeCRV from "../../assets/tokens/3crv.png";
import cvxCRV from "../../assets/tokens/cvxCRV.png";
import eurs from "../../assets/tokens/eurs.png";
// import mta from "../../assets/tokens/mta.png";
// import musd from "../../assets/tokens/musd.png";
// import usdm from "../../assets/tokens/usdm.png";
import cvx from "../../assets/tokens/cvx.png";
// import geist from "../../assets/tokens/geist.png";
import tribe from "../../assets/tokens/tribe.png";
import fei from "../../assets/tokens/fei.png";
// import boring from "../../assets/tokens/boring.png";
// import wormholeLuna from "../../assets/tokens/wormholeLuna.png";
import origin from "../../assets/tokens/origin.png";
import angle from "../../assets/tokens/angle.png";
import rai from "../../assets/tokens/rai.png";
import flx from "../../assets/tokens/flx.png";
import threshold from "../../assets/tokens/threshold.png";
// import ust from "../../assets/tokens/ust.png";
// import gro from "../../assets/tokens/gro.png";
// import dfx from "../../assets/tokens/dfx.png";
// import kp3r from "../../assets/tokens/kp3r.png";
import inv from "../../assets/tokens/inv.png";
//import bean from "../../assets/tokens/bean.png";
import lft from "../../assets/tokens/lft.png";
// import pwrd from "../../assets/tokens/pwrd.png";
import lyra from "../../assets/tokens/lyra.png";
import jpeg from "../../assets/tokens/jpeg.png";
import stg from "../../assets/tokens/stg.png";
import ankr from "../../assets/tokens/ankr.png";
import usdd from "../../assets/tokens/usdd.png";
import tusd from "../../assets/tokens/tusd.png";
import gno from "../../assets/tokens/gno.png";
// import apefi from "../../assets/tokens/apefi.png";
// import multi from "../../assets/tokens/multi.png";
import clev from "../../assets/tokens/clev.png";
import cnc from "../../assets/tokens/cnc.png";
import ogv from "../../assets/tokens/ogv.png";
// import op from "../../assets/tokens/optimism.webp";
import eusd from "../../assets/tokens/eusd.png";
// import cfx from "../../assets/tokens/cfx.png";
import wbeth from "../../assets/tokens/wbeth.png";
import metronome from "../../assets/tokens/met.png";
import crvusd from "../../assets/tokens/crvusd.png";
import pyusd from "../../assets/tokens/pyusd.png";
import prisma from "../../assets/tokens/prisma.png";
import mkusd from "../../assets/tokens/mkusd.png";
import cvxprisma from "../../assets/tokens/cvxprisma.png";
import xeth from "../../assets/tokens/xeth.png";
import fxn from "../../assets/tokens/fxn.png";
import usdt from "../../assets/tokens/usdt.png";
import btrfly from "../../assets/tokens/btrfly.png";
import gho from "../../assets/tokens/gho.png";
import aave from "../../assets/tokens/aave.png";
import txjp from "../../assets/tokens/txjp.png";
import zeta from "../../assets/tokens/zeta.png";
import zunami from "../../assets/tokens/zunami.png";
import dinero from "../../assets/tokens/dinero.png";

export const tokenOptions = [
  {
    value: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    label: "WETH",
    img: <img src={weth} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x6b175474e89094c44da98b954eedeac495271d0f",
    label: "DAI",
    img: <img src={dai} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    label: "USDC",
    img: <img src={usdc} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 6,
  },
  {
    value: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    label: "USDT",
    img: <img src={usdt} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 6,
  },
  {
    value: "0x25eC98773D7b4ceD4cAFaB96A2A1c0945f145e10",
    label: "stUSDT",
    img: <img src={stusdt} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0xD533a949740bb3306d119CC777fa900bA034cd52",
    label: "CRV",
    img: <img src={crv} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f",
    label: "SNX",
    img: <img src={snx} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x5a98fcbea516cf06857215779fd812ca3bef1b32",
    label: "LDO",
    img: <img src={ldo} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  // {
  //   value: "0x2ba592f78db6436527729929aaf6c908497cb200",
  //   label: "CREAM",
  //   img: <img src={cream} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 18,
  // },
  // {
  //   value: "0x5f98805a4e8be255a32880fdec7f6728c6568ba0",
  //   label: "LUSD",
  //   img: <img src={lusd} alt="logo" width="100%" />,    chainId: 1,

  // },
  // {
  //   value: "0x674c6ad92fd080e4004b2312b45f796a192d27a0",
  //   label: "USDN",
  //   img: <img src={usdn} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 18,
  // },
  // {
  //   value: "0x1456688345527be1f37e9e627da0837d6f08c925",
  //   label: "USDP",
  //   img: <img src={usdp} alt="logo" width="100%" />,    chainId: 1,

  // },
  {
    value: "0xbc6da0fe9ad5f3b0d58160288917aa56653660e9",
    label: "alUSD",
    img: <img src={alusd} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0xdbdb4d16eda451d0503b854cf79d55697f90c8df",
    label: "ALCX",
    img: <img src={alcx} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e",
    label: "YFI",
    img: <img src={yfi} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x853d955acef822db058eb8505911ed77f175b99e",
    label: "FRAX",
    img: <img src={frax} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0",
    label: "FXS",
    img: <img src={fxs} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3",
    label: "MIM",
    img: <img src={mim} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x090185f2135308bad17527004364ebcc2d37e5f6",
    label: "SPELL",
    img: <img src={spell} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x514910771af9ca656af840dff83e8264ecf986ca",
    label: "LINK",
    img: <img src={link} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x4e15361fd6b4bb609fa63c81a2be19d873717870",
    label: "FTM",
    img: <img src={ftm} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
    label: "MATIC",
    img: <img src={matic} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x3472a5a71965499acd81997a54bba8d852c6e53d",
    label: "BADGER",
    img: <img src={badger} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x6c3f90f043a72fa612cbac8115ee7e52bde6e490",
    label: "3crv",
    img: <img src={threeCRV} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x62b9c7356a2dc64a1969e19c23e4f579f9810aa7",
    label: "cvxCRV",
    img: <img src={cvxCRV} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0xdb25f211ab05b1c97d595516f45794528a807ad8",
    label: "EURS",
    img: <img src={eurs} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 2,
  },
  // {
  //   value: "0xa3bed4e1c75d00fa6f4e5e6922db7261b5e9acd2",
  //   label: "MTA",
  //   img: <img src={mta} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 18,
  // },
  // {
  //   value: "0xe2f2a5c287993345a840db3b0845fbc70f5935a5",
  //   label: "MUSD",
  //   img: <img src={musd} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 18,
  // },
  // {
  //   value: "0x31d4Eb09a216e181eC8a43ce79226A487D6F0BA9",
  //   label: "USDM",
  //   img: <img src={usdm} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 18,
  // },
  {
    value: "0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b",
    label: "CVX",
    img: <img src={cvx} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
    label: "WFTM",
    img: <img src={ftm} alt="logo" width="100%" />,
    chainId: 250,
    decimals: 18,
  },
  // {
  //   value: "0xd8321aa83fb0a4ecd6348d4577431310a6e0814d",
  //   label: "GEIST",
  //   img: <img src={geist} alt="logo" width="100%" />,
  //   chainId: 250,
  //   decimals: 18,
  // },
  {
    value: "0xc7283b66Eb1EB5FB86327f08e1B5816b0720212B",
    label: "TRIBE",
    img: <img src={tribe} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x956F47F50A910163D8BF957Cf5846D573E7f87CA",
    label: "FEI",
    img: <img src={fei} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  // {
  //   value: "0xbc19712feb3a26080ebf6f2f7849b417fdd792ca",
  //   label: "BORING",
  //   img: <img src={boring} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 18,
  // },
  // {
  //   value: "0xbd31ea8212119f94a611fa969881cba3ea06fa3d",
  //   label: "LUNA",
  //   img: <img src={wormholeLuna} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 6,
  // },
  {
    value: "0x8207c1ffc5b6804f6024322ccf34f29c3541ae26",
    label: "OGN",
    img: <img src={origin} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x31429d1856aD1377A8A0079410B297e1a9e214c2",
    label: "ANGLE",
    img: <img src={angle} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  // {
  //   value: "0xfd05D3C7fe2924020620A8bE4961bBaA747e6305",
  //   label: "bveCVX",
  //   img: <img src={badger} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 18,
  // },
  {
    value: "0x03ab458634910aad20ef5f1c8ee96f1d6ac54919",
    label: "RAI",
    img: <img src={rai} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x6243d8cea23066d098a15582d81a598b4e8391f4",
    label: "FLX",
    img: <img src={flx} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0xCdF7028ceAB81fA0C6971208e83fa7872994beE5",
    label: "T",
    img: <img src={threshold} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  // {
  //   value: "0xa693B19d2931d498c5B318dF961919BB4aee87a5",
  //   label: "UST",
  //   img: <img src={ust} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 6,
  // },
  // {
  //   value: "0x3Ec8798B81485A254928B70CDA1cf0A2BB0B74D7",
  //   label: "GRO",
  //   img: <img src={gro} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 18,
  // },
  // {
  //   value: "0x888888435fde8e7d4c54cab67f206e4199454c60",
  //   label: "DFX",
  //   img: <img src={dfx} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 18,
  // },
  // {
  //   value: "0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44",
  //   label: "KP3R",
  //   img: <img src={kp3r} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 18,
  // },
  {
    value: "0x41D5D79431A913C4aE7d69a668ecdfE5fF9DFB68",
    label: "INV",
    img: <img src={inv} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0xE80C0cd204D654CEbe8dd64A4857cAb6Be8345a3",
    label: "JPEG",
    img: <img src={jpeg} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  // {
  //   value: "0xdc59ac4fefa32293a95889dc396682858d52e5db",
  //   label: "BEAN",
  //   img: <img src={bean} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 6,
  // },
  {
    value: "0xb620be8a1949aa9532e6a3510132864ef9bc3f82",
    label: "LFT",
    img: <img src={lft} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  // {
  //   value: "0xF0a93d4994B3d98Fb5e3A2F90dBc2d69073Cb86b",
  //   label: "PWRD",
  //   img: <img src={pwrd} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 18,
  // },
  {
    value: "0x01ba67aac7f75f647d94220cc98fb30fcc5105bf",
    label: "LYRA",
    img: <img src={lyra} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0xAf5191B0De278C7286d6C7CC6ab6BB8A73bA2Cd6",
    label: "STG",
    img: <img src={stg} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x8290333cef9e6d528dd5618fb97a76f268f3edd4",
    label: "ANKR",
    img: <img src={ankr} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  // {
  //   value: "0x2ebff165cb363002c5f9cbcfd6803957ba0b7208",
  //   label: "wGEIST",
  //   img: <img src={geist} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 18,
  // },
  {
    value: "0x466a756E9A7401B5e2444a3fCB3c2C12FBEa0a54",
    label: "PUSd",
    img: <img src={jpeg} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x0C10bF8FcB7Bf5412187A595ab97a3609160b5c6",
    label: "USDD",
    img: <img src={usdd} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x0000000000085d4780B73119b644AE5ecd22b376",
    label: "TUSD",
    img: <img src={tusd} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x6810e776880C02933D47DB1b9fc05908e5386b96",
    label: "GNO",
    img: <img src={gno} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  // {
  //   value: "0x4332f8A38f14BD3D8D1553aF27D7c7Ac6C27278D",
  //   label: "APEFI",
  //   img: <img src={apefi} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 18,
  // },
  // {
  //   value: "0x65Ef703f5594D2573eb71Aaf55BC0CB548492df4",
  //   label: "MULTI",
  //   img: <img src={multi} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 18,
  // },
  {
    value: "0x72953a5C32413614d24C29c84a66AE4B59581Bbf",
    label: "CLEV",
    img: <img src={clev} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x9aE380F0272E2162340a5bB646c354271c0F5cFC",
    label: "CNC",
    img: <img src={cnc} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x9c354503C38481a7A7a51629142963F98eCC12D0",
    label: "OGV",
    img: <img src={ogv} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0xA0d69E286B938e21CBf7E51D71F6A4c8918f482F",
    label: "eUSD",
    img: <img src={eusd} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  // {
  //   value: "0xA1f82E14bc09A1b42710dF1A8a999B62f294e592",
  //   label: "eCFX",
  //   img: <img src={cfx} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 18,
  // },
  {
    value: "0x402F878BDd1f5C66FdAF0fabaBcF74741B68ac36",
    label: "sdFXS",
    img: <img src={fxs} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0xa2E3356610840701BDf5611a53974510Ae27E2e1",
    label: "wBETH",
    img: <img src={wbeth} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x2Ebd53d035150f328bd754D6DC66B99B0eDB89aa",
    label: "MET",
    img: <img src={metronome} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0xf939E0A03FB07F59A73314E73794Be0E57ac1b4E",
    label: "crvUSD",
    img: <img src={crvusd} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x6c3ea9036406852006290770BEdFcAbA0e23A0e8",
    label: "PYUSD",
    img: <img src={pyusd} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 6,
  },
  {
    value: "0xda47862a83dac0c112ba89c6abc2159b95afd71c",
    label: "PRISMA",
    img: <img src={prisma} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x4591dbff62656e7859afe5e45f6f47d3669fbb28",
    label: "mkUSD",
    img: <img src={mkusd} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x34635280737b5bfe6c7dc2fc3065d60d66e78185",
    label: "cvxPrisma",
    img: <img src={cvxprisma} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0xe063F04f280c60aECa68b38341C2eEcBeC703ae2",
    label: "XETH",
    img: <img src={xeth} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x365accfca291e7d3914637abf1f7635db165bb09",
    label: "FXN",
    img: <img src={fxn} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0xc55126051b22ebb829d00368f4b12bde432de5da",
    label: "BTRFLY",
    img: <img src={btrfly} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x40d16fc0246ad3160ccc09b8d0d3a2cd28ae6c2f",
    label: "GHO",
    img: <img src={gho} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9",
    label: "AAVE",
    img: <img src={aave} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x961dd84059505d59f82ce4fb87d3c09bec65301d",
    label: "TXJP",
    img: <img src={txjp} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 8,
  },
  {
    value: "0xf091867EC603A6628eD83D274E835539D82e9cc8",
    label: "ZETA",
    img: <img src={zeta} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x6b5204B0Be36771253Cc38e88012E02B752f0f36",
    label: "ZUN",
    img: <img src={zunami} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },
  {
    value: "0x6DF0E641FC9847c0c6Fde39bE6253045440c14d3",
    label: "DINERO",
    img: <img src={dinero} alt="logo" width="100%" />,
    chainId: 1,
    decimals: 18,
  },

  //                       optimism tokens
  // {
  //   value: "0x4200000000000000000000000000000000000042",
  //   label: "OP",
  //   img: <img src={op} alt="logo" width="100%" />,
  //   chainId: 10,
  //   decimals: 18,
  // },
  //                       rinkeby tokens
  // {
  //   value: "0xc778417e063141139fce010982780140aa0cd5ab",
  //   label: "rinkeby WETH",
  //   img: <img src={weth} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 18,
  // },
  // {
  //   value: "0xc7ad46e0b8a400bb3c915120d284aafba8fc4735",
  //   label: "rDAI",
  //   img: <img src={dai} alt="logo" width="100%" />,
  //   chainId: 1,
  //   decimals: 18,
  // },
  // {
  //   value: "0xaFF4481D10270F50f203E0763e2597776068CBc5",
  //   label: "rWEENUS",
  //   img: <img src={weth} alt="logo" width="100%" />,
  // },
  // {
  //   value: "0x5592ec0cfb4dbc12d3ab100b257153436a1f0fea",
  //   label: "rDAI2",
  //   img: <img src={dai} alt="logo" width="100%" />,
  // },
];
