import React, { useEffect, useState } from "react";
import { Grid, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import votBox from "../../assets/votBoxBW.png";
import { useStylesDark, useStylesLight } from "../../Styles/FooterStyles";
import { theLlamasABI, theLlamasAddress } from "../../constants";
import { ethers } from "ethers";

const Footer = (props) => {
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  const [classes, setClasses] = useState(classesDark);
  const [llamaID, setLlamaId] = useState(undefined);
  const [llamaImage, setLlamaImage] = useState(undefined);
  // theme setting
  useEffect(() => {
    if (props.ctheme === "light") {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [props.ctheme]);

  //function to fetch llama of current address from contract
  const getLlama = async () => {
    let signer = await props.provider.getSigner();
    let llamaContractWithSigner = new ethers.Contract(
      theLlamasAddress,
      theLlamasABI,
      signer
    );
    const tx = await llamaContractWithSigner.tokensForOwner(props.address);
    if (tx[0] !== undefined) {
      const llamaID = tx[0];
      let llamaURI = await llamaContractWithSigner.tokenURI(llamaID);
      const response = await fetch(llamaURI);
      const metadata = await response.json();
      const imageUrl = metadata.image;
      console.log(imageUrl);
      setLlamaImage(imageUrl);
    }
  };

  useEffect(() => {
    if (props.address && props.provider) {
      getLlama();
    }
  }, [props.address]);

  return (
    <footer className={classes.footer}>
      <div className={classes.footerMargin} />
      <Grid container justify="center" className={classes.nameContainer}>
        <Grid container justify="center" className={classes.nameContainer}>
          <Hidden smDown>
            <Grid item className={classes.gridItem}>
              <Grid container direction="column" spacing={2}></Grid>
            </Grid>
          </Hidden>
          <Grid item className={classes.gridItem}>
            <Grid container direction="column" spacing={2}>
              <Grid item className={classes.title}>
                Community
              </Grid>
              <Grid
                item
                className={classes.link}
                component={Link}
                onClick={() => {
                  window.open("https://twitter.com/VotiumProtocol", "_blank");
                }}
              >
                Twitter
              </Grid>
              <Grid
                item
                className={classes.link}
                component={Link}
                onClick={() => {
                  window.open("https://discord.gg/j6UnnVsH8t", "_blank");
                }}
              >
                Discord
              </Grid>
              <Grid
                item
                className={classes.link}
                component={Link}
                onClick={() => {
                  window.open("https://votiumprotocol.medium.com/", "_blank");
                }}
              >
                Medium
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Grid container direction="column" spacing={2}>
              <Grid item className={classes.title}>
                Resources
              </Grid>
              <Grid
                item
                className={classes.link}
                component={Link}
                onClick={() => {
                  window.open("http://docs.votium.app", "_blank");
                }}
              >
                Documentation
              </Grid>
              <Grid
                item
                className={classes.link}
                component={Link}
                onClick={() => {
                  window.open("https://github.com/oo-00/Votium", "_blank");
                }}
              >
                Github
              </Grid>
              <Grid
                item
                className={classes.link}
                component={Link}
                onClick={() => {
                  window.open(
                    "https://llama.airforce/#/votium/bribes",
                    "_blank"
                  );
                }}
              >
                llama.airforce
              </Grid>
              <Grid item className={classes.link}></Grid>
            </Grid>
          </Grid>
        </Grid>
        {llamaImage !== undefined && (
          <Grid container justifyContent="flex-end">
            <Grid
              item
              style={{
                margin: "2em",
              }}
            >
              <img
                alt="votium logo"
                src={llamaImage}
                style={{
                  width: "10em",
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      <Hidden smDown>
        <img alt="votium logo" src={votBox} className={classes.footerLogo}/>
      </Hidden>
    </footer>
  );
};

export default Footer;
