/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ref, onValue } from "firebase/database";
import {
  Typography,
  Grid,
  Paper,
  Button,
  TextField,
  Modal,
  Fade,
  Backdrop,
} from "@material-ui/core";
import ClaimCard from "./ClaimCardCurve";
import votBox from "../../../assets/votBox.png";
import { ethers } from "ethers";
import { useStylesDark, useStylesLight } from "../../../Styles/ClaimPageStyles";
import { tokenOptions } from "../../Auction/tokenOptions";
import {
  merkleAddressEthereumCurve,
  merkleABI,
  addressRegistryABI,
  addressRegistryAddress,
} from "../../../constants";
var _ = require("lodash");

const ClaimPage = (props) => {
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  const [classes, setClasses] = useState(classesDark);
  const [displayClaims, setDisplayClaims] = useState();
  const [claimArray, setClaimArray] = useState([]);
  const [checkboxArray, setCheckboxArray] = useState([]);
  const [stringArray, setStringArray] = useState([]);
  const [filteredClaimArray, setFilteredClaimArray] = useState([]);
  const [claimButtonDisabled, setClaimButtonDisabled] = useState(true);
  const [claimText, setClaimText] = useState(
    "Select 2 or more tokens to claim simultaneously"
  );
  const [disableAllButtons, setDisableAllButtons] = useState(false);
  const [nothingToClaim, setNothingToClaim] = useState(undefined);
  const [nothingToClaimText, setNothingToClaimText] = useState(
    "You have no $veCRV rewards to claim"
  );
  const [refreshArray, setRefreshArray] = useState(false);
  const [forwardAddress, setForwardAdress] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [connectedNetwork, setConnectedNetwork] = useState(0);
  const [disableCancel, setDisableCancel] = useState(true);
  const [disableForward, setDisableForward] = useState(true);
  const [forwardedToText, setForwardedToText] = useState("");
  const [bothDisabledText, setBothDisabledText] = useState("");
  const [freezeClaims, setFreezeClaims] = useState(false);

  // check if claims are frozen in db
  useEffect(() => {
    const frozenRef = ref(
      props.db,
      "frozen"
    );
    onValue(frozenRef, (snapshot) => {
      if (snapshot.val() === true) {
        setFreezeClaims(true);
      } else {
        setFreezeClaims(false);
      }
    });
  }, [props.db]);

  // theme setting
  useEffect(() => {
    if (props.ctheme === 'light') {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [props.ctheme]);

  // check provider network
  useEffect(() => {
    if (props.provider !== undefined) {
      if (props.provider._network !== undefined) {
        setConnectedNetwork(props.provider._network.chainId);
        if (props.provider._network.chainId !== 1) {
          setDisableCancel(true);
          setDisableForward(true);
        } else {
          setDisableCancel(false);
          setDisableForward(false);
        }
      } else if (connectedNetwork > -100) {
        setConnectedNetwork(connectedNetwork - 1);
      }
    }
  }, [props.provider, connectedNetwork]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    let checkboxArray = [];
    tokenOptions.map(() => {
      checkboxArray.push(false);
    });
    setCheckboxArray(checkboxArray);
  }, []);

  useEffect(() => {
    let i = 0;
    let empty = 0;
    while (i < claimArray.length) {
      if (claimArray[i][0] === undefined) {
        empty++;
      } else {
        break;
      }
      i++;
    }
    if (empty === claimArray.length && claimArray.length !== 0) {
      setNothingToClaim(true);
    } else {
      if (props.provider) {
        setNothingToClaim(false);
      } else {
        setNothingToClaimText(
          "Please connect your wallet to check for claimable rewards"
        );
      }
    }
    if (claimArray.length !== tokenOptions.length) {
      setRefreshArray(!refreshArray);
    }
  }, [claimArray, props.provider, props.address, refreshArray]);

  const addToClaimArray = (res) => {
    let newClaimArray = claimArray;
    newClaimArray.push(res);
    setClaimArray(newClaimArray);
    setRefreshArray(!refreshArray);
  };

  const handleCheckboxChange = (i, bool) => {
    let newCheckboxArray = checkboxArray;
    newCheckboxArray[i] = bool;
    setCheckboxArray(newCheckboxArray);
    setStringArray(JSON.stringify(checkboxArray));
  };

  const handleClaimMulti = async () => {
    const signer = props.provider.getSigner();
    const merkleContract = new ethers.Contract(
      merkleAddressEthereumCurve,
      merkleABI,
      signer
    );
    try {
      const tx = await merkleContract.claimMulti(
        props.address,
        filteredClaimArray
      );
      await tx.wait();
    } catch {
      console.log("Couldn't claim all - try single claiming");
    }
  };

  // const handleClaimAll = async () => {
  //   const signer = props.provider.getSigner();
  //   const merkleContract = new ethers.Contract(
  //     merkleAddressEthereumCurve,
  //     merkleABI,
  //     signer
  //   );
  //   try {
  //     const tx = await merkleContract.claimMulti(props.address, claimArray);
  //     await tx.wait();
  //   } catch {
  //     console.log("Couldn't claim all");
  //   }
  // };

  //splice filtered claim array
  useEffect(() => {
    let claimArrayClone = claimArray;
    let selectedClaimArray = [];
    let i = 0;
    while (i < checkboxArray.length) {
      if (checkboxArray[i] === true) {
        selectedClaimArray.push(claimArrayClone[i]);
      }
      i++;
    }

    if (selectedClaimArray.length > 0) {
      console.log(selectedClaimArray);
    }
    //console.log(claimArrayClone);
    setFilteredClaimArray(selectedClaimArray);
    if (selectedClaimArray.length > 1) {
      setClaimButtonDisabled(false);
      setDisableAllButtons(true);
      setClaimText("Claim " + selectedClaimArray.length.toString() + " tokens");
    } else {
      setClaimButtonDisabled(true);
      //setDisableAllButtons(false);
      setClaimText(
        "Claim a reward, or select 2 or more tokens to claim at once"
      );
    }
  }, [stringArray, claimArray]);

  //get and display claims array
  useEffect(() => {
    let currIndex = -1;
    setDisplayClaims(
      tokenOptions.map((token) => {
        currIndex++;
        return (
          <ClaimCard
            token={tokenOptions[currIndex]}
            db={props.db}
            address={props.address}
            provider={props.provider}
            addToClaimArray={addToClaimArray}
            checkboxIndex={currIndex}
            handleCheckboxChange={handleCheckboxChange}
            disableButton={disableAllButtons}
            ctheme={props.ctheme}
          ></ClaimCard>
        );
      })
    );
  }, []);

  //check if address already forwarded
  useEffect(() => {
    async function checkForward() {
      const addressRegistryContract = new ethers.Contract(
        addressRegistryAddress,
        addressRegistryABI,
        props.provider
      );
      let res = await addressRegistryContract.registry(props.address);

      //check if forwarded
      if (
        res.start.toNumber() != 0 &&
        res.expiration.toNumber() > Math.floor(Date.now() / 1000)
      ) {
        setDisableCancel(false);
        setDisableForward(true);
        setForwardedToText(
          res.to.substring(0, 4) +
            "..." +
            res.to.substring(res.to.length - 2, res.to.length)
        );
      } else if (
        // check if they used setToExpire() (cant register new address)
        res.expiration.toNumber() <
        Math.floor(Date.now / 1000) + 60 * 60 * 24 * 14
      ) {
        setDisableCancel(true);
        setDisableForward(true);
        setBothDisabledText(
          "Must wait until next round for current registration to expire"
        );
      } else {
        setDisableCancel(true);
        setDisableForward(false);
      }
    }
    if (props.provider && props.address) {
      checkForward();
    }
  }, [props.provider, props.address]);

  const handleForward = async () => {
    const signer = props.provider.getSigner();
    const addressRegistryContract = new ethers.Contract(
      addressRegistryAddress,
      addressRegistryABI,
      signer
    );
    try {
      const tx = await addressRegistryContract.setRegistry(forwardAddress);
      await tx.wait();
    } catch {
      console.log("Couldn't forward");
    }
  };
  const handleCancel = async () => {
    const signer = props.provider.getSigner();
    const addressRegistryContract = new ethers.Contract(
      addressRegistryAddress,
      addressRegistryABI,
      signer
    );
    try {
      const tx = await addressRegistryContract.setToExpire();
      await tx.wait();
    } catch {
      console.log("Couldn't cancel");
    }
  };

  return (
    <>
      <Grid
        container
        direction="column"
        className={classes.auctionsContainer}
        justifyContent="center"
      >
        {freezeClaims ? (
          <>
            <Paper className={classes.auctionCard}>
              <Grid container direction="column" justifyContent="center">
                <Grid item>
                  <Typography className={classes.noActiveAuctionTitle}>
                    Claims are currently being updated and will be posted
                    shortly
                  </Typography>
                </Grid>
                <Grid item>
                  <img
                    src={votBox}
                    alt="time-icon"
                    className={classes.votBox}
                  ></img>
                </Grid>
              </Grid>
            </Paper>
          </>
        ) : (
          <>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              display="flex"
              className={classes.claimContainer}
            >
              <Grid item>
                <Button
                  onClick={handleClaimMulti}
                  classes={{
                    root: classes.submitButton,
                    disabled: classes.submitButtonDisabled,
                  }}
                  disabled={claimButtonDisabled}
                >
                  {claimText}
                </Button>
              </Grid>
            </Grid>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {displayClaims}
            </div>
            {nothingToClaim === true && (
              <Paper className={classes.auctionCard}>
                <Grid container direction="column" justifyContent="center">
                  <Grid item>
                    <Typography className={classes.noActiveAuctionTitle}>
                      {nothingToClaimText}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <img
                      src={votBox}
                      alt="time-icon"
                      className={classes.votBox}
                    ></img>
                  </Grid>
                </Grid>
              </Paper>
            )}
            <Grid item align="center">
              <Button
                classes={{
                  root: classes.delegateButton,
                  disabled: classes.delegateButtonDisabled,
                }}
                disableRipple
                onClick={handleOpenModal}

                //disabled={connectedNetwork !== 1}
              >
                Address Forwarding
              </Button>
              <Modal
                className={classes.modal}
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={openModal}>
                  <div className={classes.modalPaper}>
                    <Grid container direction="column" alignItems="center">
                      <Grid item>
                        <Typography className={classes.modalText}>
                          The following transaction will let you forward your
                          future rewards to be claimable by another address.
                        </Typography>
                      </Grid>
                      <Grid item>
                        <TextField
                          className={classes.modalInput}
                          label={"Recepient Address"}
                          variant="outlined"
                          value={forwardAddress}
                          onChange={(e) => {
                            setForwardAdress(e.target.value);
                          }}
                        ></TextField>
                      </Grid>
                      <Grid container direction="row" justifyContent="center">
                        <Grid item>
                          <Button
                            classes={{
                              root: classes.modalDelegateButton,
                              disabled: classes.modalDelegateButtonDisabled,
                            }}
                            disableRipple
                            onClick={handleForward}
                            disabled={disableForward}
                          >
                            Confirm new address
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            classes={{
                              root: classes.modalDelegateButton,
                              disabled: classes.modalDelegateButtonDisabled,
                            }}
                            disableRipple
                            onClick={handleCancel}
                            disabled={disableCancel}
                          >
                            Cancel existing forwarding
                          </Button>
                        </Grid>
                      </Grid>
                      {forwardedToText !== "" && (
                        <Grid item>
                          <Typography>
                            {"Currently forwarded to: " + forwardedToText}
                          </Typography>
                        </Grid>
                      )}
                      {bothDisabledText !== "" && (
                        <Grid item>
                          <Typography>{bothDisabledText}</Typography>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                </Fade>
              </Modal>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default ClaimPage;
