/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Button,
  TextField,
  Modal,
  Fade,
  Backdrop,
} from "@material-ui/core";
import { MdContentCopy } from "react-icons/md";
import ClaimCard from "./ClaimCard";
import votBox from "../../assets/votBox.png";
import { ethers } from "ethers";
import { useStylesDark, useStylesLight } from "../../Styles/ClaimPageStyles";
import { tokenOptions } from "../Auction/tokenOptions";
import { ref, onValue } from "firebase/database";
import {
  merkleAddressEthereum,
  merkleABI,
  addressRegistryABI,
  addressRegistryAddress,
} from "../../constants";
import AccountDetails from "./AccountDetails";
import UserIncentivesAccordion from "../Auction/Components/UserIncentivesAccordion";
var _ = require("lodash");

const ClaimPage = (props) => {
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  const [classes, setClasses] = useState(classesDark);
  const [displayClaims, setDisplayClaims] = useState();
  const [claimArray, setClaimArray] = useState([]);
  const [checkboxArray, setCheckboxArray] = useState([]);
  const [stringArray, setStringArray] = useState([]);
  const [filteredClaimArray, setFilteredClaimArray] = useState([]);
  const [claimButtonDisabled, setClaimButtonDisabled] = useState(true);
  const [claimText, setClaimText] = useState(
    "Select 2 or more tokens to claim simultaneously"
  );
  const [disableAllButtons, setDisableAllButtons] = useState(false);
  const [nothingToClaim, setNothingToClaim] = useState(undefined);
  const [nothingToClaimText, setNothingToClaimText] = useState(
    "You have no $vlCVX rewards to claim"
  );
  const [refreshArray, setRefreshArray] = useState(false);
  const [connectedNetwork, setConnectedNetwork] = useState(0);
  const [connected, setConnected] = useState(false);
  const [forwardedToText, setForwardedToText] = useState("");
  const [freezeClaims, setFreezeClaims] = useState(false);

  // theme setting
  useEffect(() => {
    if (props.ctheme === "light") {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [props.ctheme]);

  // check if claims are frozen in db
  useEffect(() => {
    const frozenRef = ref(props.db, "frozen");
    onValue(frozenRef, (snapshot) => {
      if (snapshot.val() === true) {
        setFreezeClaims(true);
      } else {
        setFreezeClaims(false);
      }
    });
  }, [props.db]);

  // check provider network
  useEffect(() => {
    if (props.provider !== undefined) {
      if (props.provider._network !== undefined) {
        setConnectedNetwork(props.provider._network.chainId);
        if (props.provider._network.chainId !== 1) {
          setConnected(false);
        } else {
          setConnected(true);
        }
      } else if (connectedNetwork > -200) {
        setConnectedNetwork(connectedNetwork - 1);
      }
    }
  }, [props.provider, connectedNetwork]);

  useEffect(() => {
    let checkboxArray = [];
    tokenOptions.map(() => {
      checkboxArray.push(false);
    });
    setCheckboxArray(checkboxArray);
  }, []);

  useEffect(() => {
    let i = 0;
    let empty = 0;
    while (i < claimArray.length) {
      if (claimArray[i][0] === undefined) {
        empty++;
      } else {
        break;
      }
      i++;
    }
    if (empty === claimArray.length && claimArray.length !== 0) {
      setNothingToClaim(true);
    } else {
      if (props.provider) {
        setNothingToClaim(false);
      } else {
        setNothingToClaim(true);
        setNothingToClaimText(
          "Please connect your wallet to see your Votium information and claimable rewards"
        );
      }
    }
    if (claimArray.length !== tokenOptions.length) {
      setRefreshArray(!refreshArray);
    }
  }, [claimArray, props.provider, props.address, refreshArray]);

  const addToClaimArray = (res) => {
    let newClaimArray = claimArray;
    newClaimArray.push(res);
    setClaimArray(newClaimArray);
    setRefreshArray(!refreshArray);
  };

  const handleCheckboxChange = (i, bool) => {
    let newCheckboxArray = checkboxArray;
    newCheckboxArray[i] = bool;
    setCheckboxArray(newCheckboxArray);
    setStringArray(JSON.stringify(checkboxArray));
  };

  const handleClaimMulti = async () => {
    const signer = props.provider.getSigner();
    const merkleContract = new ethers.Contract(
      merkleAddressEthereum,
      merkleABI,
      signer
    );
    try {
      const tx = await merkleContract.claimMulti(
        props.address,
        filteredClaimArray
      );
      await tx.wait();
    } catch {
      console.log("Couldn't claim all - try single claiming");
    }
  };

  //splice filtered claim array
  useEffect(() => {
    let claimArrayClone = claimArray;
    let selectedClaimArray = [];
    let i = 0;
    while (i < checkboxArray.length) {
      if (checkboxArray[i] === true) {
        selectedClaimArray.push(claimArrayClone[i]);
      }
      i++;
    }

    if (selectedClaimArray.length > 0) {
      console.log(selectedClaimArray);
    }
    //console.log(claimArrayClone);
    setFilteredClaimArray(selectedClaimArray);
    if (selectedClaimArray.length > 1) {
      setClaimButtonDisabled(false);
      setDisableAllButtons(true);
      setClaimText("Claim " + selectedClaimArray.length.toString() + " tokens");
    } else {
      setClaimButtonDisabled(true);
      //setDisableAllButtons(false);
      setClaimText(
        "Claim a reward, or select 2 or more tokens to claim at once"
      );
    }
  }, [stringArray, claimArray]);

  //get and display claims array
  useEffect(() => {
    let currIndex = -1;
    setDisplayClaims(
      tokenOptions.map((token) => {
        currIndex++;
        return (
          <ClaimCard
            token={tokenOptions[currIndex]}
            db={props.db}
            address={props.address}
            provider={props.provider}
            addToClaimArray={addToClaimArray}
            checkboxIndex={currIndex}
            handleCheckboxChange={handleCheckboxChange}
            disableButton={disableAllButtons}
            ctheme={props.ctheme}
          ></ClaimCard>
        );
      })
    );
  }, []);

  return (
    <>
      <Grid
        container
        direction="column"
        className={classes.auctionsContainer}
        justifyContent="center"
      >
        {freezeClaims ? (
          <>
            <Paper className={classes.auctionCard}>
              <Grid container direction="column" justifyContent="center">
                <Grid item>
                  <Typography className={classes.noActiveAuctionTitle}>
                    Claims are currently being updated and will be posted
                    shortly
                  </Typography>
                </Grid>
                <Grid item>
                  <img
                    src={votBox}
                    alt="time-icon"
                    className={classes.votBox}
                  ></img>
                </Grid>
              </Grid>
            </Paper>
          </>
        ) : (
          <>
            <>
              <AccountDetails
                ctheme={props.ctheme}
                forwardedToText={forwardedToText}
                db={props.db}
                address={props.address}
                provider={props.provider}
              />
              <UserIncentivesAccordion
                ctheme={props.ctheme}
                db={props.db}
                address={props.address}
                provider={props.provider}
              />
              <span className={classes.userIncentivesAccordion}></span>
              {nothingToClaim === false && (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  className={classes.claimContainer}
                >
                  <Grid item>
                    <Button
                      onClick={handleClaimMulti}
                      classes={{
                        root: classes.submitButton,
                        disabled: classes.submitButtonDisabled,
                      }}
                      disabled={claimButtonDisabled}
                    >
                      {claimText}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>

            <div style={{ display: "flex", flexDirection: "column" }}>
              {displayClaims}
            </div>
            {nothingToClaim === true && (
              <Paper className={classes.auctionCard}>
                <Grid container direction="column" justifyContent="center">
                  <Grid item style={{ margin: "1em" }}>
                    <Typography className={classes.noActiveAuctionText}>
                      {nothingToClaimText}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default ClaimPage;
