/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Fade,
  Backdrop,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import { NumericFormat } from "react-number-format";
import { tokenOptions } from "../tokenOptions";
import { ethers } from "ethers";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { onValue, ref } from "firebase/database";
import EditIcon from "@material-ui/icons/EditOutlined";
import { depositAddressEthereum, depositABI } from "../../../constants";
import {
  useStylesDark,
  useStylesLight,
} from "../../../Styles/AuctionPageStyles";

const specialColorDark = {
  style: { color: "rgb(232, 230, 227)" }, // Set the color of the text input in the TextField to white (#fff)
};

const UserIncentivesAccordion = (props) => {
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  var [classes, setClasses] = useState(classesDark);
  const [currDepositAddress, setCurrDepositAddress] = useState(
    depositAddressEthereum
  );
  const [displayMyIncentives, setDisplayMyIncentives] = useState(false);
  const [increaseIncentiveText, setIncreaseIncentiveText] =
    useState("Increase Reward");
  const [increaseButtonDisabled, setIncreaseButtonDisabled] = useState(false);
  const [userDeposits, setUserDeposits] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [newRewardAmount, setNewRewardAmount] = useState(0);
  const [newMaxPerVote, setNewMaxPerVote] = useState(0);
  const [originalMaxPerVote, setOriginalMaxPerVote] = useState(0);
  const [gaugeAddress, setGaugeAddress] = useState(undefined);
  const [currentDeposit, setCurrentDeposit] = useState(undefined);

  let round = Math.floor(Math.floor(Date.now() / 1000) / (86400 * 14)) - 1348;
  const location = window.location.href;
  const suffix = location.split("/").pop();
  if (
    (round + 1348) * 86400 * 14 + 60 * 60 * 24 * 4 + 60 * 60 * 18 <
    Math.floor(Date.now() / 1000)
  ) {
    round++;
  }
  if (suffix === "prisma") {
    round = round - 57;
  }
  if (suffix === "fxn") {
    round = round - 65;
  }

  // theme setting
  useEffect(() => {
    if (props.ctheme === "light") {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [props.ctheme]);

  //get user deposits from db
  useEffect(() => {
    async function getInfoFromDatabase(market) {
      if (props.db && props.address) {
        try {
          const userDepositsRef = ref(
            props.db,
            market + "/userDeposits/" + props.address.toLowerCase()
          );
          onValue(userDepositsRef, (snapshot) => {
            let data = snapshot.val();
            if (data !== null && data !== undefined) {
              setUserDeposits(data);
              setDisplayMyIncentives(true);
            } else {
              setDisplayMyIncentives(false);
              console.log("Couldn't get incentives from db / This user didn't deposit incentives");
            }
          });
        } catch {
          console.log("Couldn't get info from database");
        }
      }
    }
    const location = window.location.href;
    const suffix = location.split("/").pop();
    let market = "v2";
    if (suffix === "prisma") {
      market = "v2prisma";
    }
    if (suffix === "fxn") {
      market = "v2fxn";
    }
    getInfoFromDatabase(market);
  }, [props.db, props.address]);

  useEffect(() => {
    if (userDeposits.length !== 0) {
      const isEmpty = Object.entries(userDeposits).flatMap(
        ([round, networks]) =>
          Object.entries(networks).flatMap(([network, addresses]) =>
            Object.entries(addresses).flatMap(([address, deposits]) =>
              deposits.filter(
                (deposit) =>
                  !(
                    Number(deposit.distributed) > 0 ||
                    Number(deposit.recycled) > 0 ||
                    Number(deposit.amount) === 0
                  )
              )
            )
          )
      );
      isEmpty.length === 0
        ? setDisplayMyIncentives(false)
        : setDisplayMyIncentives(true);
    }
  }, [userDeposits]);

  const handleOpenModal = (currentDeposit, gaugeAddress) => {
    setCurrentDeposit(currentDeposit);
    setOpenModal(true);
    setNewRewardAmount(0);
    setNewMaxPerVote(
      ethers.utils.formatUnits(
        currentDeposit.maxPerVote,
        currentDeposit.decimals
      )
    );
    setOriginalMaxPerVote(
      ethers.utils.formatUnits(
        currentDeposit.maxPerVote,
        currentDeposit.decimals
      )
    );
    setGaugeAddress(gaugeAddress);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const getTokenImage = (tokenAddress) => {
    if (!tokenAddress) return null;
    const token = tokenOptions.find(
      (option) => option.value.toLowerCase() === tokenAddress.toLowerCase()
    );
    return token
      ? React.cloneElement(token.img, {
          style: { width: "24px", borderRadius: "50%" },
          title: token.label,
        })
      : null;
  };

  const getTokenLabel = (tokenAddress) => {
    if (!tokenAddress) return null;
    const token = tokenOptions.find(
      (option) => option.value.toLowerCase() === tokenAddress.toLowerCase()
    );
    return token ? token.label : null;
  };

  const handleMaxPerVoteChange = (value) => {
    setNewMaxPerVote(value.floatValue);
    (value.floatValue < originalMaxPerVote || value.floatValue === undefined) &&
    value.floatValue !== 0
      ? setIncreaseButtonDisabled(true)
      : setIncreaseButtonDisabled(false);
  };

  const handleIncreaseIncentive = async (
    round,
    gaugeAddress,
    newRewardAmount,
    newMaxPerVote
  ) => {
    setIncreaseIncentiveText(<CircularProgress size={18} />);
    setIncreaseButtonDisabled(true);
    try {
      let signer = await props.provider.getSigner();
      let depositContractWithSigner = new ethers.Contract(
        currDepositAddress,
        depositABI,
        signer
      );
      console.log(
        round,
        gaugeAddress,
        currentDeposit.index,
        ethers.utils.parseUnits(
          newRewardAmount.toString(),
          currentDeposit.decimals
        ),
        ethers.utils.parseUnits(
          newMaxPerVote.toString(),
          currentDeposit.decimals
        )
      );
      const tx = await depositContractWithSigner.increaseIncentive(
        round,
        gaugeAddress,
        currentDeposit.index,
        ethers.utils.parseUnits(
          newRewardAmount.toString(),
          currentDeposit.decimals
        ),
        ethers.utils.parseUnits(
          newMaxPerVote.toString(),
          currentDeposit.decimals
        )
      );
      await tx.wait();
      setIncreaseIncentiveText("Reward Increased Successfully");
    } catch (error) {
      console.error(error);
      setIncreaseIncentiveText("Increase Reward");
      setIncreaseButtonDisabled(false);
    }
  };

  return (
    displayMyIncentives && (
      <Accordion className={classes.incentivesAccordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.arrowButton} />}
        >
          <Typography align="center" style={{ marginLeft: "15.9em" }}>
            My Incentives
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper} className={classes.tablePaper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableSubtitle}>Round</TableCell>{" "}
                  <TableCell className={classes.tableSubtitle}>Pool</TableCell>
                  <TableCell className={classes.tableSubtitle}>
                    Deposited Rewards
                  </TableCell>
                  <TableCell className={classes.tableSubtitle}>
                    Max per Vote
                  </TableCell>
                  <TableCell align="right" className={classes.tableSubtitle}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userDeposits !== null && userDeposits !== undefined && (
                  <>
                    {Object.entries(userDeposits).map(([round, networks]) => {
                      return Object.entries(networks).flatMap(
                        ([network, addresses]) =>
                          Object.entries(addresses).flatMap(
                            ([address, deposits]) =>
                              deposits
                                .filter(
                                  (deposit) =>
                                    !(
                                      Number(deposit.distributed) > 0 ||
                                      Number(deposit.recycled) > 0 ||
                                      Number(deposit.amount) === 0
                                    )
                                )
                                .map((deposit) => (
                                  <>
                                    <TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          borderBottom: "none",
                                        },
                                      }}
                                    >
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        className={classes.tableText}
                                      >
                                        {round}
                                      </TableCell>{" "}
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        className={classes.tableText}
                                      >
                                        {deposit.pool.slice(0, -10) + "..."}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        className={classes.tableText}
                                      >
                                        <Grid
                                          container
                                          direction="row"
                                          alignItems="center"
                                        >
                                          <Grid
                                            item
                                            style={{
                                              marginBottom: "-0.3em",
                                              marginRight: "0.25em",
                                            }}
                                          >
                                            {" "}
                                            {getTokenImage(deposit.token)}
                                          </Grid>
                                          <Grid item>
                                            &nbsp;
                                            {(
                                              ethers.utils.formatUnits(
                                                deposit.amount,
                                                deposit.decimals
                                              ) / 0.96
                                            ).toLocaleString()}
                                            &nbsp;
                                          </Grid>
                                          <Grid item>
                                            {" "}
                                            {getTokenLabel(deposit.token)}
                                          </Grid>
                                        </Grid>
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        className={classes.tableText}
                                      >
                                        {deposit.maxPerVote === "0"
                                          ? "N/A"
                                          : ethers.utils
                                              .formatUnits(
                                                deposit.maxPerVote,
                                                deposit.decimals
                                              )
                                              .toLocaleString()}
                                      </TableCell>
                                      <TableCell
                                        component="th"
                                        scope="row"
                                        className={classes.tableText}
                                      >
                                        <EditIcon
                                          style={{
                                            color: "grey",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            handleOpenModal(deposit, address);
                                          }}
                                        />
                                        <Modal
                                          className={classes.forwardModal}
                                          open={openModal}
                                          onClose={handleCloseModal}
                                          closeAfterTransition
                                          BackdropComponent={Backdrop}
                                          BackdropProps={{
                                            timeout: 500,
                                          }}
                                        >
                                          <Fade in={openModal}>
                                            <div
                                              className={
                                                classes.forwardModalPaper
                                              }
                                            >
                                              <Grid
                                                container
                                                direction="column"
                                                alignItems="center"
                                              >
                                                <Grid item>
                                                  <Typography
                                                    className={
                                                      classes.modalText
                                                    }
                                                  >
                                                    <p>
                                                      {" "}
                                                      You can increase your
                                                      reward{" "}
                                                      {originalMaxPerVote !==
                                                        "0.0" &&
                                                        "or reward per vote"}{" "}
                                                      amount for this gauge:
                                                    </p>
                                                  </Typography>
                                                  <Typography
                                                    className={
                                                      classes.modalText
                                                    }
                                                  >
                                                    <p>
                                                      {currentDeposit !==
                                                        undefined &&
                                                        currentDeposit.pool}
                                                    </p>
                                                  </Typography>
                                                </Grid>
                                                <Grid item>
                                                  <NumericFormat
                                                    customInput={TextField}
                                                    className={
                                                      classes.modalInput
                                                    }
                                                    label={"Rewards to add"}
                                                    variant="outlined"
                                                    type="text"
                                                    value={0}
                                                    onValueChange={(value) => {
                                                      setNewRewardAmount(
                                                        value.floatValue
                                                      );
                                                    }}
                                                    NumericString
                                                    thousandSeparator
                                                    InputProps={{
                                                      startAdornment: (
                                                        <InputAdornment position="start">
                                                          <div
                                                            style={{
                                                              width: "30px",
                                                              marginBottom:
                                                                "-0.3em",
                                                            }}
                                                          >
                                                            {getTokenImage(
                                                              deposit.token
                                                            )}
                                                          </div>
                                                        </InputAdornment>
                                                      ),
                                                      classes: {
                                                        root: classes.inputRoot,
                                                      },
                                                    }}
                                                    InputLabelProps={{
                                                      className: classes.label,
                                                    }}
                                                    FormHelperTextProps={{
                                                      className:
                                                        classes.helperText,
                                                    }}
                                                  />{" "}
                                                </Grid>
                                                {originalMaxPerVote !==
                                                  "0.0" && (
                                                  <Grid
                                                    item
                                                    style={{
                                                      marginTop: "1em",
                                                    }}
                                                  >
                                                    <NumericFormat
                                                      customInput={TextField}
                                                      error={
                                                        newMaxPerVote ===
                                                          undefined ||
                                                        (newMaxPerVote <
                                                          originalMaxPerVote &&
                                                          newMaxPerVote !== 0)
                                                      }
                                                      helperText={
                                                        (newMaxPerVote <
                                                          originalMaxPerVote ||
                                                          newMaxPerVote ===
                                                            undefined) &&
                                                        newMaxPerVote !== 0 &&
                                                        "New max/vote amount can't be lower than current (" +
                                                          originalMaxPerVote +
                                                          ")"
                                                      }
                                                      className={
                                                        classes.modalInput
                                                      }
                                                      label={
                                                        "New Max $" +
                                                        getTokenLabel(
                                                          deposit.token
                                                        ) +
                                                        " Per Vote"
                                                      }
                                                      variant="outlined"
                                                      type="text"
                                                      value={originalMaxPerVote}
                                                      onValueChange={(
                                                        value
                                                      ) => {
                                                        handleMaxPerVoteChange(
                                                          value
                                                        );
                                                      }}
                                                      NumericString
                                                      thousandSeparator
                                                      InputProps={{
                                                        startAdornment: (
                                                          <InputAdornment position="start">
                                                            <div
                                                              style={{
                                                                width: "30px",
                                                                marginBottom:
                                                                  "-0.3em",
                                                              }}
                                                            >
                                                              {getTokenImage(
                                                                deposit.token
                                                              )}
                                                            </div>
                                                          </InputAdornment>
                                                        ),
                                                        classes: {
                                                          root: classes.inputRoot,
                                                        },
                                                      }}
                                                      InputLabelProps={{
                                                        className:
                                                          classes.label,
                                                      }}
                                                      FormHelperTextProps={
                                                        specialColorDark
                                                      }
                                                    />
                                                  </Grid>
                                                )}{" "}
                                              </Grid>

                                              <Grid
                                                container
                                                direction="column"
                                                alignItems="center"
                                              >
                                                {originalMaxPerVote !==
                                                  "0.0" && (
                                                  <Grid item>
                                                    <Typography
                                                      className={
                                                        classes.modalTextSmall
                                                      }
                                                    >
                                                      To disable the limit, set
                                                      New Max $
                                                      {getTokenLabel(
                                                        deposit.token
                                                      )}{" "}
                                                      Per Vote to 0
                                                    </Typography>
                                                  </Grid>
                                                )}
                                                <Grid item>
                                                  <Button
                                                    disabled={
                                                      increaseButtonDisabled
                                                    }
                                                    classes={{
                                                      root: classes.modalDelegateButton,
                                                      disabled:
                                                        classes.modalDelegateButtonDisabled,
                                                    }}
                                                    onClick={() => {
                                                      handleIncreaseIncentive(
                                                        round,
                                                        gaugeAddress,
                                                        newRewardAmount,
                                                        newMaxPerVote
                                                      );
                                                    }}
                                                  >
                                                    {increaseIncentiveText}
                                                  </Button>
                                                </Grid>
                                              </Grid>
                                            </div>
                                          </Fade>
                                        </Modal>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                ))
                          )
                      );
                    })}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    )
  );
};
export default UserIncentivesAccordion;
