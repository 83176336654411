import { makeStyles } from "@material-ui/core";
import { fontWeight } from "@material-ui/system";

export const useStylesDark = makeStyles((theme) => ({
  wrapper: {
    justifyContent: "center",
  },
  paper: {
    width: "15%",
    margin: "2em",
    backgroundColor: "#141E27",
    [theme.breakpoints.down("md")]: {
      width: "22%",
    },
  },
  text: {
    marginTop: "0.3em",
    textAlign: "center",
    fontFamily: "Rubik",
    color: theme.palette.common.grey,
  },
  logoContainer: {},
  cvxLogo: {
    width: "3em",
    margin: "0.3em",
  },
  auctionsContainer: {
    textAlign: "center",
    justifyContent: "center",
    zIndex: 1000,
    alignItems: "center",
    align: "center",
  },
  auctionCard: {
    margin: "1em",
    marginBottom: "9.5em",
    marginTop: "-2.5em",
    zIndex: 1,
    width: "25em",
    background: "rgb(34 36 38)",
  },
  accountDetailsCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "1em",
    width: "40em",
    zIndex: 1,
    paddingTop: "0.6em",
    marginTop: "-4em",
    paddingBottom: "1em",
    background: "rgb(34 36 38)",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  userIncentivesAccordion: {
    marginBottom: "8em",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "12em",
    },
  },
  noActiveAuctionTitle: {
    fontSize: "1.5em",
    marginTop: "0.2em",
    color: "rgb(232, 230, 227)",
  },
  noActiveAuctionText: {
    fontSize: "1em",
    color: "grey",
  },
  timeText: {
    fontSize: "1.3em",
    marginTop: "1.05em",
    color: "grey",
    textAlign: "center",
  },
  tab: {
    margin: "auto",
    display: "block",
    textAlign: "center",
  },
  uniSpace: {
    maxWidth: "15%",
    marginBottom: "-0.4em",
  },
  cvxSpace: {
    maxWidth: "15%",
  },
  crvSpace: {
    maxWidth: "8%",
    marginTop: "0.5em",
    marginBottom: "0.6em",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  arrowButton: {
    color: "white",
  },
  votBox: {
    width: "3em",
    margin: "1em",
  },
  accordion: {
    width: "40em",
  },
  activeContainer: {
    alignItems: "center",
  },
  arrowsContainer: {
    marginBottom: "4em",
    marginTop: "-6em",
  },
  votTokenInput: {
    width: "2em",
  },
  claimContainer: {
    marginTop: "-10.5em",
    marginBottom: "4em",
  },
  submitButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "#141E27",
    color: theme.palette.common.lgrey,
  },
  submitButtonDisabled: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    background: "rgb(34 36 38)",
    color: "rgb(232, 230, 227) !important",
  },
  tableTextBottom: {
    borderBottom: "none",
  },
  orText: {
    color: "grey !important",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-25em",
  },
  modalPaper: {
    backgroundColor: "#070A0E",
    borderRadius: "3px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: "45em",
    width: "20em",
    alignItems: "center",
  },
  forwardModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-15em",
  },
  forwardModalPaper: {
    backgroundColor: "#070A0E",
    borderRadius: "3px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: "45em",
    width: "50em",
    alignItems: "center",
  },
  modalTitle: {
    fontSize: "1em",
    fontWeight: "700",
    marginTop: "-1em",
  },
  modalDelegateButton: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    margin: "1em",
    background: "#141e27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    height: "3.7em",
  },
  modalDelegateButtonDisabled: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    margin: "1em",
    background: "#cccecf",
    borderRadius: "5px",
    color: "white",
    height: "3.7em",
  },
  modalText: {
    textAlign: "center",
    margin: "1em",
    color: theme.palette.common.grey,
    fontWeight: "400",
  },
  modalClaimButton: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "70px",
    background: "#141E27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    height: "3em",
    padding: "1em",
    left: "35%",
    marginTop: "1.5em",
    marginBottom: "-0.1em",
  },
  modalClaimButtonDisabled: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "70px",
    background: "#cccecf",
    borderRadius: "5px",
    height: "3em",
    padding: "1em",
    left: "28%",
    marginTop: "1.5em",
    marginBottom: "-0.1em",
  },
  modalInput: {
    width: "30em",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgb(216 212 207)", // Change the border color to white (#fff)
      },
      "&:hover fieldset": {
        borderColor: "rgb(216 212 207)", // Change the border color to white (#fff) on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(216 212 207)", // Change the border color to white (#fff) when focused
      },
    },
  },
  delegateButton: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    marginLeft: "1em",
    marginTop: "-0.3em",
    background: "#141E27",
    borderRadius: "5px",
    color: "white",
    height: "3.7em",
  },
  delegateButtonDisabled: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    marginLeft: "1em",
    marginTop: "-0.3em",
    background: "#141E27",
    borderRadius: "5px",
    color: "#BEBEBE !important",
    height: "3.7em",
  },
  faqButton: {
    color: theme.palette.common.grey,
    fontSize: "0.7em",
    textTransform: "none",
    background: "#141e27",
    margin: "1em",
  },
  infoIcon: {
    color: "grey",
    marginBottom: "-0.3em",
    zIndex: 100,
  },
  unconsumedDepositsText: { color: "white", margin: "1em" },
  infoTablePaper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "1em",
    width: "30em",
    zIndex: 1,
    marginTop: "-10em",
    background: "rgb(34 36 38)",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "12em",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  infoTable: {
    width: "100%",
    background: "rgb(34 36 38)",
    marginBottom: "1em",
  },
  tableText: { fontSize: "1em", color: "grey", fontWeight: "500" },
  tableSubText: { fontSize: "1em", color: "grey", fontWeight: "400" },
  icon: { width: "1em", marginRight: "0.4em", marginBottom: "-0.1em" },
  snapshotIcon: {
    width: "1em",
    marginLeft: "0.4em",
    marginBottom: "-0.1em",
    color: "#f3b04e",
  },
  editButton: {
    cursor: "pointer",
    width: "0.7em",
    marginBottom: "-0.2em",
    marginLeft: "0.1em",
  },
  copyButton: { minWidth: "0.1em", color: "white" },
}));

export const useStylesLight = makeStyles((theme) => ({
  wrapper: {
    justifyContent: "center",
  },
  paper: {
    width: "15%",
    margin: "2em",
    backgroundColor: "#141E27",
    [theme.breakpoints.down("md")]: {
      width: "22%",
    },
  },
  text: {
    marginTop: "0.3em",
    textAlign: "center",
    fontFamily: "Rubik",
    color: theme.palette.common.grey,
  },
  logoContainer: {},
  cvxLogo: {
    width: "3em",
    margin: "0.3em",
  },
  auctionsContainer: {
    textAlign: "center",
    justifyContent: "center",
    zIndex: 1000,
    alignItems: "center",
    align: "center",
  },
  auctionCard: {
    margin: "1em",
    marginBottom: "9.5em",
    marginTop: "-2.5em",
    zIndex: 1,
    width: "25em",
    background: "rgb(234 236 238)",
  },
  accountDetailsCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "1em",
    width: "40em",
    zIndex: 1,
    paddingTop: "0.6em",
    marginTop: "-4em",
    paddingBottom: "1em",
    background: "#e3e5e6",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  noActiveAuctionTitle: {
    fontSize: "1.5em",
    marginTop: "0.2em",
    color: "rgb(12, 10, 27)",
  },
  noActiveAuctionText: {
    fontSize: "1em",
    color: "rgb(32, 30, 37)",
  },
  timeText: {
    fontSize: "1.3em",
    marginTop: "1.05em",
    color: "grey",
    textAlign: "center",
  },
  tab: {
    margin: "auto",
    display: "block",
    textAlign: "center",
  },
  uniSpace: {
    maxWidth: "15%",
    marginBottom: "-0.4em",
  },
  cvxSpace: {
    maxWidth: "15%",
  },
  crvSpace: {
    maxWidth: "8%",
    marginTop: "0.5em",
    marginBottom: "0.6em",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  arrowButton: {
    color: "white",
  },
  votBox: {
    width: "3em",
    margin: "1em",
  },
  accordion: {
    width: "40em",
  },
  activeContainer: {
    alignItems: "center",
  },
  arrowsContainer: {
    marginBottom: "4em",
    marginTop: "-6em",
  },
  votTokenInput: {
    width: "2em",
  },
  claimContainer: {
    marginTop: "-6em",
    marginBottom: "4em",
  },
  submitButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "#141E27",
    color: theme.palette.common.lgrey,
  },
  submitButtonDisabled: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    background: "rgb(34 36 38)",
    color: "rgb(232, 230, 227) !important",
  },
  tableTextBottom: {
    borderBottom: "none",
  },
  orText: {
    color: "grey !important",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-25em",
  },
  modalPaper: {
    backgroundColor: "#070A0E",
    borderRadius: "3px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: "45em",
    width: "20em",
    alignItems: "center",
  },
  forwardModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-15em",
  },
  forwardModalPaper: {
    backgroundColor: "#070A0E",
    borderRadius: "3px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: "45em",
    width: "50em",
    alignItems: "center",
  },
  modalTitle: {
    fontSize: "1em",
    fontWeight: "700",
    marginTop: "-1em",
  },
  modalDelegateButton: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    margin: "1em",
    background: "#141e27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    height: "3.7em",
  },
  modalDelegateButtonDisabled: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    margin: "1em",
    background: "#cccecf",
    borderRadius: "5px",
    color: "white",
    height: "3.7em",
  },
  modalText: {
    textAlign: "center",
    margin: "1em",
    color: theme.palette.common.grey,
    fontWeight: "400",
  },
  modalClaimButton: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "70px",
    background: "#141E27",
    borderRadius: "5px",
    color: theme.palette.common.grey,
    height: "3em",
    padding: "1em",
    left: "35%",
    marginTop: "1.5em",
    marginBottom: "-0.1em",
  },
  modalClaimButtonDisabled: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "70px",
    background: "#cccecf",
    borderRadius: "5px",
    height: "3em",
    padding: "1em",
    left: "28%",
    marginTop: "1.5em",
    marginBottom: "-0.1em",
  },
  modalInput: {
    width: "30em",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgb(216 212 207)", // Change the border color to white (#fff)
      },
      "&:hover fieldset": {
        borderColor: "rgb(216 212 207)", // Change the border color to white (#fff) on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(216 212 207)", // Change the border color to white (#fff) when focused
      },
    },
  },
  delegateButton: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    marginLeft: "1em",
    marginTop: "-0.3em",
    background: "#141E27",
    borderRadius: "5px",
    color: "white",
    height: "3.7em",
  },
  delegateButtonDisabled: {
    textTransform: "none",
    fontSize: "0.8em",
    fontFamily: "Rubik",
    minWidth: "50px",
    marginLeft: "1em",
    marginTop: "-0.3em",
    background: "#141E27",
    borderRadius: "5px",
    color: "#BEBEBE !important",
    height: "3.7em",
  },
  unconsumedDepositsText: { color: "white", margin: "1em" },
  faqButton: {
    color: theme.palette.common.grey,
    fontSize: "0.7em",
    textTransform: "none",
    background: "#141e27",
    margin: "1em",
  },
  infoIcon: {
    color: "grey",
    marginBottom: "-0.3em",
    zIndex: 100,
  },
  infoTablePaper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "1em",
    width: "30em",
    zIndex: 1,
    marginTop: "-10em",
    background: "rgb(34 36 38)",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "12em",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  infoTable: {
    width: "100%",
    marginBottom: "1em",
  },
  tableText: { fontSize: "1em", color: "grey", fontWeight: "500" },
  tableSubText: { fontSize: "1em", color: "grey", fontWeight: "400" },
  icon: { width: "1em", marginRight: "0.4em", marginBottom: "-0.1em" },
  snapshotIcon: {
    width: "1em",
    marginLeft: "0.4em",
    marginBottom: "-0.1em",
    color: "#f3b04e",
  },
  editButton: {
    cursor: "pointer",
    width: "0.7em",
    marginBottom: "-0.2em",
    marginLeft: "0.1em",
  },
  copyButton: { minWidth: "0.1em", color: "white" },
  userIncentivesAccordion: {
    marginBottom: "8em",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "12em",
    },
  },
}));
