import { makeStyles } from "@material-ui/core";
export const useStylesDark = makeStyles((theme) => ({
  cvxcrvLogo: {
    width: "2em",
    marginBottom: "-0.2em",
    marginRight: "-0.2em",
  },
  snapshotLogo: {
    marginLeft: "-4em",
    marginRight: "-3em",
    marginBottom: "-1.3em",
    textTransform: "none",
    cursor: "pointer",
  },
  voteText: {
    color: "rgb(206 205 203)",
    fontSize: "1.1em",
  },
  bottomCell: {
    borderBottom: "none",
  },
  cvxLogo: {
    width: "2.8em",
    marginBottom: "-0.4em",
    marginRight: "-0.6em",
  },
  timeIcon: {
    marginBottom: "-0.2em",
    width: "1.5em",
  },
  auctionCard: {
    margin: "1em",
    maxWidth: "35em",
    height: "18em",
    paddingTop: "0.6em",
    backgroundColor: "rgb(34 36 38)",
  },
  accordion: {
    width: "40em",
    background: "linear-gradient(45deg, #FDFCFB 30%, #D7D7D8 90%)",
    [theme.breakpoints.down("xs")]: {
      width: "20em",
    },
  },
  tableContainer: {
    //alignItems: "center",
    //background: "linear-gradient(45deg, #FDFCFB 30%, #D7D7D8 90%)",
    marginTop: "2em",
    // width: "40%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1em",
      paddingRight: "1em",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0.5em",
      paddingRight: "0.5em",
    },
    maxWidth: "62em",
    justifyContent: "center",
    background: "transparent",
    boxShadow: "none",
    paddingLeft: "5em",
    paddingRight: "5em",
  },

  tableHeader: {
    marginBottom: "2em",
  },
  tableCellNoBorder: {
    borderBottom: "none",
    borderTop: "none",
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    background: "rgb(34 36 38)",
    color: "rgb(206 205 203)",
  },
  tableRow: {
    background: "rgb(34 36 38)",
    borderBottom: `solid  transparent`,
    borderTop: `solid  transparent`,
    "&:last-child": {
      borderBottom: "none",
    },
    marginBottom: theme.spacing(1),
    minHeight: "24px",
    padding: "15px 20px",
    borderRadius: "10px",
    transition: " none 0s ease 0s !important",
    "&:hover": {
      background: "rgb(44 47 49)",
    },
  },
  openedTableRow: {
    background: "rgb(34 36 38)",
    borderBottom: `solid  transparent`,
    borderTop: `solid  transparent`,
    "&:last-child": {
      borderBottom: "none",
    },
    padding: "0",
  },

  collapseRow: {
    //background: "#fcfcfc",
    borderBottom: `solid ${theme.spacing(2)}px transparent`,
    borderTop: `solid ${theme.spacing(2)}px transparent`,
  },

  firstTableRow: {
    borderRadius: theme.shape.borderRadius,
    background: "#fcfcfc",
    borderBottom: `solid ${theme.spacing(5)}px transparent`,
  },
  tableBody: {
    marginBottom: theme.spacing(5),
    //backgroundColor: "red",
  },

  activeContainer: {
    alignItems: "center",
  },
  table: {},
  tableText: {
    color: "grey",
  },
  tableTextBottom: {
    color: "grey",
    borderBottom: "none",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#F9FAFB",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    width: "50%",
    height: "80%",
  },
  modalAuctionName: {
    fontSize: "4em",
    fontFamily: "Rubik",
    fontWeight: "400",
    textAlign: "center",
  },
  modalTimeRemaining: {
    fontSize: "3em",
    fontFamily: "Rubik",
    fontWeight: "400",
    textAlign: "center",
  },
  modalHighestBid: {
    fontSize: "3em",
    fontFamily: "Rubik",
    fontWeight: "400",
    textAlign: "center",
  },
  inputField: {
    width: "70%",
  },
  inputLogo: {
    width: "2em",
  },
  modalContainer: {
    width: "50%",
  },
  submitButton: {
    left: "44%",
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "#141E27",
    color: theme.palette.common.lgrey,
  },
  cardTitle: {
    color: "white",
    fontSize: "1.1em",
    margin: "1em",
    marginBottom: "1.5em",
  },
  caption: {
    textTransform: "none",
    textAlign: "center",
    justifyContent: "center",
    marginLeft: "7.3em",
  },
  button: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "0.9em",
    backgroundColor: "#141E27",
    color: "white",
    margin: "1em",
    borderRadius: "5px",
  },
  disabledButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "0.9em",
    background: "transparent",
    borderRadius: "5px",
    color: "grey",
    margin: "1em",
  },
  bribeText: {
    width: "90%",
    textAlign: "center",
    marginTop: "1em",
  },
  votBox: {
    marginTop: "0.5em",
    width: "8em",
  },
  bribesContainer: {
    width: "85%",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
    justifyContent: "center",
    //gridTemplateColumns: "repeat(auto-fit,360px)",
  },
  tableCell: {
    color: "rgb(206 205 203)",
  },
}));

export const useStylesLight = makeStyles((theme) => ({
  cvxcrvLogo: {
    width: "2em",
    marginBottom: "-0.2em",
    marginRight: "-0.2em",
  },
  bottomCell: {
    borderBottom: "none",
  },
  cvxLogo: {
    width: "2.8em",
    marginBottom: "-0.4em",
    marginRight: "-0.6em",
  },
  timeIcon: {
    marginBottom: "-0.2em",
    width: "1.5em",
  },
  auctionCard: {
    margin: "1em",
    maxWidth: "35em",
    height: "18em",
    paddingTop: "0.6em",
    backgroundColor: "rgb(234 236 238)",
  },
  accordion: {
    width: "40em",
    background: "linear-gradient(45deg, #FDFCFB 30%, #D7D7D8 90%)",
    [theme.breakpoints.down("xs")]: {
      width: "20em",
    },
  },
  snapshotLogo: {
    marginLeft: "-4em",
    marginRight: "-3em",
    marginBottom: "-1.3em",
    textTransform: "none",
    cursor: "pointer",
  },
  voteText: {
    color: "#808080",
    fontSize: "1.1em",
  },
  tableContainer: {
    //alignItems: "center",
    //background: "linear-gradient(45deg, #FDFCFB 30%, #D7D7D8 90%)",
    marginTop: "2em",
    // width: "40%",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1em",
      paddingRight: "1em",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "0.5em",
      paddingRight: "0.5em",
    },
    maxWidth: "60em",
    justifyContent: "center",
    background: "transparent",
    boxShadow: "none",
    paddingLeft: "5em",
    paddingRight: "5em",
  },

  tableHeader: {
    marginBottom: "2em",
  },
  tableCellNoBorder: {
    borderBottom: "none",
    borderTop: "none",
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(0),
    color: "rgb(6 5 3)",
  },
  tableRow: {
    background: "linear-gradient(45deg, #FDFCFB 30%, #E7E7E8 90%)",
    boxShadow: theme.shadows[5],
    borderBottom: `solid  transparent`,
    borderTop: `solid  transparent`,
    "&:last-child": {
      borderBottom: "none",
    },
    marginBottom: theme.spacing(1),
    minHeight: "24px",
    padding: "15px 20px",
    borderRadius: "10px",
    transition: " none 0s ease 0s !important",
    "&:hover": {
      background: "rgb(244 247 249)",
    },
  },
  openedTableRow: {
    background: "linear-gradient(45deg, #FDFCFB 30%, #E7E7E8 90%)",
    boxShadow: theme.shadows[5],
    borderBottom: `solid  transparent`,
    borderTop: `solid  transparent`,
    "&:last-child": {
      borderBottom: "none",
    },
    borderRadius: "10px",
    padding: "0",
  },

  collapseRow: {
    //background: "#fcfcfc",
    borderBottom: `solid ${theme.spacing(2)}px transparent`,
    borderTop: `solid ${theme.spacing(2)}px transparent`,
  },

  firstTableRow: {
    borderRadius: theme.shape.borderRadius,
    background: "#fcfcfc",
    borderBottom: `solid ${theme.spacing(5)}px transparent`,
  },
  tableBody: {
    marginBottom: theme.spacing(5),
    //backgroundColor: "red",
  },

  activeContainer: {
    alignItems: "center",
  },
  table: {},
  tableText: {
    color: "#000",
  },
  tableTextBottom: {
    color: "#000",
    borderBottom: "none",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#F9FAFB",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    width: "50%",
    height: "80%",
  },
  modalAuctionName: {
    fontSize: "4em",
    fontFamily: "Rubik",
    fontWeight: "400",
    textAlign: "center",
  },
  modalTimeRemaining: {
    fontSize: "3em",
    fontFamily: "Rubik",
    fontWeight: "400",
    textAlign: "center",
  },
  modalHighestBid: {
    fontSize: "3em",
    fontFamily: "Rubik",
    fontWeight: "400",
    textAlign: "center",
  },
  inputField: {
    width: "70%",
  },
  inputLogo: {
    width: "2em",
  },
  modalContainer: {
    width: "50%",
  },
  submitButton: {
    left: "44%",
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "1em",
    backgroundColor: "#141E27",
    color: theme.palette.common.lgrey,
  },
  cardTitle: {
    color: "#000",
    fontSize: "1.1em",
    margin: "1em",
    marginBottom: "1.5em",
  },
  caption: {
    textTransform: "none",
    textAlign: "center",
    justifyContent: "center",
    marginLeft: "7.3em",
  },
  button: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "0.9em",
    backgroundColor: "#141E27",
    color: "white",
    margin: "1em",
    borderRadius: "5px",
  },
  disabledButton: {
    textTransform: "none",
    fontFamily: "Rubik",
    fontSize: "0.9em",
    background: "transparent",
    borderRadius: "5px",
    color: "grey",
    margin: "1em",
  },
  bribeText: {
    width: "90%",
    textAlign: "center",
    marginTop: "1em",
  },
  votBox: {
    marginTop: "0.5em",
    width: "8em",
  },
  bribesContainer: {
    width: "85%",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
    justifyContent: "center",
    //gridTemplateColumns: "repeat(auto-fit,360px)",
  },
  tableCell: {
    color: "rgb(6 5 3)",
  },
}));
