import { useState, useEffect } from "react";
import { onValue } from "firebase/database";
import {
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { BsFillLightningFill } from "react-icons/bs";
import { ethers } from "ethers";


import { tokenOptions } from "../../tokenOptions";
import { useStylesDark, useStylesLight } from "../../../../Styles/AuctionCardStyles";
import { Info } from "@material-ui/icons";

const AuctionCard = (props) => {
  const classesLight = useStylesLight();
  const classesDark = useStylesDark();
  const [classes, setClasses] = useState(classesDark);
  const [tokenSymbol, setTokenSymbol] = useState(false);
  const [tokenImg, setTokenImg] = useState("");
  const [gaugeTotalValue, setGaugeTotalValue] = useState(0);
  const [userGaugeWeightValue, setUserGaugeWeightValue] = useState(0);
  const [userEffectiveAmount, setUserEffectiveAmount] = useState(0);
  const [refreshDB, setRefreshDB] = useState(1);
  const [filter, setFilter] = useState(true);
  const networks = { 1: "Mainnet", 10: "Optimism" };

  // theme setting 
  useEffect(() => {
    if (props.ctheme === 'light') {
      setClasses(classesLight);
    } else {
      setClasses(classesDark);
    }
  }, [props.ctheme]);
  
  //get token symbol and img
  useEffect(() => {
    for (let i = 0; i < tokenOptions.length; i++) {
      if (
        props.bribe.token.toUpperCase() == tokenOptions[i].value.toUpperCase()
      ) {
        setTokenSymbol(tokenOptions[i].label);
        setTokenImg(tokenOptions[i].img);
      }
    }
  }, [props.db, props.bribe]);

  //get tokenprice from db
  useEffect(() => {
    async function getInfoFromDatabase() {
      if (props.db) {
        try {
          onValue(props.gaugeTotalRef, (snapshot) => {
            let data = snapshot.val();
            if (data !== null && data !== undefined) {
              if (
                data[props.bribe.choice.toString().toUpperCase()] !== null &&
                data[props.bribe.choice.toString().toUpperCase()] !== undefined
              ) {
                data = data[props.bribe.choice.toString().toUpperCase()];
                setGaugeTotalValue(data);
              } else {
                console.log("Couldn't get gauge total value from db");
                setGaugeTotalValue(0);
              }
            } else {
              console.log("Couldn't get gauge total value from db");
              setGaugeTotalValue(0);
            }
          });
          onValue(props.userGaugeWeightRef, (snapshot) => {
            let data = snapshot.val();
            if (data !== null && data !== undefined) {
              console.log(data);
              if (
                data[props.bribe.choice.toString().toUpperCase()] !== null &&
                data[props.bribe.choice.toString().toUpperCase()] !== undefined
              ) {
                data = data[props.bribe.choice.toString().toUpperCase()];
                setUserGaugeWeightValue(data.weight);
                setUserEffectiveAmount(data.effective);
              } else {
                console.log("Couldn't get user gauge weight from db");
                setUserGaugeWeightValue(0);
                setUserEffectiveAmount(0);
              }
            } else {
              console.log("Couldn't get user gauge weight from db");
              setUserGaugeWeightValue(0);
              setUserEffectiveAmount(0);
            }
          });
        } catch {
          setRefreshDB(refreshDB + 1);
          console.log("Couldn't get info from database");
        }
      }
    }

    try {
      if (refreshDB < 300) {
        getInfoFromDatabase();
      }
    } catch {
      console.log("Couldn't run getRefs/getInfoFromDatabase");
    }
  }, [
    props.db,
    refreshDB,
    props.bribe,
    props.auctionWeek,
    props.refreshAuction,
    props.bribes,
  ]);

  useEffect(() => {
    setRefreshDB(1);
  }, [props.auctionWeek]);

  useEffect(() => {
    setFilter(props.filter);
  }, [props.filter]);

  return (
    <>
      {(props.tokenValue * props.bribe.amount > 9999 ||
        props.tokenValue === undefined ||
        !filter) && (
        <Paper className={classes.auctionCardCurve}>
          <Grid item className={classes.tokenImg} align="left">
            {tokenImg}
          </Grid>
          <Grid container direction="column">
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <Typography className={classes.cardTitle}>
                  {tokenSymbol ? "$" + tokenSymbol : props.bribe.token}{" "}
                  incentive
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography className={classes.cardSubtitle}>
                for "
                <b>
                  {props.bribe.pool.length > 20
                    ? props.bribe.pool.substr(0, 20) + "..."
                    : props.bribe.pool}
                </b>
                " pool
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.gaugeAddressSubtitle}>
                Gauge address:{" "}
                <b>
                  {props.bribe.choice.substring(0, 4) +
                    "..." +
                    props.bribe.choice.substring(
                      props.bribe.choice.length - 2,
                      props.bribe.choice.length
                    )}
                </b>
              </Typography>
            </Grid>

            <Grid item>
              <TableContainer>
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left" className={classes.tableText}>
                        <Typography>Total reward</Typography>
                      </TableCell>
                      <TableCell align="right">
                        {ethers.utils.commify(props.bribe.amount) +
                          " $" +
                          tokenSymbol}
                      </TableCell>
                    </TableRow>{" "}
                    <TableRow>
                      <TableCell align="left" className={classes.tableText}>
                        <Typography>In $USD</Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "rgb(206 205 203)" }}
                      >
                        {props.tokenValue === undefined
                          ? "---"
                          : "$" +
                            ethers.utils.commify(
                              (props.tokenValue * props.bribe.amount).toFixed(2)
                            )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" className={classes.tableText}>
                        <Typography>$/veCRV</Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "rgb(206 205 203)" }}
                      >
                        {props.tokenValue === undefined || gaugeTotalValue === 0
                          ? "---"
                          : "$" +
                            ethers.utils.commify(
                              (
                                (props.tokenValue * props.bribe.amount) /
                                gaugeTotalValue
                              ).toFixed(4)
                            )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" className={classes.tableText}>
                        <Typography>Your vote weight</Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "rgb(206 205 203)" }}
                      >
                        {props.tokenValue === undefined || gaugeTotalValue === 0
                          ? "---"
                          : ethers.utils.commify(
                              (userGaugeWeightValue / 100).toFixed(2)
                            ) + "%"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left" className={classes.tableText}>
                        <Grid container>
                          <Grid item>
                            <Typography style={{ color: "grey" }}>
                              Your reward
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Amount not final until end of vote. Additional votes dilute reward.">
                              <Info
                                className={classes.infoIcon}
                                fontSize="small"
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "rgb(206 205 203)" }}
                      >
                        {props.tokenValue === undefined ||
                        gaugeTotalValue === 0 ||
                        userEffectiveAmount === 0
                          ? "---"
                          : "$" +
                            ethers.utils.commify(
                              (
                                ((props.tokenValue * props.bribe.amount) /
                                  gaugeTotalValue) *
                                userEffectiveAmount
                              ).toFixed(2)
                            )}
                      </TableCell>
                    </TableRow>
                    {networks[props.bribe.network] ? (
                      <TableRow>
                        <TableCell align="left" className={classes.tableText}>
                          <Grid container>
                            <Grid item>
                              <Typography>Network</Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell align="right">
                          {networks[props.bribe.network]}
                        </TableCell>
                      </TableRow>
                    ) : (
                      ""
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                disableRipple
                onClick={() => {
                  window.open("https://dao.curve.fi/gaugeweight");
                }}
                endIcon={
                  <BsFillLightningFill fontSize="small"></BsFillLightningFill>
                }
              >
                Vote on Curve
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default AuctionCard;
